import React from 'react'
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap'

const ViewWaitlist = () => {
  return (
    <div>
      <div className="content-wrapper">
        <Row>
          <Col md="12" sm="4">

            <Form>
              <Form.Group className="my-3" controlId="formBasicEmail">
                <Form.Label> Name</Form.Label>
                <p>John Revera</p>

              </Form.Group>

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label> Phone</Form.Label>
                    <p>(123) 456-1987</p>
                    {/* <PhoneInput country="us"placeholder='phone number'  value={value}  onChange={setValue} /> */}

                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label>Enter Group Size</Form.Label>
                    <p>5</p>

                  </Form.Group>
                </Col>
              </Row>


              <Form.Check
                disabled
                label="Prioritize"
                name="group1"
                type="checkbox"
                className='mb-2'
              />

              <Form.Check
                disabled
                label="Fast Pass"
                name="group1"
                type="checkbox"
              />


              <Accordion defaultActiveKey="0" className='my-4'>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Preference</Accordion.Header>
                  <Accordion.Body>

                    <Row>
                      <Col>
                        <ul className="list-inline gird-list  mb-3 justify-content-center">
                          <li className="list-inline-item icon mb-3">
                            <Form.Check
                              disabled
                              label="1st Floor (8)"
                              name="group1"
                              type="checkbox"
                            />
                          </li>
                          <li className="list-inline-item icon mb-3">
                            <Form.Check
                              disabled
                              label="2nd Floor (3)"
                              name="group1"
                              type="checkbox"
                            />
                          </li>
                          <li className="list-inline-item icon">
                            <Form.Check
                              disabled
                              label="3rd Floor (7)"
                              name="group1"
                              type="checkbox"
                            />
                          </li>
                          <li className="list-inline-item icon">
                            <Form.Check
                              disabled
                              label="4th Floor (1)"
                              name="group1"
                              type="checkbox"
                            />
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row className='mt-2'>
                    </Row>
                    <Row>
                      <Col>
                        <ul className="list-inline gird-list  mb-3 justify-content-center">
                          <li className="list-inline-item icon mb-3">
                            <Form.Check
                              disabled
                              label="Standard (19)"
                              name="group1"
                              type="checkbox"
                            />
                          </li>
                          <li className="list-inline-item icon mb-3">
                            <Form.Check
                              disabled
                              label="Putting"
                              name="group1"
                              type="checkbox"
                            />
                          </li>
                          <li className="list-inline-item icon">
                            <Form.Check
                              disabled
                              label="Suites"
                              name="group1"
                              type="checkbox"
                            />
                          </li>
                          <li className="list-inline-item icon">

                            <Form.Check
                              disabled
                              label="VIP bays"
                              name="group1"
                              type="checkbox"
                            />
                          </li>
                        </ul>
                      </Col>
                    </Row>



                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/* <div className="modal-footer mb-3 ">
                <Button type="button" className="btn btn-secondary border-0 fw_medium   ms-2 rounded-1   " variant="secondary"   >Cancel</Button>
                <Button type="button" className="  btn_primary border-0 fw_medium   ms-2 rounded-1"  >Assign Bay</Button>
              </div> */}
            </Form>
          </Col>
        </Row>
      </div>

    </div>
  )
}

export default ViewWaitlist
import React from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import MainLayout from "../../components/Layout/MainLayout";
import DayPricing from "./DayPricing";
import SessionPricing from "./SessionPricing";

const Pricing = () => {
  return (
    <div>
      <MainLayout>
        <Tabs
          defaultActiveKey="day-pricing"
          id="uncontrolled-tab-example"
          className="mb-5"
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <Tab eventKey="day-pricing" title="Day Pricing">
            <DayPricing />
          </Tab>
          <Tab eventKey="session-pricing" title="Session Pricing">
            <SessionPricing />
          </Tab>
        </Tabs>
      </MainLayout>
    </div>
  );
};

export default Pricing;

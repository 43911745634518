import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { VENUE_GUID } from "../../../../constants";
import dataService from "../../../../apiServices/data.service";
import moment from "moment-timezone";
import BayBox from "../../../BayBox/BayBox";
import { useDispatch, useSelector } from "react-redux";
import PhoneNumberFormat from "../../../CommonComponents/PhoneNumberFormat";
import { getGroupLeadFromGuest } from "../../../../commonFunctions";
import {
  disableCancelAssignmentBtn,
  disableTransferAssignmentBtn,
  enableNewAssignmentBtn,
} from "../../../../features/reloadDataTableSlice";

const CancelCanves = ({
  show,
  handleClose,
  bayCss,
  bayNumber,
  bayStartTime,
  bayStateName,
  bayEndTime,
  bayRowGuid,
  waitlistGuid,
  toast,
  showFlagUi,
  showFlagCount,
  disableCancelBtn,
}) => {
  const { allBays, intake_bay_filter, intake_bays, floors, loading, error } =
    useSelector((state) => state.bays);
  const [guestList, setGuestList] = useState([]);
  const [reasonToCancel, setReasonToCancel] = useState("");
  const [selectedBay, setSelectedBay] = useState("");
  const [bay, setBay] = useState(allBays[bayRowGuid]);
  const [guestGroupLead, setGuestGroupLead] = useState({});
  const dispatch = useDispatch();

  const labelCss = {
    color: bay.IsOnline
      ? bay.IsEvent
        ? "#F57522"
        : bay.LabelColor
        ? bay.LabelColor
        : null
      : "#000",
  };
  const cancelAssignment = () => {
    dataService
      .cancelAssignment({
        VenueGuid: VENUE_GUID,
        BayGuid: bayRowGuid,
        IntakeGuid: waitlistGuid,
        ReasonForCancellation: reasonToCancel
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success("Bay Assignment cancelled.");
          disableCancelBtn();
          dispatch(disableCancelAssignmentBtn());
          dispatch(enableNewAssignmentBtn());
          dispatch(disableTransferAssignmentBtn());
          handleClose();
        } else {
          toast.error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        // Handle error
        toast.error(err.message);
      });
  };
  useEffect(() => {
    if (show) {
      dataService
        .getGuestInfoInBay({
          BayGuid: bayRowGuid,
          VenueGuid: VENUE_GUID,
          IntakeGuid: waitlistGuid,
        })
        .then((response) => {
          if (response.data.status === "success") {
            setGuestGroupLead(getGroupLeadFromGuest(response.data.response));
            setGuestList(response.data.response);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }, [show]);

  return (
    <>
      {/* CANCEL ASSIGNMENT */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="off_canves_two off_canves offcanvas offcanvas-end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5>Cancel Assignment</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <Row>
              <Col sm={4} xs={6} className="box_details_col me-4">
                <div className="bay-d-wid">
                  <div
                    className={`bay-card-box  ${
                      bay.IsServiceRequest ? "service-requested" : null
                    }`}
                    style={bayCss}
                  >
                    <div className="bay-divider">
                      <div className="d-flex align-items-center justify-content-center sec1">
                        <span className="fw-bold ">{bayNumber}</span>
                      </div>
                      {showFlagUi()}
                    </div>
                  </div>
                  <div className="bay-card-notifcation-offcanvas">
                    {showFlagCount()}
                  </div>
                  <div
                    className="bay-card-status text-uppercase w-100 mb-3"
                    style={labelCss}
                  >
                    {bay.IsOnline ? bayStateName : "Offline"}
                  </div>
                </div>
                <div className="ms-4">
                  {guestGroupLead !== undefined &&
                  guestGroupLead !== null &&
                  Object.keys(guestGroupLead).length > 0 ? (
                    <h4>Pin - {guestGroupLead.Pin}</h4>
                  ) : null}
                </div>
              </Col>

              <Col md="7">
                <div className="inuse-heading">
                  {guestList.map((guest, i) => {
                    return guest.GroupLead ? (
                      <div key={i} className="mb-5">
                        <h5>{guest.Name}</h5>
                        <div className="d-flex mt-2">
                          <p className="me-5">
                            <PhoneNumberFormat phone={guest.Phone} />
                          </p>
                          <p>Pin - {guest.Pin}</p>
                        </div>
                        <hr></hr>
                        <div className="table-responsive mt-3">
                          <Table
                            cellPadding="4"
                            cellSpacing="2"
                            className="border_none_table"
                          >
                            <tbody>
                              <tr>
                                <td className="fw-bold py-2">
                                  Session Started
                                </td>
                                <td className="py-2">
                                  {guest.SessionStartTime
                                    ? moment(guest.SessionStartTime).format(
                                        "h:mm:ss a"
                                      )
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-bold py-2">Purchased Time</td>
                                <td className="py-2">
                                  {moment
                                    .duration(
                                      guest.TotalTimePurchdInMin,
                                      "minutes"
                                    )
                                    .asHours()}{" "}
                                  h
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-bold py-2">
                                  Current Session End
                                </td>
                                <td className="py-2">
                                  {guest.PurchasedSessionEndTime
                                    ? moment(
                                        guest.PurchasedSessionEndTime
                                      ).format("h:mm:ss a")
                                    : "N/A"}
                                </td>
                              </tr>
                              {/* <tr>
                              <td className="fw-bold py-2">Membership Type</td>
                              <td className="py-2">Annual</td>
                            </tr> */}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              </Col>
              <Col sm={12}>
                <Form.Group
                  className="my-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="mb-2">Reason</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={(e) => setReasonToCancel(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="modal-footer mt-4">
              <Button
                type="button"
                className="btn btn-secondary   fw_medium   ms-2 rounded-1   "
                variant=" "
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="  btn_primary  fw_medium   ms-2 rounded-1   "
                onClick={cancelAssignment}
                disabled={reasonToCancel.length < 6}
              >
                Confirm Cancellation
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      {/* CANCEL ASSIGNMENT */}
    </>
  );
};

export default CancelCanves;

import React from "react";
import { Col, Offcanvas, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const ColorMapCanvas = ({ show, setShow }) => {
  const { bay_states } = useSelector((state) => state.bays);
  return (
    <Offcanvas
      show={show}
      onHide={() => setShow(false)}
      placement="end"
      className="color-map"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Bay Color Map</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Row>
          {/*         {bay_states.map((item, i) => {
            return (
              <Col sm={4} key={i}>
                <div className="bay-card  alert-status ">
                  <div className="timewrapper">
                    <div className="card-status-time"></div>
                  </div>
                  <div
                    className="bay-card-box"
                    style={{ background: item.FillColor }}
                  >
                    <div className="bay-divider">
                      <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
                        <span>101</span>
                      </div>
                    </div>
                    <div className="bay-card-notifcation"></div>
                  </div>
                  <div
                    className="bay-card-status text-uppercase"
                    style={{ color: "#000" }}
                  >
                    {item.BayStateName}
                  </div>
                </div>
              </Col>
            );
          })} */}
          <Col sm={2}>
            <div className="bay-card  alert-status ">
              <div className="timewrapper">
                <div className="card-status-time"></div>
              </div>
              <div className="bay-card-box" style={{ background: "#fff" }}>
                <div className="bay-divider">
                  <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
                    <span>101</span>
                  </div>
                </div>
                <div className="bay-card-notifcation"></div>
              </div>
              <div
                className="bay-card-status text-uppercase"
                style={{ color: "#000" }}
              >
                AVAILABLE
              </div>
            </div>
          </Col>
          <Col sm={2}>
            <div className="bay-card  alert-status ">
              <div className="timewrapper">
                <div className="card-status-time"></div>
              </div>
              <div className="bay-card-box" style={{ background: "#cad7df" }}>
                <div className="bay-divider">
                  <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
                    <span>101</span>
                  </div>
                </div>
                <div className="bay-card-notifcation"></div>
              </div>
              <div
                className="bay-card-status text-uppercase"
                style={{ color: "#cad7df" }}
              >
                CLOSED
              </div>
            </div>
          </Col>
          <Col sm={2}>
            <div className="bay-card  alert-status ">
              <div className="timewrapper">
                <div className="card-status-time"></div>
              </div>
              <div className="bay-card-box" style={{ background: "#8EA7BA" }}>
                <div className="bay-divider">
                  <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
                    <span>101</span>
                  </div>
                </div>
                <div className="bay-card-notifcation"></div>
              </div>
              <div
                className="bay-card-status text-uppercase"
                style={{ color: "#8EA7BA" }}
              >
                OUT OF ORDER
              </div>
            </div>
          </Col>
          <Col sm={2}>
            <div className="bay-card  alert-status ">
              <div className="timewrapper">
                <div className="card-status-time"></div>
              </div>
              <div className="bay-card-box" style={{ background: "#53B257" }}>
                <div className="bay-divider">
                  <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
                    <span>101</span>
                  </div>
                </div>
                <div className="bay-card-notifcation"></div>
              </div>
              <div
                className="bay-card-status text-uppercase"
                style={{ color: "#53B257" }}
              >
                EVENT
              </div>
            </div>
          </Col>
          <Col sm={2}>
            <div className="bay-card  alert-status ">
              <div className="timewrapper">
                <div className="card-status-time"></div>
              </div>

              <div className="bay-card-box" style={{ background: "#fff" }}>
                <div className="bay-divider">
                  <div
                    className="bay-event"
                    style={{ background: "#53B257" }}
                  ></div>
                  <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
                    <span>101</span>
                  </div>
                </div>
                <div className="bay-card-notifcation"></div>
              </div>
              <div
                className="bay-card-status text-uppercase"
                style={{ color: "#000" }}
              >
                UPCOMING EVENT
              </div>
            </div>
          </Col>
        </Row>
        <Row className="my-4">
          <Col xs={6}>
            <Row>
              <Col xs={3}>
                <div className="bay-card  alert-status ">
                  <div className="timewrapper">
                    <div className="card-status-time">
                      <span> 15m</span>
                    </div>
                  </div>
                  <div
                    className="bay-card-box"
                    style={{ background: "#c10d5c" }}
                  >
                    <div className="bay-divider">
                      <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
                        <span className="text-white">101</span>
                      </div>
                    </div>
                    <div className="bay-card-notifcation"></div>
                  </div>
                  <div
                    className="bay-card-status text-uppercase"
                    style={{ color: "#000" }}
                  >
                    ASSIGNED
                  </div>
                </div>
              </Col>
              <Col>
                Assigned- 15 minutes allocated for guests to check in at the
                bay. Decrementing time shown above the bay.
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row>
              <Col xs={3}>
                <div className="bay-card  alert-status ">
                  <div className="timewrapper">
                    <div className="card-status-time">
                      <span> 30m</span>
                    </div>
                  </div>
                  <div
                    className="bay-card-box"
                    style={{ background: "#9038c5" }}
                  >
                    <div className="bay-divider">
                      <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
                        <span className="text-white">101</span>
                      </div>
                    </div>
                    <div className="bay-card-notifcation"></div>
                  </div>
                  <div
                    className="bay-card-status text-uppercase"
                    style={{ color: "#9038c5" }}
                  >
                    IN-USE
                  </div>
                </div>
              </Col>
              <Col>In-Use - Remaining session time shown above the bay.</Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row>
              <Col xs={3}>
                <div className="bay-card  alert-status ">
                  {/*       <div className="timewrapper">
                    <div className="card-status-time">
                      <span> 30m</span>
                    </div>
                  </div> */}
                  <div className="bay-card-box" style={{ background: "#000" }}>
                    <div className="bay-divider">
                      <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
                        <span className="text-white">101</span>
                      </div>
                    </div>
                    <div className="bay-card-notifcation"></div>
                  </div>
                  <div
                    className="bay-card-status text-uppercase"
                    style={{ color: "#000" }}
                  >
                    OFFLINE
                  </div>
                </div>
              </Col>
              <Col>
                The bay is not sending a heartbeat but it is operational.
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />

        <Row className="my-5">
          <Col xs={6}>
            <Row>
              <Col xs={3}>
                <div className="bay-card  alert-status ">
                  <div className="timewrapper">
                    <div className="card-status-time"></div>
                  </div>
                  <div className="bay-card-box" style={{ background: "#fff" }}>
                    <div className="bay-divider">
                      <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
                        <span>101</span>
                      </div>
                    </div>
                    <div className="bay-card-notifcation"></div>
                    <div
                      className="bay-nstatus "
                      style={{
                        background: "#FFF",
                        // color: props.bay.IsEvent ? "#c92167" : props.bay.FillColor,
                        color: "#C0155C",
                      }}
                    >
                      <span className="d-flex  pause-icon ">
                        <i className="fa-solid fa-triangle-exclamation"></i>
                      </span>
                    </div>
                  </div>
                  <div
                    className="bay-card-status text-uppercase"
                    style={{ color: "#000" }}
                  >
                    AVAILABLE
                  </div>
                </div>
              </Col>
              <Col>Paused- The bay or venue has been paused by the Admin</Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row>
              <Col xs={3}>
                <div className="bay-card  alert-status ">
                  <div className="timewrapper">
                    <div className="card-status-time"></div>
                  </div>
                  <div
                    className="bay-card-box"
                    style={{ background: "rgb(143, 59, 196)" }}
                  >
                    <div className="bay-divider">
                      <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
                        <span>101</span>
                      </div>
                    </div>
                    <div className="bay-card-notifcation"></div>
                    <div
                      className="bay-nstatus "
                      style={{
                        background: "#FFC107",
                        color: "#000",
                      }}
                    >
                      <span>Service</span>
                    </div>
                  </div>
                  <div
                    className="bay-card-status text-uppercase"
                    style={{ color: "#000" }}
                  >
                    IN USE
                  </div>
                </div>
              </Col>
              <Col>
                Service Request - The guest has asked for service at the bay.
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row>
              <Col xs={3}>
                <div className="bay-card  alert-status ">
                  <div className="timewrapper">
                    <div className="card-status-time"></div>
                  </div>
                  <div className="bay-card-box" style={{ background: "#fff" }}>
                    <div className="bay-divider">
                      <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
                        <span>101</span>
                      </div>
                    </div>
                    <div className="bay-card-notifcation"></div>
                    <div
                      className="bay-nstatus "
                      style={{
                        background: "#2c4cba",
                        color: "#fff",
                      }}
                    >
                      <span>Bussing</span>
                    </div>
                  </div>
                  <div
                    className="bay-card-status text-uppercase"
                    style={{ color: "#000" }}
                  >
                    AVAILABLE
                  </div>
                </div>
              </Col>
              <Col>Bussing - Incrementing time shown above the bay.</Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row>
              <Col xs={3}>
                <div className="bay-card  alert-status ">
                  <div className="timewrapper">
                    <div className="card-status-time"></div>
                  </div>
                  <div className="bay-card-box" style={{ background: "#fff" }}>
                    <div className="bay-divider">
                      <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
                        <span>101</span>
                      </div>
                    </div>
                    <div className="bay-card-notifcation"></div>
                    <div
                      className="bay-nstatus "
                      style={{ background: "#FFC107", color: "#000" }}
                    >
                      <span>Locked</span>
                    </div>
                  </div>
                  <div
                    className="bay-card-status text-uppercase"
                    style={{ color: "#000" }}
                  >
                    AVAILABLE
                  </div>
                </div>
              </Col>
              <Col>Locked - Bay is opened by another user.</Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row>
              <Col xs={3}>
                <div className="bay-card  alert-status ">
                  <div className="timewrapper">
                    <div className="card-status-time"></div>
                  </div>
                  <div className="bay-card-box" style={{ background: "#fff" }}>
                    <div className="bay-divider">
                      <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
                        <span>101</span>
                      </div>
                    </div>
                    <div class="bay-card-notifcation">
                      <a title="" class="bay-alert" href="/">
                        3
                      </a>
                    </div>
                    <div
                      className="bay-nstatus "
                      style={{
                        background: "#2c4cba",
                        color: "#fff",
                      }}
                    >
                      <span>Bussing</span>
                    </div>
                  </div>
                  <div
                    className="bay-card-status text-uppercase"
                    style={{ color: "#000" }}
                  ></div>
                </div>
              </Col>
              <Col>
                More than 1 ﬂag is set. The ﬂag with highest priority is shown
                in the bay. Click the bay for details.
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row>
              <Col xs={12}>
                <h5 className="fw-bold">Bay ﬂag Priority</h5>
                <ul className="list-group list-group-numbered mt-2">
                  <li className="list-group-item p-0 py-1"> Locked</li>
                  <li className="list-group-item p-0 py-1"> Pause</li>
                  <li className="list-group-item p-0 py-1"> Service</li>
                  <li className="list-group-item p-0 py-1"> Bussing</li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ColorMapCanvas;

import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import dataService from "../../../apiServices/data.service";
import moment from "moment-timezone";
import { VENUE_GUID } from "../../../constants";
import { toast } from "react-toastify";
import ThreeDotsLoader from "../../../views/ThreeDotsLoader/ThreeDotsLoader";
import { orderBy } from "../../../commonFunctions";
import PhoneNumberFormat from "../../CommonComponents/PhoneNumberFormat";

const GuestInfo = ({ rowGuid, IntakeGuid }) => {
  const [guestList, setGuestList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [leaderList, setLeaderList] = useState([]);
  const [playerList, setPlayerList] = useState([]);

  useEffect(() => {
    setLoading(true);
    dataService
      .getGuestInfoInBay({ BayGuid: rowGuid, VenueGuid: VENUE_GUID, IntakeGuid: IntakeGuid })
      .then((response) => {
        if (response.data.status === "success") {
          //setGuestList(orderBy(response.data.response, 'GroupLead','desc'));
          const splitData = splitDataByGroupLead(response.data.response);

          setLeaderList(splitData[1] || [])
          setPlayerList(splitData[0] || [])
         
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  const splitDataByGroupLead = (originalData) => {
    return originalData.reduce((result, item) => {
      const groupLead = item.GroupLead;
  
      if (!result[groupLead]) {
        result[groupLead] = [];
      }
  
      result[groupLead].push(item);
  
      return result;
    }, {});
  };


  return (
    <>
      {loading ? (
        <ThreeDotsLoader />
      ) : (
        <div className={`tab-pane mt-4`}>
          <div className="inuse-heading">
            {leaderList.length > 0 ? leaderList.map((guest, i) => {
              return guest.GroupLead ? (
                <div key={i}>
                  <h4 className="mb-3">{guest.Name}</h4>
                  <div className="d-flex mt-2">
                    <h5 ><PhoneNumberFormat phone={guest.Phone} /></h5>
                    <span className="mx-4">|</span>
                    <h5 >Pin - {guest.Pin}</h5 >
                  </div>
                  <div className="table-responsive mt-4">
                    <Table
                      cellPadding="4"
                      cellSpacing="2"
                      className="border_none_table mb-0"
                    >
                      <tbody>
                        <tr>
                          <td width="200" className="fw-bold py-2">Session Started</td>
                          <td className="py-2">
                            {guest.SessionStartTime
                              ? moment(guest.SessionStartTime).format(
                                "h:mm:ss a"
                              )
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold py-2">Purchased Time</td>
                          <td className="py-2">
                            {
                              guest.TotalTimePurchdInMin > 0 ? moment
                              .duration(guest.TotalTimePurchdInMin, "minutes")
                              .asHours(): "-"
                            }
                            &nbsp;h
                            
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold py-2">Current Session End</td>
                          <td className="py-2">
                            {guest.PurchasedSessionEndTime
                              ? moment(guest.PurchasedSessionEndTime).format(
                                "h:mm:ss a"
                              )
                              : "N/A"}
                          </td>
                        </tr>
                        {/* <tr>
                                <td className="fw-bold py-2">Membership Type</td>
                                <td className="py-2">Annual</td>
                              </tr> */}
                      </tbody>
                    </Table>
                  </div>

                </div>
              ) : null;
            }) : null}
            <div >
              <div className="table-responsive mt-4">
                <Table >
                  <thead>
                    <th>Players Name</th>
                    <th width="170">Phone</th>
                  </thead>
                  <tbody>
                    {playerList.length > 0 ? playerList.map((guest, i) => {
                      return (!guest.GroupLead && guest.Name.trim().length > 0) ? (
                        <tr id={i}>
                          <td>{guest.Name}</td>
                          <td><PhoneNumberFormat phone={guest.Phone} /></td>
                        </tr>
                      ) : null;
                    }): null}


                  </tbody>
                </Table>
              </div>
            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default GuestInfo;

import React, { useState, useEffect } from 'react'
import { Badge } from 'react-bootstrap';

import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { checkedBay } from '../../features/baysSlice';


const IntakeBayBox = (props) => {
  
  const dispatch = useDispatch()

  const { checked_intake_bay } = useSelector((state) => state.bays)

  const bayCss = {
    backgroundColor: props.bay.IsOnline
      ? props.bay.IsEvent
        ? "#4CAF50"
        : props.bay.FillColor
      : props.bay.BayStateName == 'Out Of Order' || props.bay.BayStateName == 'Closed'
        ? props.bay.FillColor
        : "#000",
    borderColor: props.bay.IsOnline
      ? props.bay.IsEvent
        ? "#4CAF50"
        : props.bay.BorderColor

      : props.bay.BayStateName == 'Out Of Order' || props.bay.BayStateName == 'Closed'
        ? props.bay.FillColor
        : "#000",

    color:  props.bay.IsEvent ? "#fff" : props.bay.IsOnline ? props.bay.TextColor ? props.bay.TextColor : null : "#fff",
  };

  const labelCss = {
    color:  props.bay.IsOnline ? props.bay.LabelColor ? props.bay.LabelColor : null : "#000",
  };
  const lockColor = { background: "#FFC107", color: '#FFF' }

  const [isCheck, setIsCheck] = useState(props.bay.is_check)

  const setBay = (e) => {
    if (props.bay.IsOnline) {

      if(!props.bay.is_disable)
      {
        if (props.status == 'available' && !props.bay.IsLocked && !props.bay.IsPaused && !props.bay.IsOutOfOrder && !props.bay.IsServiceRequest && !props.bay.IsPaused && !props.bay.IsLocked && !props.bay.IsEvent) {
          if (e.target.checked) {
            dispatch(checkedBay(e.target.value))
            setIsCheck(1);
            props.handler(e.target.value)
          } else {
            dispatch(checkedBay(null))
            setIsCheck(0);
            props.handler("")
          }
        } else {
          toast.error("Bay is not available");
        }
      }else{
        toast.error("Bay is excluded from preferences");
      }

      
    } else {
      toast.error("The bay must be online for assignment.");
    }

  }

  const showFlagUi = () => {
    if (props.bay.IsOnline) {
      if ((props.bay.BayStateId == "6" || props.bay.BayStateId == "2")) {
        if (props.bay.IsLocked) {
          return (
            <div
              className="bay-nstatus "
              style={{ background: "#FFC107", color: "#000" }}
            >
              <span>Locked</span>
            </div>
          );
        }
      } else {
        if (props.bay.IsLocked) {
          return (
            <div
              className="bay-nstatus "
              style={{ background: "#FFC107", color: "#000" }}
            >
              <span>Locked</span>
            </div>
          );
        }
  
        if (props.bay.IsPaused) {
          return (
            <div
              className="bay-nstatus "
              style={{
                background: "#FFF",
                // color: props.bay.IsEvent ? "#c92167" : props.bay.FillColor,
                color: "#C0155C"
              }}
            >
              <span className="d-flex  pause-icon ">
                <i className="fa-solid fa-triangle-exclamation"></i>
              </span>
            </div>
          );
        }
  
        /* if (!props.bay.IsOnline) {
          return (
            <div
              className="bay-nstatus "
              style={{ background: "#000", color: "#fff" }}
            >
              <span>Offline</span>
            </div>
          );
        } */
  
  
        /* if (!props.bay.IsOnline) {
          return (
            <div
              className="bay-nstatus "
              style={{
                background: "#FFF",
                color: "#000",
              }}
            >
              <span>Offline</span>
            </div>
          );
        } */
        if (props.bay.IsServiceRequest) {
          return (
            <div
              className="bay-nstatus "
              style={{
                background: "#FFC107",
                color: props.bay.IsEvent ? "#ffffff" : "#000",
              }}
            >
              <span>Service</span>
            </div>
          );
        }
  
        if (props.bay.IsBussing) {
          return (
            <div
              className="bay-nstatus "
              style={{
                background: "#2c4cba",
                color:
                  props.status == "available" || props.bay.IsBussing
                    ? "#fff"
                    : props.bay.IsEvent
                      ? "#fff"
                      : "#000",
              }}
            >
              <span>Bussing</span>
            </div>
          );
        }
        if (props.bay.IsDemoMode) {
          return (
            <div
              className="bay-nstatus "
              style={{
                background: "#fff",
                color: props.bay.IsEvent ? "#C0155C" : "#C0155C",
              }}
            >
              <span>Demo</span>
            </div>
          );
        }
      }
    }
    

  };




  let flagCount = 0;
  const showFlagCount = () => {
    /*  if (props.bay.IsLocked) {
       flagCount = flagCount + 1;
     } */
    if (props.bay.IsPaused) {
      flagCount = flagCount + 1;
    }
    if (props.bay.IsOutOfOrder) {
      flagCount = flagCount + 1;
    }
    if (props.bay.IsServiceRequest) {
      flagCount = flagCount + 1;
    }
    if (props.bay.IsDisallowAdditionalTime) {
      flagCount = flagCount + 1;
    }

    if (props.bay.IsBussing) {
      flagCount = flagCount + 1;
    }

    return (flagCount > 1) ? <Link
      to=""
      title=""
      className={`bay-alert`}
    >
      {flagCount}
    </Link> : ""
  }
  return (
    <>
   
      <div
        className={`bay-card  alert-status  ${props.className} ${props.status === 'bussing' ? 'reserv' : ''
          } ${props.isDisable ? 'bayDisable' : ''} `}

      >


        <div className="card-status-time">
         
        </div>
        <label className={` intake-bay-card-box`} style={bayCss}>
          <input
            type="checkbox"
            id={props.bay.BayGuid}
            value={props.bay.BayGuid}
            onChange={setBay}
            name='bays'
          />
          <div className='bay-divider'>
            <div className='d-flex align-items-center justify-content-center sec1'>
              <span>{props.number}</span>
            </div>

            {
              //(props.status == 'available') ? showFlagUi() : null

            }
            {
              showFlagUi()
            }

          </div>


          {
            (props.status == "reserved") ? <div className="bay-reserv">
              <i className="triangle"></i>
            </div> : null
          }

          <div className="bay-card-notifcation">
            <Link
              to=""
              title=""
              className={`bay-alert check ${(checked_intake_bay == props.bay.BayGuid) ? 'd-flex' : 'd-none'
                }`}
            >
              <i className="fa-solid fa-check fw_12"></i>
            </Link>
            {
              showFlagCount()
            }

          </div>
        </label>
        <div className="bay-card-status text-uppercase" style={labelCss}>{props.bay.IsOnline ? props.status : "Offline"}</div>
      </div>
    </>
  )
}

export default IntakeBayBox


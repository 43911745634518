import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import moment from "moment-timezone";

const BayBox = (props) => {
  const bayCss = {
    backgroundColor: props.bay.IsOnline
      ? props.bay.IsEvent
        ? "#4CAF50"
        : props.bay.FillColor
      : props.bay.BayStateName == 'Out Of Order' || props.bay.BayStateName == 'Closed'
        ? props.bay.FillColor
        : "#000",
    borderColor: props.bay.IsOnline
      ? props.bay.IsEvent
        ? "#4CAF50"
        : props.bay.BorderColor

      : props.bay.BayStateName == 'Out Of Order' || props.bay.BayStateName == 'Closed'
        ? props.bay.FillColor
        : "#000",

    color:  props.bay.IsEvent ? "#fff" : props.bay.IsOnline ? props.bay.TextColor ? props.bay.TextColor : null : "#fff",
  };

  const labelCss = {
    color:  props.bay.IsOnline ? props.bay.LabelColor ? props.bay.LabelColor : null : "#000",
  };

  const showFlagUi = () => {
    if (props.bay.IsOnline) {
      if ((props.bay.BayStateId == "6" || props.bay.BayStateId == "2")) {
        if (props.bay.IsLocked) {
          return (
            <div
              className="bay-nstatus "
              style={{ background: "#FFC107", color: "#000" }}
            >
              <span>Locked</span>
            </div>
          );
        }
      } else {
        if (props.bay.IsLocked) {
          return (
            <div
              className="bay-nstatus "
              style={{ background: "#FFC107", color: "#000" }}
            >
              <span>Locked</span>
            </div>
          );
        }
  
        if (props.bay.IsPaused) {
          return (
            <div
              className="bay-nstatus "
              style={{
                background: "#FFF",
                // color: props.bay.IsEvent ? "#c92167" : props.bay.FillColor,
                color: "#C0155C"
              }}
            >
              <span className="d-flex  pause-icon ">
                <i className="fa-solid fa-triangle-exclamation"></i>
              </span>
            </div>
          );
        }
  
        /* if (!props.bay.IsOnline) {
          return (
            <div
              className="bay-nstatus "
              style={{ background: "#000", color: "#fff" }}
            >
              <span>Offline</span>
            </div>
          );
        } */
  
  
        /* if (!props.bay.IsOnline) {
          return (
            <div
              className="bay-nstatus "
              style={{
                background: "#FFF",
                color: "#000",
              }}
            >
              <span>Offline</span>
            </div>
          );
        } */
        if (props.bay.IsServiceRequest) {
          return (
            <div
              className="bay-nstatus "
              style={{
                background: "#FFC107",
                color: props.bay.IsEvent ? "#ffffff" : "#000",
              }}
            >
              <span>Service</span>
            </div>
          );
        }
  
        if (props.bay.IsBussing) {
          return (
            <div
              className="bay-nstatus "
              style={{
                background: "#2c4cba",
                color:
                  props.status == "available" || props.bay.IsBussing
                    ? "#fff"
                    : props.bay.IsEvent
                      ? "#fff"
                      : "#000",
              }}
            >
              <span>Bussing</span>
            </div>
          );
        }
        if (props.bay.IsDemoMode) {
          return (
            <div
              className="bay-nstatus "
              style={{
                background: "#fff",
                color: props.bay.IsEvent ? "#C0155C" : "#C0155C",
              }}
            >
              <span>Demo</span>
            </div>
          );
        }
      }
    }
    

  };

  const renderer1 = ({ hours, minutes, seconds, completed }) => {
    return (
      <span>
        {" "}
        {hours ? hours + "h" : ""} {minutes}m
      </span>
    );
    /* if (minutes <= 100 && minutes > 1) {
          return <span>{zeroPad(minutes)}:00</span>;
        }
    
        if (minutes <= 1) {
          return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
        } */
    if (minutes) {
      return (
        <span
          style={{ color: props.bay.IsEvent ? "#F57522" : props.bay.FillColor }}
        >
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    /* if (minutes <= 100 && minutes > 1) {
          return <span>{zeroPad(minutes)}:00</span>;
        }
    
        if (minutes <= 1) {
          return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
        } */

    return (
      <span
        style={{ color: props.bay.IsEvent ? "#F57522" : props.bay.FillColor }}
      >
        {hours ? hours + "h" : ""} {minutes}m
      </span>
    );
  };

  const bussrenderer = ({ hours, minutes, seconds, completed }) => {
    /* if (minutes <= 100 && minutes > 1) {
          return <span>{zeroPad(minutes)}:00</span>;
        }
    
        if (minutes <= 1) {
          return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
        } */
    /* if (minutes) {
      return (
        <span
          style={{ color: props.bay.IsEvent ? "#F57522" : props.bay.TextColor }}
        >
          {hours ? hours + "h" : ""} {minutes}m
        </span>
      );
    } */
    return (
      <span
        style={{ color: props.bay.IsEvent ? "#F57522" : "#000000" }}
      >
        {hours ? hours + "h" : ""} {minutes}m
      </span>
    );
  };

  /*  const showCounter = (BayStateId, end_time, buss_time) => {
       if (BayStateId == '5') {
         // return <Countdown date={buss_time} overtime={true} zeroPadTime={2} renderer={bussrenderer} />
       } else if (BayStateId == '4') {
         return <Countdown date={end_time} zeroPadTime={2} renderer={renderer} />
       } else {
         return null;
       }
     } */

  const showCounterUI = () => {
    /**/

    if (props.bay.IsServiceRequest) {
      return (
        <>
          <Countdown
            date={moment
              .utc(props.bay.ServiceRequestOn)
              .local()
              .format("YYYY-MM-DD HH:mm:ss")}
            zeroPadTime={2}
            renderer={renderer}
            overtime={true}
          />
        </>
      );
    }

    if (props.bay.IsBussing && props.bay.BayStateId != 6) {
      return (
        <>
          <Countdown
            date={moment
              .utc(props.bay.BussingOn)
              .local()
              .format("YYYY-MM-DD HH:mm:ss")}
            overtime={true}
            zeroPadTime={2}
            renderer={bussrenderer}
          />
        </>
      );
    }
    if (props.bay.IsBussing && props.bay.BayStateId == 6) {
      return (
        <>
          <Countdown
            date={moment
              .utc(props.bay.BussingOn)
              .local()
              .format("YYYY-MM-DD HH:mm:ss")}
            overtime={true}
            zeroPadTime={2}
            renderer={bussrenderer}
          />
        </>
      );
    }

    if (props.bay.BayStateId == 4 && props.bay.PurchasedSessionEndTime) {
      return (
        <>
          <Countdown
            date={moment(props.bay.PurchasedSessionEndTime)
              .format("YYYY-MM-DD HH:mm:ss")}
            zeroPadTime={2}
            now={() => moment(props.bay.LocalVenueTime)}
            renderer={renderer1}
          />
        </>
      );
    }
  };
  let flagCount = 0;
  const showFlagCount = () => {
    /* if (!props.bay.IsOnline) {
      flagCount = flagCount + 1;
    } */
    /*  if (props.bay.IsLocked) {
       flagCount = flagCount + 1;
     } */
    if (props.bay.IsPaused) {
      flagCount = flagCount + 1;
    }
    if (props.bay.IsOutOfOrder) {
      flagCount = flagCount + 1;
    }
    if (props.bay.IsServiceRequest) {
      flagCount = flagCount + 1;
    }
    if (props.bay.IsDisallowAdditionalTime) {
      flagCount = flagCount + 1;
    }

    if (props.bay.IsBussing) {
      flagCount = flagCount + 1;
    }

    if (props.bay.IsDemoMode) {
      flagCount = flagCount + 1;
    }

    return flagCount > 1 ? (
      <Link to="" title="" className={`bay-alert`}>
        {flagCount}
      </Link>
    ) : (
      ""
    );
  };
  return (
    <>
      <div
        className={`bay-card  alert-status  ${props.className} ${props.status === "bussing" ? "reserv" : ""
          } ${props.isDisable ? "bayDisable" : ""}`}
        onClick={props.handler}
      >
        <div className="timewrapper">
          
          <div className="card-status-time">
            {/* {
            (props.bay.timer_option == 1) ? showCounter(props.bay.BayStateId, props.bay.BussingOn, props.bay.bay_state_set_on) : <span className='badgeSq' style={{ background: props.bay.IsEvent ? "#F57522" : props.bay.FillColor}}>
              {
              showCounter(props.bay.BayStateId, props.bay.BussingOn, props.bay.bay_state_set_on)
              }
            </span>
          } */}

            {
              (props.bay.BayStateId == "6" || props.bay.BayStateId == "2") ? "" : (!props.bay.IsLocked && !props.bay.IsPaused && !props.bay.IsOutOfOrder)
                ? showCounterUI()
                : null
            }

          </div>
        </div>


        <div
          className={`bay-card-box  ${props.bay.IsServiceRequest ? "service-requested" : null
            }`}
          style={bayCss}
        >
          {/* <div className={`${props.vip == 2 ? 'bay-vip' : 'd-none'}`}>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
          </div> */}
          <div className="bay-divider">
            {props.bay.HasUpcomingEventsToday ? <div className="bay-event"></div> : null}

            <div className="d-flex flex-wrap align-items-center justify-content-center sec1">
              <span>{props.number}</span>
              {props.bay.BayTypeId == "6" ? (
                <div className="icon-row">
                  <span className="icon-circle">
                    <img src="/img/canon-ic.png" />
                  </span>
                </div>
              ) : null}
            </div>
            {/* {
              !props.bay.IsOnline ? "15" : showFlagUi()
            } */}
            {
              showFlagUi()
            }
          </div>

          {props.status == "reserved" ? (
            <div className="bay-reserv">
              <i className="triangle"></i>
            </div>
          ) : null}

          {/* inline css */}

          {/* locked -> style={{ background: "#dfc235", color: '#fff' }} */}
          {/* Service -> style={{background: "#fff", color: '#2fd7df' }} */}
          {/* Bussing -> style={{background: "#fff", color: '#1ba13c' }} */}
          {/* Paused -> style={{background: "#fff", color: '#c0155c' }} */}
          {/* Out of order -> style={{ background: "#fff", color: '#a6b4bf' }} */}

          <div className="bay-card-notifcation">
            {/* <Link
              className={`bay-alert ${props.status == 'in-use' ? 'd-flex' : 'd-none'
                }`}
            >
              <i className="fa-solid fa-triangle-exclamation"></i>
            </Link> */}
            {
              (props.bay.BayStateId == "6" || props.bay.BayStateId == "2") ? "" : showFlagCount()
            }

          </div>
        </div>
        {props.bay.IsEvent ? (
            <div className="  text-uppercase bay-event-text">Event</div>
          ) : <div className="bay-card-status text-uppercase" style={labelCss}>
          {props.bay.IsOnline ? props.status : "Offline"}

        </div>}
        
      </div>
    </>
  );
};

export default BayBox;

import React from 'react'
import ConfigLayout from '../../components/Layout/ConfigLayout'
import Table from 'react-bootstrap/Table';
import { AD_LOGIN_URL, APP_GUID, APP_NAME, APP_VERSION, AZURE_CLIENT_ID, AZURE_DOMAIN, AZURE_USER_FLOW, BAY_DIRECTION, CLASSIC_API_URL, DATABLE_CLASSIC_API_URL, IS_LUXE, THEME, VENUE_GUID } from '../../constants';


const Index = () => {
  return (
    <ConfigLayout>
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>App Name</td>
          <td>{APP_NAME}</td>
        </tr>
        <tr>
          <td>2</td>
          <td>App Version</td>
          <td>{APP_VERSION}</td>
        </tr>
        <tr>
          <td>3</td>
          <td>App Guid</td>
          <td>{APP_GUID}</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Venue Guid</td>
          <td>{VENUE_GUID}</td>
        </tr>
        <tr>
          <td>5</td>
          <td>Client</td>
          <td>{IS_LUXE ? "Luxe" : 'Atomic'}</td>
        </tr>
        <tr>
          <td>6</td>
          <td>Bay Direction</td>
          <td>{BAY_DIRECTION}</td>
        </tr>
        <tr>
          <td>7</td>
          <td>Theme Name</td>
          <td>{THEME}</td>
        </tr>
        <tr>
          <td>8</td>
          <td>API Url</td>
          <td>{CLASSIC_API_URL}</td>
        </tr>
        <tr>
          <td>9</td>
          <td>Datatable API Url</td>
          <td>{DATABLE_CLASSIC_API_URL}</td>
        </tr>
        <tr>
          <td>10</td>
          <td>AD Login Url</td>
          <td>{AD_LOGIN_URL}</td>
        </tr>
        <tr>
          <td>11</td>
          <td>Azure Domain</td>
          <td>{AZURE_DOMAIN}</td>
        </tr>
        <tr>
          <td>12</td>
          <td>Azure User Flow</td>
          <td>{AZURE_USER_FLOW}</td>
        </tr>
        <tr>
          <td>13</td>
          <td>Azure Client ID</td>
          <td>{AZURE_CLIENT_ID}</td>
        </tr>
      </tbody>
    </Table>
    </ConfigLayout>
  )
}

export default Index
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";
import dataService from "../../apiServices/data.service";
import { toast } from "react-toastify";
import { VENUE_GUID } from "../../constants";
import ThreeDotsLoader from "../ThreeDotsLoader/ThreeDotsLoader";

const SelectProfile = ({ callBack, handleProfileSelect }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [avatar, setAvatar] = useState([]);
  const [profile, SetProfile] = useState("img/profile-pic.png");

  const payLoad = {
    IsPlayer: 0,
    VenueGuid: VENUE_GUID,
  };
  useEffect(() => {
    setLoading(true);
    dataService
      .getAvatar(payLoad)
      .then((response) => {
        if (response.data.status === "success") {
          setAvatar(response.data.response);
          //     console.log(response.data.response);
        } else {
          toast.error(response.data.errorMessage);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
    // .finally(() => {});
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleProfileSelect(profile);
  };

  const updateAvatar = (e) => {
    // console.info(e.target.src);
    var anchor = document.querySelectorAll(".popup-img"); //5
    for (var a = 0; a < anchor.length; a++) {
      anchor[a].parentElement.classList.remove("avatar-checked");
    }
    e.target.parentElement.classList.add("avatar-checked");
    SetProfile(e.target.src);
  };
  return (
    <>
      {loading ? (
        <ThreeDotsLoader />
      ) : (
        <div className="offcanvas-content">
          <Form onSubmit={handleSubmit}>
            <div className="avatar">
              {avatar.map((item, i) => {
                return (
                  <Link onClick={updateAvatar} key={i}>
                    <div className="img-link">
                      <img
                        src={item.AvatarUrl}
                        className="popup-img"
                        loading="lazy"
                        alt="..."
                      />
                    </div>
                  </Link>
                );
              })}
            </div>
            <div className="modal-footer gap-2">
              <Button
                type="button"
                variant="secondary"
                className="btn btn-secondary fw_medium   ms-2 rounded-1"
                data-bs-dismiss="modal"
                onClick={() => {
                  callBack("edit");
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                /* onClick={(event) => {
                              // event.preventDefault();
                              // callBack("profile-select", profile);
                              // callBack("edit");
                              // handleProfileSelect()
                            }} */
                className="btn btn-primary fw_medium ms-2 rounded-1"
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default SelectProfile;

import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import dataService from "../../apiServices/data.service";
import { TIMEZONE, VENUE_GUID } from "../../constants";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import {
  getUniqueKeys,
  groupByKey,
  sortByKeySession,
} from "../../commonFunctions";
import { useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import ThreeDotsLoader from "../ThreeDotsLoader/ThreeDotsLoader";

const SessionPricing = () => {
  const [startDate, setStartDate] = useState(new Date());
  const { venue } = useSelector((state) => state.venues);
  const { venueDateTime } = useSelector((state) => state.venueDateTime);
  const dateTime = moment();
  const [pricingData, setPricingData] = useState([]);
  const [keys, setKey] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const { bay_types } = useSelector((state) => state.bays);
  const [cols, setCols] = useState([]);
  const [show, setShow] = useState();

  useEffect(() => {
    setShow(true);
    dataService
      .getSessionPricing({
        VenueGuid: VENUE_GUID,
        ForDate: venueDateTime.length > 0 ? moment.utc(venueDateTime).format("YYYY-MM-DD") : dateTime.tz(TIMEZONE).format("YYYY-MM-DD"),
        StartTime: venueDateTime.length > 0 ? moment.utc(venueDateTime).format("hh:mm") : dateTime.tz(TIMEZONE).format("hh:mm") ,
      })
      .then((res) => {
        if (res.data.status == "success") {
          setPricingData(groupByKey(res.data.response, "SessionType"));
          setKey(getUniqueKeys(res.data.response, "SessionType"));
          setCols(
            sortByKeySession(
              Object.values(groupByKey(res.data.response, "SessionType"))[0],
              "Id"
            )
          );
          setShow(false);
        } else {
          toast.error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        // Handle error
        toast.error(err.message);
      });
  }, [refresh]);

  const refreshData = () => {
    setRefresh((refresh) => refresh + 1);
  };

  return (
    <div className="pricing">
      <Form>
        <Row className="align-items-baseline">
          <Col md="3">
            <div className="d-flex">
              <Form.Group className=" " controlId="formPlaintextEmail">
                <Form.Label className="py-0">Today's Date</Form.Label>
              </Form.Group>
              <p className="ms-4">{ venueDateTime.length > 0 ? moment.utc(venueDateTime).format("DD MMM YYYY") : dateTime.tz(TIMEZONE).format("DD MMM YYYY") }</p>
            </div>
          </Col>
          {/* <Col md="3">
                        <div className='d-flex'>
                            <Form.Group className=" " controlId="formPlaintextEmail">
                                <Form.Label className='py-0'>
                                    Current Time
                                </Form.Label>
                            </Form.Group>
                            <p className='ms-4'>{dateTime.format('h:mm a')}</p>
                            <Button variant='none' size="sm" className=' fw_medium rounded-1 border-0 ms-2' onClick={refreshData}>
                                <i className="fa-solid fa-arrows-rotate"></i>
                            </Button>
                        </div>
                    </Col> */}
        </Row>
      </Form>
      {/* <hr></hr>
            <Row>
                <Col sm="3">
                    <h6>Season Pricing in Effect</h6>
                </Col>
                <Col sm="2">
                    Cinco de mayo
                </Col>
                <Col sm="2">
                    1/1/2023 - 3/1/2023
                </Col>
            </Row> */}
      <hr></hr>
      <div className="table-responsive">
        {show ? (
          <ThreeDotsLoader />
        ) : (
          <Table className="table pricing-table ">
            <thead>
              <tr>
                <th scope="col " width="200">
                  {" "}
                </th>
                {cols.length > 0
                  ? cols.map((data, i) => {
                      return (
                        <th scope="col " width="200" key={i}>
                          {data.BayTypeName}
                        </th>
                      );
                    })
                  : null}
              </tr>
            </thead>
            <tbody>
              {cols.length > 0
                ? keys.map((data, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <h6>{data}</h6>
                        </td>
                        {sortByKeySession(pricingData[data], "Id").map(
                          (bay_type, di) => {
                            return (
                              <>
                                {" "}
                                <td key={di}>
                                  ${bay_type.CalculatedSessionPrice}
                                </td>
                              </>
                            );
                          }
                        )}
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default SessionPricing;

import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Siderbar from "../Sidebar/Siderbar";
import Footer from '../../components/Footer/Footer'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import Signin from "../../views/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import { getBays } from "../../features/baysSlice";
import { APP_GUID, VENUE_GUID } from "../../constants";
import dataService from "../../apiServices/data.service";
import { useNavigate } from "react-router-dom";
import { updateAccessStatus } from "../../features/profileSlice";
import { toast } from "react-toastify";


const ConfigLayout = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { is_access, profile } = useSelector((state) => state.profile)


    useEffect(() => {

        if (profile && is_access == 2) {
            if (Object.keys(profile).length == 0) {
                /* navigate("/access-denied"); */
                dispatch(updateAccessStatus(1))
            }

            dataService.getAppAccess({
                VenueGuid: VENUE_GUID,
                PersonGuid: Object.keys(profile).length > 0 ? profile.PersonsGuid : "0000",
                AppGuid: APP_GUID,
            })
                .then(function (response) {

                    if (response.data.status == "success") {
                        /* if ((response.data.response.length > 0 && response.data.response && response.data.response[0] && !response.data.response[0].HasAccess)) {
                            navigate("/access-denied");
                            dispatch(updateAccessStatus(0))
                        } */


                        dispatch(updateAccessStatus(1))
                    }
                })
                .catch((err) => {
                    toast.error(err.message)
                });

        }

    }, [profile])



    const [active, setActive] = useState(false);

    const handleActiveState = () => {

        setActive(!active);
    };
    return (
        <>
            <div className="bg-white d-flex p-0">
                   
                    <div className={`content ${active ? "open" : " "}`}>
                        <div className="main-panel px-0 px-sm-4">
                            {props.children}
                        </div>
                        <Footer />
                    </div>
                </div>
        </>
    );
};

export default ConfigLayout;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reload: 0,
  disabledNewAssignmentBtnStatus: false,
  disabledTransferAssignmentBtnStatus: false,
  disabledCancelAssignmentBtnStatus: false,
};

export const reloadDataTableSlice = createSlice({
    name: 'reloadDataTable',
    initialState,
    reducers: {
      reloadTable: (state) => {
        state.reload === 0 ? (state.reload = 1) : (state.reload = 0);
        // state.reload = 1;
      },
      resetReloadTable: (state) => {
        state.reload === 0 ? (state.reload = 1) : (state.reload = 0);
      },
      disableNewAssignmentBtn: (state) => {
        state.disabledNewAssignmentBtnStatus = true ;
      },
      enableNewAssignmentBtn: (state) => {
        state.disabledNewAssignmentBtnStatus = false ;
      },
      disableTransferAssignmentBtn: (state) => {
        state.disabledTransferAssignmentBtnStatus = true ;
      },
      enableTransferAssignmentBtn: (state) => {
        state.disabledTransferAssignmentBtnStatus = false ;
      },
      disableCancelAssignmentBtn: (state) => {
        state.disabledCancelAssignmentBtnStatus = true ;
      },
      enableCancelAssignmentBtn: (state) => {
        state.disabledCancelAssignmentBtnStatus = false ;
      },
    },
  
  });
  
  export const { reloadTable, resetReloadTable, disableNewAssignmentBtn, enableNewAssignmentBtn, disableTransferAssignmentBtn, enableTransferAssignmentBtn, disableCancelAssignmentBtn, enableCancelAssignmentBtn } = reloadDataTableSlice.actions;
  
  
  export default reloadDataTableSlice.reducer;
  
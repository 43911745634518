import { useMsal } from "@azure/msal-react";
import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import authService from "../../apiServices/auth.service";
import { APP_LOGO } from "../../constants";

const Error500 = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const login = () => {
    localStorage.setItem(window.location.hostname, "");
    navigate("/login");
  };
  return (
    <div className="page-wrap vh-100">
      <div className="page-not-found">
        <img src={`./img/${APP_LOGO}`} className="img-key" alt="" />
        <h1 className="text-xl">
          <span>5</span>
          <span>0</span>
          <span>0</span>
        </h1>
        <h4 className="text-md" style={{ marginBottom: "45px" }}>
          SORRY!
        </h4>
        <h4 className="text-sm text-sm-btm">  
          Sorry, unexpected error occurred. <br></br>
          <Button variant="secondary" className="mt-4 " onClick={() => login()}>
            Login
          </Button>
        </h4>
      </div>
    </div>
  );
};

export default Error500;

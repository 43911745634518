import { useMsal } from '@azure/msal-react';
import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import authService from '../../apiServices/auth.service';
import {APP_LOGO, CUSTOM_DOMAIN, LOGOUT_COOKIE_NAME} from '../../constants'
import Cookies from "js-cookie";

const Error404 = () => {

  // login functionality
  const { instance } = useMsal();

  const navigate = useNavigate();

  const logout = () => {
    Cookies.remove(LOGOUT_COOKIE_NAME, { path: '/', domain: CUSTOM_DOMAIN  })
    instance.logoutRedirect({
      postLogoutRedirectUri: "/login",
      onRedirectNavigate: () => {
        // Return false to stop navigation after local logout
        authService.logout();
        return true;
      },
    });
  };
  return (
    <div className="page-wrap vh-100">
      <div className="page-not-found">
        <img src={`./img/${APP_LOGO}`} alt="" className="img-fluid img-key" />
        <h1 className="text-xl">
          <span>4</span>
          <span>0</span>
          <span>4</span>
        </h1>
        <h4 className="text-md" style={{ marginBottom: '45px' }}>SORRY!</h4>
        <h4 className="text-sm text-sm-btm">The page you’re looking for was not found. <br></br>
          <Button variant="secondary" className="mt-4 "
            onClick={() => logout()}>Logout</Button>
        </h4>
      </div>
    </div>
  )
}

export default Error404
import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'
import { Link } from 'react-router-dom'
import { Form } from 'react-bootstrap'

const MenuIcon = (props) => {
  const [open, setOpen] = useState(true)
  return (
    <>
      <div className='menu_icon_box'>
        <div className="nav-item dropdown">
          <Link
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            className="nav-link dropdown-toggle"
          >
            {props.icon}
            <span>{props.title}</span>
          </Link>
        </div>
        <Collapse in={open}>
          <div id="example-collapse-text">
            <Form>
              <div className=" bg-transparent border-0  ">{props.content}</div>
            </Form>
          </div>
        </Collapse>
      </div>
    </>
  )
}

export default MenuIcon

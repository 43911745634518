import { useMsal } from '@azure/msal-react';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import authService from '../../apiServices/auth.service';
import { Button } from 'react-bootstrap';
import {APP_LOGO, APP_NAME, CUSTOM_DOMAIN, LOGOUT_COOKIE_NAME} from '../../constants';
import Cookies from "js-cookie";

const AccessDenied = () => {

  // login functionality
  const { instance } = useMsal();

  const navigate = useNavigate();

  const logout = () => {
    Cookies.remove(LOGOUT_COOKIE_NAME, { path: '/', domain: CUSTOM_DOMAIN  })
    instance.logoutRedirect({
      postLogoutRedirectUri: "/login",
      onRedirectNavigate: () => {
        // Return false to stop navigation after local logout
        authService.logout();
        return true;
      },
    });
  };


  return (
    <div className="page-wrap vh-100">
      <div className="page-not-found">
        <img src={`../img/${APP_LOGO}`}
          className="img-key" alt="" />
        <h1 className="text-xl">
          <span>4</span>
          <span>0</span>
          <span>3</span>
        </h1>
        <h4 className="text-md" style={{ marginBottom: '45px' }}>Access Denied !</h4>
        <h4 className="text-sm text-sm-btm">You don’t have access to this area of application. Speak to your
          administrator to unblock this feature. You can go back to <br></br>
          <Button variant="secondary" className="mt-4 "
            onClick={() => logout()}>Logout</Button>
        </h4>
      </div>
    </div>
  )
}

export default AccessDenied
import React, { useEffect, useState } from "react";
import authService from "../../apiServices/auth.service";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  FormLabel,
  Row,
} from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { APP_GUID, APP_LOGO, VENUE_GUID } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  setUserToken,
  updateAccessStatus,
} from "../../features/profileSlice";
import dataService from "../../apiServices/data.service";
import { ThreeDots } from "react-loader-spinner";
import { useBackendTokenCheckExpirationTime } from "../../hooks/useBackendTokenCheckExpirationTime";

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
    
    const s = useBackendTokenCheckExpirationTime()
  const [showLoader, setShowLoader] = useState(false);
  const { instance } = useMsal();
  const { accounts } = useMsal();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const changeEmailHandler = (event) => {
    setEmail(event.target.value);
  };
  const changePasswordHandler = (event) => {
    setPassword(event.target.value);
  };

  const loginFormHandler = (event) => {
    event.preventDefault();
    authService.login(email, password).then((data) => {
      console.log(data);
      if (data) {
        navigate("/");
      } else {
        toast.error("Invalid Username & Password.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      }
    });
  };

  const getAccessToken = (account) => {
    console.log("get access token");
    console.log("account user name  " + account.username);
    if (account) {
      localStorage.setItem("user", JSON.stringify(account));

      const accessTokenRequest = {
        scopes: ["https://fliteecsiondev.onmicrosoft.com/backend/.default"],
        account: account,
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let idToken = accessTokenResponse.idToken;
          let oldItems = JSON.parse(localStorage.getItem("user")) || [];
          oldItems.token = idToken;

          localStorage.setItem("user", JSON.stringify(oldItems));
        });
    }
  };

  useEffect(() => {
    // console.log(accounts);
  }, [accounts]);

  const doAzureLogin = (loginType) => {
    if (loginType === "popup") {

      // If the popup window is already open, focus it
      if (popupWindow && !popupWindow.closed && popupWindow.focus) {
        popupWindow.focus();
      } else {
      
        instance.loginPopup(loginRequest).then(async function (loginResponse) {
          setShowLoader(true)
          const accessTokenRequest = {
            scopes: ["https://graph.microsoft.com/User.ReadWrite.All"],
            account: loginResponse.account
          };
          instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {

              localStorage.setItem("user", JSON.stringify(loginResponse.account));

              // Acquire token silent success
              let idToken = accessTokenResponse.idToken;


              

              dispatch(setUserToken(idToken))
              let oldItems = JSON.parse(localStorage.getItem('user')) || [];

              oldItems.token = idToken;

              localStorage.setItem('user', JSON.stringify(oldItems));

              //setCookie('user', JSON.stringify(oldItems), window.location.hostname )


             // dispatch(getProfile(loginResponse.account.username))
              dataService.findByEmail({
                Email: loginResponse.account.username, VenueGuid: VENUE_GUID
              }).then((res) => {

                if (res.data.status == "success") {

                  localStorage.setItem('personInfo', JSON.stringify(res.data.response[0]));

                  dataService.getAppAccess({
                    VenueGuid: VENUE_GUID,
                    PersonGuid: res.data.response.length > 0 ? res.data.response[0].PersonsGuid : "0000",
                    AppGuid: APP_GUID,
                  })
                    .then(function (response) {

                      if (response.data.status == "success") {
                         if(response.data.response.length == 0)
                         {
                          navigate("/access-denied");
                          dispatch(updateAccessStatus(0))
                          return true;
                         }else if ((response.data.response.length > 0 && response.data.response && response.data.response[0] && !response.data.response[0].HasAccess)) {
                          navigate("/access-denied");
                          dispatch(updateAccessStatus(0))
                          return true;
                        }

                        navigate("/");
                        dispatch(updateAccessStatus(1))
                      } else {
                        navigate("/access-denied");
                        dispatch(updateAccessStatus(0))
                      }
                    })
                    .catch((err) => {
                      setShowLoader(false)
                      toast.error(err.message)
                    });
                }
              }).catch((err) => {
                setShowLoader(false)
                toast.error(err.message)
              })


            });


          // Display signed-in user content, call API, etc.
        }).catch(e => {
          setShowLoader(false)
          console.log(e);
        });
      }


    }
    if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).then(function (loginResponse) {

        // Display signed-in user content, call API, etc.
      }).catch(e => {
        console.log(e);
      });
    }
  }


  let popupWindow = null;

  const callbackId = instance.addEventCallback((message) => {
    if (message.eventType == "msal:popupOpened") {
      // Save the popup window for focusing later
      popupWindow = message.payload.popupWindow;
    }
  });

  return (
    <Container className="login_container bg_liner1 vh-100" fluid>
      <Row className="  h-100   page_404_wraper ">
        <div className="bg-logo ">
          <Col className="  col-md-12   text-center ">
            <div className="error_img">
              <img src={`./img/${APP_LOGO}`} alt="" className="img-key" />
            </div>
            <h1 className="text-center text-uppercase text-white  mt-5">
              Venue Manager App
            </h1>

            <div className="contant_box_404">
              {
                showLoader ? <ThreeDots
                height="90"
                width="70"
                radius="9"
                color="var(--primary)"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ justifyContent: "center" }}
              />
              : 
              <Button
                type="button"
                className="loginbutton text-white form-control mt-5"
                onClick={() => doAzureLogin("popup")}
              >
                Login
              </Button>
              }
            </div>

            {/*  {
              JSON.stringify(accounts)
            } */}
            {/* <h3 className='text-center mt-5'>Login</h3>
                  <Form onSubmit={loginFormHandler} className='mt-4'>
                    <div className=" mb-3">
                      <FormLabel htmlFor="">Username</FormLabel>
                      <FormControl
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Enter Username"
                        required
                        value={email}
                        onChange={changeEmailHandler}
                      />
                     
                    </div>
                    <div className="mb-4">
                    <FormLabel htmlFor="">Password</FormLabel>
                      <FormControl
                        type="password"
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        required
                        value={password}
                        onChange={changePasswordHandler}
                      />
                    </div>
                    <Button type="submit" className="btn btn-primary bg_liner text-white form-control  w-100 mb-4">
                      Log In
                    </Button>
                  </Form> */}
            {/* <p className="text-center mb-0">Don't have an Account? <Link to="/register"> Sign up </Link> </p> */}
          </Col>
        </div>
      </Row>
    </Container>
  );
};

export default Signin;
import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useSelector, useDispatch } from "react-redux";
import { VENUE_GUID, floorData, bayType } from "../../../../constants";
import IntakeBayBox from "../../../../views/Intake/IntakeBayBox";
import moment from "moment-timezone";
import { getGroupLeadFromGuest, slugify } from "../../../../commonFunctions";
import { IntakeBayFilters } from "../../../../features/baysSlice";
import dataService from "../../../../apiServices/data.service";
import PhoneNumberFormat from "../../../CommonComponents/PhoneNumberFormat";
import { confirmAlert } from "react-confirm-alert";

export const TranferCanves = ({
  show1,
  handleClose1,
  bayCss,
  bayEndTime,
  bayStartTime,
  bayStateName,
  bayNumber,
  bayRowGuid,
  waitlistGuid,
  toast,
  showFlagUi,
  showFlagCount,
  disableBtn,
}) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [reasonToTranfer, setReasonToTranfer] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [guestList, setGuestList] = useState([]);
  const [selectedBay, setSelectedBay] = useState("");
  const [btnIsDisable, setBtnIsDisable] = useState(false);
  const [manualAssignOnly, setManualAssignOnly] = useState(false);

  const [guestGroupLead, setGuestGroupLead] = useState({});

  const { allBays, intake_bay_filter, floors, intake_bays } = useSelector(
    (state) => state.bays
  );
  const [bay, setBay] = useState(allBays[bayRowGuid]);

  const labelCss = {
    color: bay.IsOnline
      ? bay.IsEvent
        ? "#F57522"
        : bay.LabelColor
        ? bay.LabelColor
        : null
      : "#000",
  };

  const floorCollection = floorData;
  const bayTypeCollection = bayType;
  let inTakeBays = intake_bays;

  const onIntakeFloorChecked = (e) => {
    const { value, checked } = e.target;
    if (!checked) {
      dispatch(
        IntakeBayFilters({ value: value, type: "remove", action: "floor" })
      );
    } else {
      dispatch(
        IntakeBayFilters({ value: value, type: "add", action: "floor" })
      );
    }
  };
  const onIntakeTypeChecked = (e) => {
    const { value, checked } = e.target;
    if (!checked) {
      dispatch(
        IntakeBayFilters({ value: value, type: "remove", action: "type" })
      );
    } else {
      dispatch(IntakeBayFilters({ value: value, type: "add", action: "type" }));
    }
  };
  const showCounter = (bay_state_id, end_time, buss_time) => {
    if (bay_state_id == "5") {
      // return <Countdown date={buss_time} overtime={true} zeroPadTime={2} renderer={bussrenderer} />
    } else if (bay_state_id == "4") {
      // return <Countdown date={end_time} zeroPadTime={2} renderer={renderer} />
    } else {
      return null;
    }
  };

  const setBayToGuest = (v) => {
    /* inTakeBays = inTakeBays.map(item => {
      item.is_deleted = 0
      if (item.row_guid == v) {
       item.is_deleted = 1
      }
      return item;
    }); */

    setSelectedBay(v);
  };

  const confirmBay = () => {
    confirmAlert({
      message: "Do you want to transfer bay?",
      buttons: [
        {
          label: "Cancel",
          onClick: () => handleClose1(),
        },
        {
          label: "Confirm",
          onClick: () => {
            setBtnIsDisable(true);
            dataService
              .transferBay({
                VenueGuid: VENUE_GUID,
                BayGuid: bayRowGuid,
                IntakeGuid: waitlistGuid,
                ReasonForTransfer: reasonToTranfer,
                ManualAssignOnly: manualAssignOnly ? 1 : 0,
              })
              .then((res) => {
                if (res.data.status == "success") {
                  toast.success("Bay transfer successfully.");
                  handleClose1();
                  disableBtn();
                  setBtnIsDisable(false);
                } else {
                  toast.error(res.data.errorMessage);
                  setBtnIsDisable(false);
                }
              })
              .catch((err) => {
                // Handle error
                toast.error(err.message);
                setBtnIsDisable(false);
              });
          },
        },
      ],
    });
  };
  useEffect(() => {
    if (show1) {
      dataService
        .getGuestInfoInBay({
          BayGuid: bayRowGuid,
          VenueGuid: VENUE_GUID,
          IntakeGuid: waitlistGuid,
        })
        .then((response) => {
          if (response.data.status === "success") {
            setGuestGroupLead(getGroupLeadFromGuest(response.data.response));
            setGuestList(response.data.response);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }, [show1]);

  return (
    <>
      {/* Available canavas */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="bay"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5>Available Bays</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <Row className="my-2">
              <Col>
                <p>Preference</p>
              </Col>
            </Row>
            <div key={`floor-inline-checkbox`} className="mb-3">
              {floorCollection.map((tag, i) => (
                <Form.Check // prettier-ignore
                  type="checkbox"
                  id={`floor-inline-${tag.value}`}
                  key={i}
                  label={tag.label}
                  inline
                  value={tag.value}
                  checked={
                    intake_bay_filter.Floor.length > 0
                      ? intake_bay_filter.Floor.some((el) => {
                          return el == tag.value;
                        })
                      : false
                  }
                  onChange={onIntakeFloorChecked}
                />
              ))}
            </div>
            <div key={`type-checkbox`} className="mb-3">
              {bayTypeCollection.map((tag, i) => (
                <Form.Check // prettier-ignore
                  type="checkbox"
                  id={`type-inline-${tag.label}`}
                  label={tag.label}
                  inline
                  key={i}
                  value={tag.value}
                  checked={
                    intake_bay_filter.BayTypeId.length > 0
                      ? intake_bay_filter.BayTypeId.some((el) => {
                          return el == tag.value;
                        })
                      : false
                  }
                  onChange={onIntakeTypeChecked}
                />
              ))}
            </div>
            <Row className="bay-wraper mt-3">
              {/* {
        JSON.stringify(inTakeBays)
      } */}
              {Object.values(floors).map((floor, i) => {
                return (
                  <div key={i}>
                    <Col className="d-flex same_box_row row_gap flex-sm-wrap  mb-5 mt-3  align-items-start align-content-end ">
                      {Object.values(inTakeBays).map((bay, i) => {
                        return bay.Floor == floor.Floor ? (
                          <IntakeBayBox
                            /* className={`bay-${bay.states.slug}`} */
                            className={`bay-${slugify(bay.BayStateName)}`}
                            statusTime={bay.BayStateSetOn}
                            number={bay.BayNumber}
                            status={slugify(bay.BayStateName)}
                            vip={bay.BayTypeId}
                            /* handler={() => handleShowOffcanvesTwo(bay.bay_number, floor.floor)} */
                            // handler={() => handleShowOffcanvesTwo()}
                            key={i}
                            bay={bay}
                            moment={moment}
                            isDisable={bay.is_disable}
                            showCounter={showCounter(
                              bay.BayStateId,
                              bay.SessionEndTime,
                              bay.BayStateSetOn
                            )}
                            handler={setBayToGuest}
                          />
                        ) : null;
                      })}
                      <div className="same_line mt-2"></div>
                    </Col>
                  </div>
                );
              })}
            </Row>
            <div className="modal-footer1   ">
              <Button
                type="button"
                className="btn btn-secondary fw_medium   ms-2 rounded-1   "
                variant=" "
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="  btn_primary   fw_medium   ms-2 rounded-1   "
                disabled={btnIsDisable}
                onClick={confirmBay}
              >
                Transfer
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Available canavas */}

      {/* TRANSFER BAY */}
      <Offcanvas
        show={show1}
        onHide={handleClose1}
        placement="end"
        className="off_canves_two off_canves offcanvas offcanvas-end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5>Transfer Bay</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <Row>
              <Col sm={4} xs={6} className="box_details_col me-4">
                <div className="bay-d-wid">
                  <div
                    className={`bay-card-box  ${
                      bay.IsServiceRequest ? "service-requested" : null
                    }`}
                    style={bayCss}
                  >
                    <div className="bay-divider">
                      <div className="d-flex align-items-center justify-content-center sec1">
                        <span className="fw-bold ">{bayNumber}</span>
                      </div>
                      {showFlagUi()}
                    </div>
                  </div>
                  <div className="bay-card-notifcation-offcanvas">
                    {showFlagCount()}
                  </div>
                  <div
                    className="bay-card-status text-uppercase w-100 mb-3"
                    style={labelCss}
                  >
                    {bay.IsOnline ? bayStateName : "Offline"}
                  </div>
                </div>
                {/* <div className="card border-0">
                  <div className="card-body align-items-center px-1 d-flex gap-3">
                    <div
                      className="box_with_bay_color bg_brown  "
                      style={bayCss}
                    ></div>
                    <div>
                      <h2 className="fw-bold">{bayNumber}</h2>
                      <p className="fw-bold">{bayStateName}</p>
                    </div>
                  </div>
                </div> */}
                <div className="ms-4">
                  {guestGroupLead !== undefined &&
                  guestGroupLead !== null &&
                  Object.keys(guestGroupLead).length > 0 ? (
                    <h4>Pin - {guestGroupLead.Pin}</h4>
                  ) : null}
                </div>
              </Col>
              <Col md="7" className="ps-4">
                <div className="inuse-heading">
                  {guestList.map((guest, i) => {
                    return guest.GroupLead ? (
                      <div key={i} className="mb-5">
                        <h5>{guest.Name}</h5>
                        <div className="d-flex mt-2">
                          <p className="me-5">
                            <PhoneNumberFormat phone={guest.Phone} />
                          </p>
                          <p>Pin - {guest.Pin}</p>
                        </div>
                        <hr></hr>
                        <div className="table-responsive mt-3">
                          <Table
                            cellPadding="4"
                            cellSpacing="2"
                            className="border_none_table"
                          >
                            <tbody>
                              <tr>
                                <td className="fw-bold py-2">
                                  Session Started
                                </td>
                                <td className="py-2">
                                  {guest.SessionStartTime
                                    ? moment(guest.SessionStartTime).format(
                                        "h:mm:ss a"
                                      )
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-bold py-2">Purchased Time</td>
                                <td className="py-2">
                                  {moment
                                    .duration(
                                      guest.TotalTimePurchdInMin,
                                      "minutes"
                                    )
                                    .asHours()}{" "}
                                  h
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-bold py-2">
                                  Current Session End
                                </td>
                                <td className="py-2">
                                  {guest.PurchasedSessionEndTime
                                    ? moment(
                                        guest.PurchasedSessionEndTime
                                      ).format("h:mm:ss a")
                                    : "N/A"}
                                </td>
                              </tr>
                              {/* <tr>
                              <td className="fw-bold py-2">Membership Type</td>
                              <td className="py-2">Annual</td>
                            </tr> */}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              </Col>
              <Col sm={12} className="my-2">
                <Form.Check
                  type={"checkbox"}
                  id={`default-${"checkbox"}`}
                  label={`Manual Assign Only`}
                  checked={manualAssignOnly}
                  onChange={(e) => setManualAssignOnly(e.target.checked)}
                />
              </Col>
              <Col sm={12}>
                <Form.Group
                  className="my-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="mb-2">Reason</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={(e) => setReasonToTranfer(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="modal-footer  ">
              <Button
                type="button"
                className="btn btn-secondary   fw_medium   ms-2 rounded-1   "
                variant=" "
                onClick={handleClose1}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="  btn_primary   fw_medium   ms-2 rounded-1"
                onClick={confirmBay}
                disabled={reasonToTranfer.length < 6}
              >
                Confirm
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      {/* TRANSFER BAY */}
    </>
  );
};

//export const API_URL = 'http://localhost/golf-leaderboard/api/auth/';
//export const API_URL = 'https://leaderboardapi.ecsion.com/api/auth/';
//export const API_URL = 'https://leaderboard-laravel.azurewebsites.net/api/auth/';
export const API_URL = process.env.REACT_APP_API_URL;
export const CLASSIC_API_URL = process.env.REACT_APP_CLASSIC_API_GW_URL;
export const DATABLE_CLASSIC_API_URL = process.env.REACT_APP_DATABLE_CLASSIC_API_URL;

export const loginConfig = {
    appId: "f680b28f-3da4-4697-bf33-2fd7e736a06c",
    redirectUri: process.env.REACT_APP_AD_LOGIN_URL,
    scopes : [
        "users.read"
    ],
    authority: 'https://login.microsoftonline.com/28dbe5f4-3a73-4a49-b7bb-0a627a63766b'
}

export const FLITE_API_URL = process.env.REACT_APP_FLITE_API_URL;
export const SIDEBAR_COLOR = process.env.REACT_APP_SIDEBAR_COLOR;
export const HEADER_COLOR = process.env.REACT_APP_HEADER_COLOR;
export const BUTTON_COLOR = process.env.REACT_APP_BUTTON_COLOR;
export const APP_LOGO = process.env.REACT_APP_LOGO;
export const BLACK_LOGO = process.env.REACT_APP_BLACK_LOGO;

export const AZURE_DOMAIN = process.env.REACT_APP_AZURE_DOMAIN;
export const AZURE_USER_FLOW = process.env.REACT_APP_AZURE_USER_FLOW;
export const AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID;
export const AD_LOGIN_URL = process.env.REACT_APP_AD_LOGIN_URL;

export const THEME = process.env.REACT_APP_THEME;

export const FRANCHISE_ID = process.env.REACT_APP_FRANCHISE_ID;
export const SUBVENUE_ID = process.env.REACT_APP_SUBVENUE_ID;
export const APP_NAME = process.env.REACT_APP_APP_NAME;
export const APP_VERSION = process.env.REACT_APP_APP_VERSION;
export const APP_GUID = process.env.REACT_APP_APP_GUID;
export const VENUE_GUID = process.env.REACT_APP_VENUE_GUID;
export const IS_LUXE = process.env.REACT_APP_IS_LUXE;
export const BAY_DIRECTION = process.env.REACT_APP_BAY_DIRECTION;

/**
 * set COUNT_DOWN_TIMER in minute
 */
export const COUNT_DOWN_TIMER = 5;

export const INTAKE_COUNT_DOWN_TIMER = 1;

export const appInfo = {
  VenueGUID: VENUE_GUID,
  AppName: APP_NAME,
  AppVersion: APP_VERSION,
  AppGUID: APP_GUID,
  } 

export const bayStates = 
[
  {
    "BayStateId": "1",
    "BayStateName": "Available",
    "FillColor": "#4CAF50",
    "BorderColor": "#4CAF50",
    "LabelColor": "#4CAF50",
    "TextColor": "#ffffff",
    "SortOrder": null
  },
  {
    "BayStateId": "2",
    "BayStateName": "Demo",
    "FillColor": "#C0155C",
    "BorderColor": "#C0155C",
    "LabelColor": "#C0155C",
    "TextColor": "#ffffff",
    "SortOrder": null
  },
  {
    "BayStateId": "3",
    "BayStateName": "Assigned",
    "FillColor": "#C0155C",
    "BorderColor": "#C0155C",
    "LabelColor": "#C0155C",
    "TextColor": "#ffffff",
    "SortOrder": null
  },
  {
    "BayStateId": "4",
    "BayStateName": "In use",
    "FillColor": "#C0155C",
    "BorderColor": "#C0155C",
    "LabelColor": "#C0155C",
    "TextColor": "#ffffff",
    "SortOrder": null
  },
  /* {
    "BayStateId": 5,
    "BayStateName": "Out of Order",
    "FillColor": "#C9D6DE",
    "BorderColor": "#A6B4BF",
    "LabelColor": "#607279",
    "TextColor": "#607279",
    "SortOrder": null
  }, */
  ,
  {
    "BayStateId": "6",
    "BayStateName": "Offline",
    "FillColor": "#C9D6DE",
    "BorderColor": "#A6B4BF",
    "LabelColor": "#607279",
    "TextColor": "#607279",
    "SortOrder": null
  }
]


export const floorData = IS_LUXE ? [
  { value: "1", label: "1st Floor" },
  { value: "2", label: "2nd Floor" },
  { value: "3", label: "3rd Floor" },

] : [
  { value: "1", label: "1st Floor" },
  { value: "2", label: "2nd Floor" },
  { value: "3", label: "3rd Floor" },
  { value: "4", label: "4th Floor" },
  { value: "5", label: "5th Floor" },

];

export const bayType = [
  { value: "1", label: "Standard Bays" },
  { value: "2", label: "VIP Bays" },
  { value: "3", label: "Suite" },

];

export const roles = {
  "1": "Admin",
  "2": "Manager",
  "3": "Employee"
}


export const ADMIN_APP_SWITCH_URL = process.env.REACT_APP_ADMIN_APP_SWITCH_URL;
export const SIMULATOR_APP_SWITCH_URL = process.env.REACT_APP_SIMULATOR_APP_SWITCH_URL;
export const EMPLOYEE_APP_SWITCH_URL = process.env.REACT_APP_EMPLOYEE_APP_SWITCH_URL;
export const FLOOR_APP_SWITCH_URL = process.env.REACT_APP_FLOOR_APP_SWITCH_URL;
export const EVENT_APP_SWITCH_URL = process.env.REACT_APP_EVENT_APP_SWITCH_URL;
export const BUSSINESS_REPORTING_APP_SWITCH_URL = process.env.REACT_APP_BUSSINESS_REPORTING_APP_SWITCH_URL;

export const TIMEZONE = process.env.REACT_APP_TIMEZONE;
export const C_DOMAIN = process.env.REACT_APP_C_DOMAIN;

export const CUSTOM_DOMAIN = process.env.REACT_APP_CUSTOM_DOMAIN;
export const LOGOUT_VARNAME = process.env.REACT_APP_LOGOUT_VARNAME;
export const LOGOUT_COOKIE_NAME = process.env.REACT_APP_LOGOUT_COOKIE_NAME;
export const RESPONSIVE_APP_LOGO = process.env.REACT_APP_RESPONSIVE_LOGO;


export const hoverData = {
  HOLD: "A reservation is being held for the guest.",
  NOT_RECONCILED: "A reservation is incomplete at the current time.",
  BOOKED:
    "The guest has booked the reservation, but it has yet to be confirmed.",
  LEFT_MESSAGE:
    "The guest has been contacted by venue staff and left a message.",
  NO_ANSWER:
    "The guest has been contacted by venue staff and there was no answer.",
  WRONG_NUMBER:
    "An attempt to contact the guest was made, but they provided the wrong phone number.",
  CONFIRMED: "The guest has confirmed the booking.",
  PRE_ARRIVED:
    "The expected number of guests in the party have arrived at the venue.",
  PREPARTIALLYARRIVED:
    "A partial number of guests in the party has arrived at the venue.",
  LATE: "The guest has not arrived at the expected arrival time of the booked reservation.",
  CANCELED: "The guest has canceled the previously booked reservation.",
  NO_SHOW: "The guest never arrived at the venue to fulfill the reservation.",
  NO_ENTRY:
    "The guest was not permitted entry to the venue (Nightlife Only).",
  ARRIVED_PARTIAL:
    "A partial number of guests in the party have been seated.",
  ARRIVED: "The expected number of guests in the party have been seated.",
  ORDER_PLACED: "The table has placed an order.",
  FIRST_COURSE: "The table is on the first course of the meal.",
  SECOND_COURSE: "The table is on the second course of the meal.",
  THIRD_COURSE: "The table is on the third course of the meal.",
  FOURTH_COURSE: "The table is on the fourth course of the meal.",
  DESSERT: "The table is on the dessert course of the meal.",
  CHECK_DROPPED: "The waiter has dropped the check(s) at the table.",
  PAID: "The check(s) have been paid by the guest(s).",
  BUS_TABLE: "The table has been marked for bussing service.",
  COMPLETE: "The reservation has been completed.",
};
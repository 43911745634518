import { useEffect, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import tokenService from '../apiServices/token.service';

const REFRESH_THRESHOLD = 300; // 5 minutes in seconds
const TOKEN_CHECK_INTERVAL = 60000; // 1 minute in milliseconds


export const useBackendTokenCheckExpirationTime = () => {

  const interval = useRef(null);
  const { instance, accounts } = useMsal();
  const acquireTokenWithRefreshToken = async () => {
    try {
      if (accounts.length && instance) {
        const response = await instance.acquireTokenSilent({
          scopes: ["https://graph.microsoft.com/User.ReadWrite.All"],
          account: accounts[0],
        });

        let idToken = response.idToken;
          let oldItems = JSON.parse(localStorage.getItem("user")) || [];
          oldItems.token = idToken;

          localStorage.setItem("user", JSON.stringify(oldItems));
      }
    } catch (error) {
      console.log('Error refreshing token', error);  // Handle token refresh error
    }
  };
  useEffect(() => {
    const checkTokenExpiry = () => {
      const backendAccessToken = tokenService.getExpiryTimeForToken();
      if (backendAccessToken) {
     
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const timeUntilExpiry = backendAccessToken.exp - currentTime;
       
        if (timeUntilExpiry <= REFRESH_THRESHOLD) {     // Token is about to expire or has expired, refresh it
          acquireTokenWithRefreshToken();
        }
      }else{
      }
    };
    interval.current = setInterval(checkTokenExpiry, TOKEN_CHECK_INTERVAL);
    checkTokenExpiry(); // Check token expiry immediately after mounting     
    return () => clearInterval(interval.current);
  }, []);
  return null; // You might not need to return anything from this hook

};
import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import dataService from "../../../../apiServices/data.service";
import { ThreeDots } from "react-loader-spinner";
import ThreeDotsLoader from "../../../../views/ThreeDotsLoader/ThreeDotsLoader";

const Archive = ({ venueId, toast, bayGuid }) => {
  const [notes, setNotes] = useState([]);
  const [show, setShow] = useState();

  const genericNotesLoad = {
    table: "bay_notes",
    params: { keys: "is_archived, id, notes,row_guid" },
    filter: {
      is_archived: 1,
      is_deleted: 0,
      venue_guid: venueId,
      bay_guid: bayGuid,
    },
  };

  const updatePayload = ({ row_guid, fieldName, fieldValue, updatedBy }) => {
    return {
      data: {
        [fieldName]: fieldValue,
        updated_by: updatedBy,
      },
      filter: {
        row_guid: row_guid,
      },
    };
  };

  const handleAction = (id, action, tableName) => {
    let payload;
    let fieldNameUpdate;
    switch (action) {
      case "is_archived":
        fieldNameUpdate = "Un Archived";
        payload = updatePayload({
          row_guid: id,
          fieldName: "is_archived",
          fieldValue: 0,
          updatedBy: "mukul",
        });
        dataService
          .updateGeneric({
            table: tableName,
            ...payload,
            venue_guid: venueId,
          })
          .then((res) => {
            if (res.data.status === "success") {
              if (tableName === "bay_notes") {
                setNotes(notes.filter((item) => item.row_guid != id));
              }
              toast.success(`Marked as ${fieldNameUpdate}`);
            } else {
              toast.error(res.data.errorMessage[0]);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
        break;

      default:
        throw new Error(`Invalid type: ${action}`);
    }
  };

  const updateActions = (noteGuid, flag) => {
    dataService
      .archiveNotes({
        BayGuid: bayGuid,
        VenueGuid: venueId,
        BayNotesGuid: noteGuid,
        IsArchived: 0
      })
      .then((response) => {
        if (response.data.status == "success") {
          setNotes(notes.filter((item) => item.BayNotesGuid != noteGuid));
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    setShow(true);
    dataService
      .getNotes({
        IsArchived: 1,
        VenueGuid: venueId,
        BayGuid: bayGuid,
      })
      .then((response) => {
        if (response.data.status == "success") {
          setNotes(response.data.response);
        }
        setShow(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  return (
    <div className={`tab-pane mt-4`}>
      <div className="table-responsive">
        {show ? (
          <ThreeDotsLoader />
        ) : (
          <Table className="table note-table">
            <tbody>
              {notes && notes.length > 0
                ? notes.map((note, i) => {
                    return (
                      <tr key={i}>
                        <td>{note.Notes}</td>
                        <td width="100">
                          <Button
                            variant="none"
                            onClick={() => updateActions(note.BayNotesGuid, 0)}
                          >
                            Unarchive
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </Table>
        )}
      </div>

      {/* <Row>
    <Col>
      <span className="fw_medium">Notes</span>
      <div className="mt-2">
        <FloatingLabel controlId="floatingTextarea2" label="Note">
          <Form.Control
            as="textarea"
            className="bg_sky_blue"
            placeholder="Leave a comment here"
            style={{ height: '100px' }}
          />
        </FloatingLabel>
      </div>

    </Col>
  </Row> */}

      {/* <div className="modal-footer mt-5 ">
    <Button type="button" className="btn btn-secondary border-0 fw_medium   ms-2 rounded-1   " variant="secondary"   >Cancel</Button>
    <Button type="button" className="  btn_primary border-0 fw_medium   ms-2 rounded-1   ">Save</Button>
  </div> */}
    </div>
  );
};

export default Archive;

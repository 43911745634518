import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  ListGroup,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import {
  getProfile,
  setUserToken,
  updateAccessStatus,
} from "../../features/profileSlice";
import { useDispatch } from "react-redux";
import authService from "../../apiServices/auth.service";
import dataService from "../../apiServices/data.service";
import { APP_GUID, APP_NAME, VENUE_GUID } from "../../constants";
import { toast } from "react-toastify";
import { checkExpTime, savedUsers } from "../../commonFunctions";
import ThreeDotsLoader from "../ThreeDotsLoader/ThreeDotsLoader";
import {
  BrowserAuthError,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

const AccountSelection = () => {
  const users = savedUsers();
  const [show, setShow] = useState();
  const [showAccountSelection, setShowAccountSelection] = useState(false);
  const [activeAccount, setActiveAccount] = useState([]);
  const { instance, accounts } = useMsal();
  const [diff, setDiff] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryString = window.location.search;
  const [activeUser, setActiveUser] = useState("");

  const urlParams = new URLSearchParams(queryString);
  const qValue = urlParams.get("q");
  useEffect(() => {
    const initializeUser = () => {
      const users = savedUsers();
      const setAccountId = new Set();
      instance.getAllAccounts().forEach((account) => {
        if (checkExpTime(accounts)) setAccountId.add(account.homeAccountId);
      });

      setActiveAccount(Array.from(setAccountId));

      if (!users) {
        // If there are no saved users, navigate to the login page and exit
        navigate("/login");
        return;
      }

      if (qValue) {
        var userActive = false;
        const filteredUsers = users.filter(
          (user) => user?.homeAccountId === qValue
        );
        instance.getAllAccounts().map((key, index) => {
          if (filteredUsers[0]?.homeAccountId == key?.homeAccountId) {
            userActive = true;
          }
        });

        if (filteredUsers.length != 0) {
          if (filteredUsers.length > 0 && userActive) {
            const account = filteredUsers[0];
            if (
              instance.getActiveAccount() != null &&
              account.homeAccountId != instance.getActiveAccount().homeAccountId
            ) {
              setActiveUser(instance.getActiveAccount());
              setShowAccountSelection(true);
            } else {
              instance.setActiveAccount(account);
              const idToken = account.idToken;
              dispatch(setUserToken(idToken));

              const accounts = instance.getAllAccounts();
              const newUsers = [...users, ...accounts];

              const mergedData = newUsers.reduce((result, item) => {
                result[item.homeAccountId] = item;
                return result;
              }, {});

              localStorage.setItem(
                window.location.hostname,
                JSON.stringify(Object.values(mergedData))
              );
              instance
                .acquireTokenSilent({
                  loginRequest,
                  account: account,
                })
                .then(function (accessTokenResponse) {
                  let accessToken = accessTokenResponse;
                  dispatch(setUserToken(accessToken.idToken));
                  localStorage.setItem("user", JSON.stringify(account));
                  const oldItems = JSON.parse(localStorage.getItem("user"));
                  oldItems.token = accessToken.idToken;
                  localStorage.setItem("user", JSON.stringify(oldItems));

                  dispatch(getProfile(account.username));

                  dataService
                    .findByEmail({
                      Email: account.username,
                      VenueGuid: VENUE_GUID,
                    })
                    .then((res) => {
                      if (res.data.status == "success") {
                        localStorage.setItem(
                          "personInfo",
                          JSON.stringify(res.data.response[0])
                        );
                        dataService
                          .getAppAccessSingle({
                            VenueGuid: VENUE_GUID,
                            PersonGuid:
                              res.data.response.length > 0
                                ? res.data.response[0].PersonsGuid
                                : "0000",
                            AppGuid: APP_GUID,
                          })
                          .then(function (response) {
                            if (response.data.status == "success") {
                              if (response.data.response.length == 0) {
                                navigate("/access-denied");
                                dispatch(updateAccessStatus(0));
                                return true;
                              } else if (
                                response.data.response.length > 0 &&
                                response.data.response &&
                                response.data.response[0] &&
                                !response.data.response[0].HasAccess
                              ) {
                                navigate("/access-denied");
                                dispatch(updateAccessStatus(0));
                                return true;
                              }
                              toast.success("Login Successful");
                              navigate("/");
                              dispatch(updateAccessStatus(1));
                            } else {
                              navigate("/access-denied");
                              dispatch(updateAccessStatus(0));
                            }
                          })
                          .catch((err) => {
                            toast.error(err.message);
                          });
                      }
                    })
                    .catch((err) => {
                      toast.error(err.message);
                    });
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          } else {
            if (instance.getActiveAccount()) {
              setActiveUser(instance.getActiveAccount());
              setShowAccountSelection(true);
            } else {
              setActiveUser("");
              toast.info(
                "The requested account has been signed out. Please login in order to continue"
              );
              setShowAccountSelection(true);
            }
          }
        } else {
          setActiveUser("");
          toast.error(
            "The requested user cannot be found on this site. Please add a user account"
          );
          setShowAccountSelection(true);
        }
      } else {
        setShowAccountSelection(true);
      }
    };

    initializeUser();
  }, [qValue, diff]);

  const handleListItemClick = (e, account) => {
    e.stopPropagation();
    e.preventDefault();
    instance.setActiveAccount(account);

    if (!account) {
      instance
        .loginPopup({
          ...loginRequest,
          prompt: "login",
        })
        .then(async function (loginResponse) {
          const accounts = instance.getAllAccounts();
          const newUsers = [...users, ...accounts];
          setDiff(!diff);
          const mergedData = newUsers.reduce((result, item) => {
            const { homeAccountId } = item;
            if (!result[homeAccountId]) {
              result[homeAccountId] = item;
            } else {
              result[homeAccountId] = { ...result[homeAccountId], ...item };
            }
            return result;
          }, {});

          const mergedArray = Object.values(mergedData);
          localStorage.setItem(
            window.location.hostname,
            JSON.stringify(mergedArray)
          );
        })
        .catch((error) => {
          if (error instanceof BrowserAuthError) {
            toast.info(
              "Authentication process was canceled by the user. Please try again if you wish to proceed."
            );
          } else {
            toast.error(err.message);
          }
        });
    } else {
      const checkUserName = instance.getAccountByUsername(account.username);
      if (checkUserName) {
        localStorage.setItem("user", JSON.stringify(account));
        instance
          .acquireTokenSilent({ loginRequest, account: account })
          .then((accessTokenResponse) => {
            setShow(true);
            const accounts = instance.getAllAccounts();
            const newUsers = [...users, ...accounts];

            setDiff(!diff);
            const mergedData = newUsers.reduce((result, item) => {
              const { homeAccountId } = item;
              if (!result[homeAccountId]) {
                result[homeAccountId] = item;
              } else {
                result[homeAccountId] = { ...result[homeAccountId], ...item };
              }
              return result;
            }, {});

            let accessToken = accessTokenResponse;
            dispatch(setUserToken(accessToken.idToken));

            localStorage.setItem("user", JSON.stringify(account));
            const oldItems = JSON.parse(localStorage.getItem("user"));
            oldItems.token = accessToken.idToken;

            localStorage.setItem("user", JSON.stringify(oldItems));
            dispatch(getProfile(account.username));

            dataService
              .findByEmail({
                Email: account.username,
                VenueGuid: VENUE_GUID,
              })
              .then((res) => {
                if (res.data.status == "success") {
                  localStorage.setItem(
                    "personInfo",
                    JSON.stringify(res.data.response[0])
                  );
                  localStorage.setItem(
                    window.location.hostname,
                    JSON.stringify(Object.values(mergedData))
                  );
                  dispatch(getProfile(account.username));
                  dataService
                    .getAppAccessSingle({
                      VenueGuid: VENUE_GUID,
                      PersonGuid:
                        res.data.response.length > 0
                          ? res.data.response[0].PersonsGuid
                          : "0000",
                      AppGuid: APP_GUID,
                    })
                    .then(function (response) {
                      if (response.data.status == "success") {
                        if (response.data.response.length == 0) {
                          navigate("/access-denied");
                          dispatch(updateAccessStatus(0));
                          return true;
                        } else if (
                          response.data.response.length > 0 &&
                          response.data.response &&
                          response.data.response[0] &&
                          !response.data.response[0].HasAccess
                        ) {
                          navigate("/access-denied");
                          dispatch(updateAccessStatus(0));
                          return true;
                        }

                        navigate("/");
                        dispatch(updateAccessStatus(1));
                        setShow(false);
                      } else {
                        navigate("/access-denied");
                        dispatch(updateAccessStatus(0));
                      }
                    })
                    .catch((error) => {
                      if (error instanceof BrowserAuthError) {
                        toast.info(
                          "Authentication process was canceled by the user. Please try again if you wish to proceed."
                        );
                      } else {
                        toast.error(err.message);
                      }
                    });
                }
              })
              .catch((err) => {
                toast.error(err.message);
                setShow(false);
              });
          })
          .catch((error) => {
            toast.info("Session Expired please sign in again");
            if (error instanceof InteractionRequiredAuthError) {
              instance.logoutRedirect({
                postLogoutRedirectUri: "/login",
                account: instance.getActiveAccount(),
                onRedirectNavigate: () => {
                  setDiff(!diff);
                  localStorage.setItem(
                    "bay-mgr-logout",
                    Date.now().toString()
                  );
                  authService.logout();
                  return false;
                },
              });
            } else {
              console.log("An unknown error occured!");
            }
          });
      } else {
        instance
          .loginPopup({
            ...loginRequest,
            prompt: "login",
            loginHint: account.username,
          })
          .then(async function (loginResponse) {
            localStorage.setItem("user", JSON.stringify(account));
            const accounts = instance.getAllAccounts();
            const newUsers = [...users, ...accounts];
            setDiff(!diff);
            const mergedData = newUsers.reduce((result, item) => {
              const { homeAccountId } = item;
              if (!result[homeAccountId]) {
                result[homeAccountId] = item;
              } else {
                result[homeAccountId] = { ...result[homeAccountId], ...item };
              }
              return result;
            }, {});

            localStorage.setItem(
              window.location.hostname,
              JSON.stringify(Object.values(mergedData))
            );
            setDiff(!diff);
          })
          .catch((error) => {
            if (error instanceof BrowserAuthError) {
              toast.info(
                "Authentication process was canceled by the user. Please try again if you wish to proceed."
              );
            }
          });
      }
    }
  };

  const removeAccount = (e, account) => {
    e.stopPropagation();
    e.preventDefault();
    var filteredUsers = [];
    Object.keys(users).map((key, index) => {
      const user = users[key];
      if (user.homeAccountId !== account.homeAccountId) {
        filteredUsers.push(user);
      }
      return filteredUsers;
    });

    const checkUserName = instance.getAccountByUsername(account.username);
    if (checkUserName) {
      instance.setActiveAccount(account);
      instance
        .acquireTokenSilent(loginRequest)
        .then((response) => {
          instance.logoutRedirect({
            postLogoutRedirectUri: "/login",
            account: account,
            idTokenHint: response.idToken,
            onRedirectNavigate: () => {
              setDiff(!diff);
              localStorage.setItem(
                window.location.hostname,
                JSON.stringify(filteredUsers)
              );
              authService.logout();
              return instance.getAllAccounts().length > 0 ? false : true;
              // return true;
            },
          });
        })
        .catch((err) => {
          // could also check if err instance of InteractionRequiredAuthError if you can import the class.
          if (err.name === "InteractionRequiredAuthError") {
            instance.logoutRedirect({
              postLogoutRedirectUri: "/login",
              account: instance.getActiveAccount(),
              onRedirectNavigate: () => {
                setDiff(!diff);
                localStorage.setItem(
                  "bay-mgr-logout",
                  Date.now().toString()
                );
                authService.logout();
                return false;
              },
            });
            localStorage.setItem(
              window.location.hostname,
              JSON.stringify(filteredUsers)
            );
          }
        });
    } else {
      localStorage.setItem(
        window.location.hostname,
        JSON.stringify(filteredUsers)
      );
      window.location.reload();
    }
  };

  return (
    <>
      <Container className="login_container bg_liner1 vh-100" fluid>
        <Row className=" h-100 account-main-wrapper ">
          <div className="box-wrap">
            <div className="bg-logo ">
              <h1 className="text-center text-uppercase text-white my-4">
                {APP_NAME}
              </h1>
              <Col className="col-md-12 text-center account-wrapper h-100vh">
                {!showAccountSelection ? (
                  <>
                    {" "}
                    <div className="text-start ps-2">
                      <h5 className="fw-bold mb-1 d-flex justify-content-center">
                        Checking if the user is already logged in{" "}
                      </h5>
                      <div className="snippet" data-title="dot-pulse">
                        <div className="stage">
                          <div className="dot-pulse"></div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="contant_box_404">
                    <div className="text-start ps-2">
                      <h3 className="fw-bold mb-1">Pick an account</h3>
                      <p>to continue to Microsoft Azure</p>
                    </div>

                    <ListGroup variant="flush">
                      {Object.keys(users).map((key, index) => {
                        const account = users[key];
                        return (
                          <div key={index}>
                            <div key={index} className="list-group-container">
                              <ListGroup.Item
                                onClick={(e) => handleListItemClick(e, account)}
                                key={account?.homeAccountId}
                                className={`d-flex justify-content-around align-items-start
                                        ${
                                          instance.getActiveAccount()
                                            ?.homeAccountId ==
                                          account?.homeAccountId
                                            ? " active"
                                            : ""
                                        }`}
                              >
                                <div className="ms-2 me-auto">
                                  <div className="fw-bold text-start">
                                    <Link>{account?.username}</Link>
                                  </div>
                                  <p className="m-0">{account?.name}</p>
                                  <p className="m-0">
                                    Signed
                                    {activeAccount.includes(
                                      account?.homeAccountId
                                    )
                                      ? " in "
                                      : " out "}{" "}
                                  </p>
                                </div>
                              </ListGroup.Item>

                              <Button
                                onClick={(e) => removeAccount(e, account)}
                                className="remove-btn"
                                variant="none"
                              >
                                {" "}
                                Remove
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                      <div className="list-group-container">
                        <ListGroup.Item
                          className="d-flex align-items-start"
                          button={"true"}
                          onClick={(e) => handleListItemClick(e, null)}
                        >
                          <div className="ms-2 me-auto">
                            <div className="fw-bold text-start">
                              <Link>Add another account</Link>
                            </div>
                          </div>
                        </ListGroup.Item>
                      </div>
                      <p className="mt-3">
                        {activeUser != "" ? (
                          <>
                            {" "}
                            <Button
                              variant="none"
                              className="active-user-switch"
                            >
                              {" "}
                              Do You want to continue with
                              <strong>
                                <Link
                                  onClick={(e) =>
                                    handleListItemClick(e, activeUser)
                                  }
                                  key={activeUser.homeAccountId}
                                >
                                  {" "}
                                  {activeUser.name}
                                </Link>
                              </strong>
                            </Button>
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                    </ListGroup>
                    {show ? <ThreeDotsLoader /> : ""}
                  </div>
                )}
              </Col>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AccountSelection;

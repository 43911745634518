import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Offcanvas from "react-bootstrap/Offcanvas";
import dataService from "../../../apiServices/data.service";
import { VENUE_GUID } from "../../../constants";
import { toast } from "react-toastify";
import ThreeDotsLoader from "../../../views/ThreeDotsLoader/ThreeDotsLoader";

const Equipment = ({ handleShow4, handleClose4, show4 }) => {
  const [equipment, setEquipment] = useState([]);
  const [show, setShow] = useState();

  /* For description*/

  const formikEquipment = useFormik({
    initialValues: {
      name: "",
      description: "",
      quantity: "1",
    },
    validationSchema:  Yup.object().shape({
      name: Yup.string().trim().required("Please enter Name."),
      description: Yup.string().trim().required("Please enter Note."),
    }),

    onSubmit: (values, { resetForm }) => {
      dataService
        .insertGeneric({
          table: "bay_equipments",
          data: { ...values, venue_guid: VENUE_GUID },
        })
        .then((res) => {
          if (res.data.status === "success") {
            toast.success("Value Saved successfully");
            setShow4(false);
            resetForm();
          } else {
            toast.error(res.data.errorMessage[0]);
          }
        });
    },
  });

  const genericEquipmentLoad = {
    table: "bay_equipments",
    params: { keys: "is_archived, id, name,row_guid,description,quantity" },
    filter: {
      is_archived: 0,
      is_deleted: 0,
      venue_guid: VENUE_GUID,
    },
  };

  const updatePayload = ({ row_guid, fieldName, fieldValue, updatedBy }) => {
    return {
      data: {
        [fieldName]: fieldValue,
        updated_by: updatedBy,
      },
      filter: {
        row_guid: row_guid,
      },
    };
  };

  const handleAction = (id, action, tableName) => {
    let payload;
    let fieldNameUpdate;
    switch (action) {
      case "is_archived":
        fieldNameUpdate = "IsArchived";
        payload = updatePayload({
          row_guid: id,
          fieldName: "is_archived",
          fieldValue: "1",
          updatedBy: "mukul",
        });
        dataService
          .updateGeneric({
            table: tableName,
            ...payload,
            venue_guid: VENUE_GUID,
          })
          .then((res) => {
            if (res.data.status === "success") {
              if (tableName === "bay_equipments") {
                setEquipment(equipment.filter((item) => item.row_guid != id));
              }
              toast.success(`Marked as ${fieldNameUpdate}`);
            } else {
              toast.error(res.data.errorMessage[0]);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
        break;

      default:
        throw new Error(`Invalid type: ${action}`);
    }
  };

  useEffect(() => {
    setShow(true);
    dataService
      .getEquipments({
        VenueGuid: VENUE_GUID,
        IsArchived: 0,
        IsDeleted: 0,
      })
      .then((response) => {
        if (response.data.status === "success") {
          setEquipment(response.data.response);
        }
        setShow(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  return (
    <>
      <div className={`tab-pane mt-4 `}>
        <div className="table-responsive">
          {show ? (
            <ThreeDotsLoader />
          ) : (
            <Table className="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col" width="150">
                    Quantity
                  </th>
                  {/*  <th scope="col" width="40"></th> */}
                </tr>
              </thead>
              <tbody>
                {equipment && equipment.length > 0 ? (
                  <>
                    {equipment.map((equipment, i) => {
                      return (
                        <tr key={equipment.BayEquipmentsGuid}>
                          <td>{equipment.Name}</td>
                          <td>{equipment.Quantity}</td>
                          {/*  <td>
                          <Button
                            variant="none"
                            border-0
                            onClick={() =>
                              handleAction(
                                equipment.row_guid,
                                'is_archived',
                                'bay_equipments',
                              )
                            }
                          >
                            Archive
                          </Button>
                        </td> */}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </tbody>
            </Table>
          )}
        </div>
      </div>
      {/* ADD EQUIPMENTS */}
      <Offcanvas
        show={show4}
        onHide={handleClose4}
        placement="end"
        className="off_canves_two off_canves offcanvas offcanvas-end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5>Add Equipment</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={formikEquipment.handleSubmit}>
            <Row>
              <Col>
                <div className="mt-2">
                  <FloatingLabel controlId="floatingTextarea2" label="Name">
                    <Form.Control
                      type="text"
                      className="bg_sky_blue"
                      name="name"
                      placeholder="Leave a comment here"
                      onChange={formikEquipment.handleChange}
                      onBlur={formikEquipment.handleBlur}
                      value={formikEquipment.values.name}
                    />
                    {formikEquipment.touched.name &&
                    formikEquipment.errors.name ? (
                      <div className="text-danger">
                        {formikEquipment.errors.name}
                      </div>
                    ) : null}
                  </FloatingLabel>
                </div>
                <div className="mt-4">
                  <FloatingLabel controlId="floatingTextarea2" label="Note">
                    <Form.Control
                      type="textarea"
                      className="bg_sky_blue"
                      name="description"
                      placeholder="Leave a comment here"
                      style={{ height: "100px" }}
                      onChange={formikEquipment.handleChange}
                      onBlur={formikEquipment.handleBlur}
                      value={formikEquipment.values.description}
                    />
                    {formikEquipment.touched.description &&
                    formikEquipment.errors.description ? (
                      <div className="text-danger">
                        {formikEquipment.errors.description}
                      </div>
                    ) : null}
                  </FloatingLabel>
                </div>
              </Col>
            </Row>
            <div className="modal-footer m-5 ">
              <div className="modal-footer  ">
                <Button
                  type="button"
                  className="btn btn-secondary  fw_medium   ms-2 rounded-1   "
                  variant=" "
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="  btn_primary  fw_medium   ms-2 rounded-1  "
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      {/* ADD EQUIPMENTS */}
    </>
  );
};

export default Equipment;

import { LogLevel } from "@azure/msal-browser";
import {
  AZURE_CLIENT_ID,
  AZURE_DOMAIN,
  AZURE_USER_FLOW,
  AD_LOGIN_URL,
} from "./constants";

export const b2cPolicies = {
  authorityDomain: AZURE_DOMAIN + ".b2clogin.com",
  names: {
    signUpSignIn: AZURE_USER_FLOW,
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://" +
        AZURE_DOMAIN +
        ".b2clogin.com/" +
        AZURE_DOMAIN +
        ".onmicrosoft.com/" +
        AZURE_USER_FLOW,
    },
  },
};

export const msalConfig = {
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority:
      "https://" +
      AZURE_DOMAIN +
      ".b2clogin.com/" +
      AZURE_DOMAIN +
      ".onmicrosoft.com/" +
      AZURE_USER_FLOW,
    knownAuthorities: [AZURE_DOMAIN + ".b2clogin.com"],
    redirectUri: AD_LOGIN_URL,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    /* loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        }
      } */
  },
  popupWindowAttributes: {
    popupSize: {
      height: 500,
      width: 500,
    },
  },
};

export const loginRequest = {
  scopes: [
    "openid",
    "https://" + AZURE_DOMAIN + ".onmicrosoft.com/api/user_impersonation",
  ],
};

export const tokenRequest = {
  scopes: [
    "https://" + AZURE_DOMAIN + ".onmicrosoft.com/api/user_impersonation",
  ],
  forceRefresh: false,
};

import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/Assets/css/style.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { ThreeDots } from "react-loader-spinner";
import { RouterProvider } from "react-router-dom";
import route from "./routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { HubConnectionBuilder } from "@microsoft/signalr";
import { getBays, updateBayFromSignalR } from "./features/baysSlice";
import { useDispatch, useSelector } from "react-redux";
import { CLASSIC_API_URL, VENUE_GUID } from "./constants";
import tokenService from "./apiServices/token.service";
import { setUserToken } from "./features/profileSlice";
import { reloadTable, resetReloadTable } from "./features/reloadDataTableSlice";
import dataService from "./apiServices/data.service";
import ThreeDotsLoader from "./views/ThreeDotsLoader/ThreeDotsLoader";

function App() {
  const { token } = useSelector((state) => state.profile);

  const reload = useSelector((state) => state.datatableReload.reload);

  const dispatch = useDispatch();
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    if (token) {
      dataService.getSignalRToken().then((res) => {
        if (res.status == 200 && res.data.accessToken) {
          const newConnection = new HubConnectionBuilder()
            .withUrl(res.data.url, {
              headers: {
                Authorization: "Bearer " + res.data.accessToken,
              },
            })
            .withAutomaticReconnect()
            .build();
          setConnection(newConnection);
        }
      });
    } else {
      dispatch(setUserToken(tokenService.getLocalAccessToken()));
    }
  }, [token]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          console.log("Connected!");
          //toast.success('Connected!');

          connection.on("ReceiveMessage", (message) => {
            console.log("ReceiveMessage => ", message);
          });

          connection.on("broadcast", function (result) {
            console.log("broadcast => ", result);
          });
          connection.on("UpdateBayStateAsync", function (result) {
            console.log("UpdateBayStateAsync => ", result);
            //toast.success('Signal received.');
            dispatch(updateBayFromSignalR(result));
            dispatch(triggerChange());
          });
          connection.on("BayUpdate", function (result) {
             console.log("BayUpdate => ", result);
            //toast.success('Signal received.');
            dispatch(updateBayFromSignalR(result));
            dispatch(triggerChange());
          });
          connection.on("VenuePause", function (result) {
            console.log("VenuePause => ", result);
            //toast.success('Signal received.');
            dispatch(getBays());
            dispatch(triggerChange());
          });
          connection.on("VenueOpen", function (result) {
              console.log("VenueOpen => ", result);
            //toast.success('Signal received.');
            dispatch(getBays());
            dispatch(triggerChange());
          });
          connection.on("VenueClose", function (result) {
                console.log("VenueClose => ", result);
            //toast.success('Signal received.');
            dispatch(getBays());
            dispatch(triggerChange());
          });

          connection.on("IntakeChange", function (result) {
            //toast.success('Signal received.');
            console.log("IntakeChange => ", result);
            dispatch(triggerChange());
          });

          connection.on("BayFlagChange", function (result) {
            console.log("BayFlagChange => ", result);
            /*  dispatch(reloadTable());  */
            dispatch(updateBayFromSignalR(result));
            dispatch(triggerChange());
            //toast.success('Signal received.');
          });


          connection.on("InitiateTransferBay", function (result) {
            console.log("InitiateTransferBay => ", result);
            /*  dispatch(reloadTable());  */
            dispatch(updateBayFromSignalR(result));
            dispatch(triggerChange());
            //toast.success('Signal received.');
          });

          connection.on("IntakeLockChange", function (result) {
            console.log("IntakeLockChange => ", result);
            if (!reload) {
              dispatch(reloadTable());
            } else {
              dispatch(resetReloadTable());
            }
          });

          connection.on("AddBayStateRequestAsync", function (result) {
            console.log("AddBayStateRequestAsync", result);
          });
        })
        .catch((e) => {
          console.log("Connection failed: ", e);
          console.log(e.message);
        });

      connection.onclose((error) => {
        connection.start();
        console.error(`Something went wrong: ${error}`);
      });
    }
  }, [connection]);

  return (
    <div>
      <ToastContainer
        hideProgressBar
        newestOnTop
        pauseOnFocusLoss
        draggable
        position="top-center"
      />
      <Suspense fallback={<ThreeDotsLoader />}>
        <RouterProvider router={route} />
      </Suspense>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import * as Yup from "yup";
import { useFormik } from "formik";
import { VENUE_GUID } from "../../constants";
import tokenService from "../../apiServices/token.service";
import dataService from "../../apiServices/data.service";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { pauseStatus } from "../../features/venuePauseSlice";

const PauseVenue = (props) => {
    const dispatch = useDispatch();
    const { venuePauseStatus } = useSelector((state) => state.venuePause);

    const [show, setShow] = useState(props.show);
    const [btnIsDisable, setBtnIsDisable] = useState(false);

    const handleClose = () => {
        props.setFalse();
        setShow(false);
    };
    useEffect(() => {
        setShow(props.show);
    }, [props]);

  const validationSchema = Yup.object().shape(
    {
      PauseReason: Yup.string().trim().required(
        "Please enter reason for pausing venue."
      ),
      VenueGuid: Yup.string().required("Please enter VenueGuid."),
      StaffGuid: Yup.string().required("Please enter StaffGuid."),
    },
    [["Phone", "Phone"]]
  );

  const formik = useFormik({
    initialValues: {
      PauseReason: "",
      IsPaused: 1,
      StaffGuid: tokenService.getPersonGuid(),
      VenueGuid: VENUE_GUID,
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      setBtnIsDisable(true);
      dataService
        .changeVenuePause(values)
        .then((res) => {
          if (res.data.status == "success") {
            toast.success("Venue Paused.");
            dispatch(pauseStatus());
            handleClose();
            setBtnIsDisable(false);
          } else {
            toast.error(res.data.errorMessage);
            setBtnIsDisable(false);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setBtnIsDisable(false);
        })
        .finally(() => {
          setBtnIsDisable(false);
        });
    },
  });

  const unPauseVenue = () => {
    setBtnIsDisable(true);
    dataService
      .changeVenuePause({
        PauseReason: "",
        IsPaused: 0,
        StaffGuid: tokenService.getPersonGuid(),
        VenueGuid: VENUE_GUID,
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success("Venue unpaused.");
          dispatch(pauseStatus());
          handleClose();
          setBtnIsDisable(false);
        } else {
          toast.error(res.data.errorMessage);
          setBtnIsDisable(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setBtnIsDisable(false);
      })
      .finally(() => {
        setBtnIsDisable(false);
      });
  };

    return (
        <>
            <Offcanvas
                show={show}
                onHide={handleClose}
                placement="end"
                className="off_canves_two off_canves offcanvas offcanvas-end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Pause / Unpause all bays</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="vip_tabs">
                        <Tabs
                            defaultActiveKey={
                                venuePauseStatus != "undefined"
                                    ? venuePauseStatus.PauseStatus == 0
                                        ? "Pause Venue"
                                        : "Unpause Venue"
                                    : false
                            }
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab
                                eventKey="Pause Venue"
                                title="Pause Venue"
                                disabled={
                                    venuePauseStatus != "undefined"
                                        ? venuePauseStatus.PauseStatus == 0
                                            ? false
                                            : true
                                        : false
                                }
                            >
                                <Form onSubmit={formik.handleSubmit}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Reason</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Enter reason for pausing venue."
                                            name="PauseReason"
                                            onChange={formik.handleChange}
                                        />
                                        {formik.touched.PauseReason && formik.errors.PauseReason ? (
                                            <div className="custom-invalid-feedback">
                                                {formik.errors.PauseReason}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="float-end"
                                        disabled={btnIsDisable}
                                    >
                                        Pause Venue
                                    </Button>
                                </Form>
                            </Tab>
                            <Tab
                                eventKey="Unpause Venue"
                                title="Unpause Venue"
                                disabled={
                                    venuePauseStatus != "undefined"
                                        ? venuePauseStatus.PauseStatus == 1
                                            ? false
                                            : true
                                        : false
                                }
                            >
                                <div className="text-center lh-lg">
                                    <p>Venue is currently paused.</p>
                                    {/* <p><span className='text-danger'>Reason: </span> Some reason statement</p> */}
                                    <Button
                                        variant="primary"
                                        className="mt-3"
                                        onClick={unPauseVenue}
                                        disabled={btnIsDisable}
                                    >
                                        Unpause Venue
                                    </Button>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default PauseVenue;

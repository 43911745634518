import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const Footer = ({ setShow }) => {
  const { bay_states } = useSelector((state) => state.bays);

  const addCss = (data) => {
    const bayCss = {
      backgroundColor: data.FillColor ? data.FillColor : "#d5d3cd",
      color: data.TextColor ? data.TextColor : "#000",
      borderColor: data.BorderColor ? data.BorderColor : "#d5d3cd",
    };

    return bayCss;
  };

  return (
    <>
      <footer className="footer">
        <div className="sign_row">
          <ul className="bay-status-sign align-items-center">
            {Object.values(bay_states).map((v, i) => {
              return (
                <li className="d-flex gap-1" key={i}>
                  <div
                    className="circle_sign bg_orange circle_solid"
                    style={addCss(v)}
                  ></div>
                  <span>{v.BayStateName}</span>
                </li>
              );
            })}
            {/* <div className="d-flex gap-2">
              <div className="circle_sign bg_orange"></div>
              <span>In Use</span>
            </div>
            <div className="d-flex gap-2">
              <div className="circle_sign bg_green"></div>
              <span>Available</span>
            </div>
            <div className="d-flex gap-2">
              <div className="circle_sign bg_light_blue"></div>
              <span>Bussing</span>
            </div>
            <div className="d-flex gap-2">
              <div className="circle_sign bg_gray border_gary"></div>
              <span>Offline</span>
            </div>
            <div className="d-flex gap-2">
              <div className="circle_sign bg_white border_gary"></div>
              <span>Do Not Assign</span>
            </div> */}
            <li>
              <Button
                variant="primary"
                className="color-map-button"
                onClick={() => setShow(true)}
              >
                Color map
              </Button>
            </li>
          </ul>
          <div className="bay-rquest-status">
            <p>Version: {process.env.REACT_APP_VERSION}</p>
            {/* <li>
              <div className="d-flex gap-1">
                <div className="circle_sign bg_orange d_flex_center">
                  <i className="fa-solid fa-star text-white"></i>
                </div>
                <span>VIP</span>
              </div>
            </li>
            <li>
              <div className=" d-flex gap-1">
                <div className="circle_sign d_flex_center bg_brown">
                  <i className="fa-solid fa-triangle-exclamation text-white"></i>
                </div>
                <span>Ball Over Net</span>
              </div>
            </li>
            <li>
              <div className="d-flex gap-1">
                <div className="circle_sign d_flex_center bg_brown">
                  <i className="fa-solid fa-hand text-white"></i>
                </div>
                <span>Service Requested</span>
              </div>
            </li>
            <li>
              <div className=" d-flex gap-1">
                <div className="pt-2">
                  <i className="triangle"></i>
                </div>
                <span>Upcoming Fast Pass</span>
              </div>
            </li> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import { configureStore } from '@reduxjs/toolkit';
import  offCanvesReducer  from '../features/offCanvesSlice';
import  showAccountFormReducer  from '../features/showAccountFormSlice';
import reloadDataTableReducer from '../features/reloadDataTableSlice';
import bayReducer from '../features/baysSlice';
import venueReducer from '../features/venueSlice';
import profileReducer from '../features/profileSlice';
import venuePauseReducer from '../features/venuePauseSlice';
import venueDateTimeReducer from '../features/venueDateTimeSlice';
import loaderReducer from "../features/loaderSlice";

export const store = configureStore({
  reducer: {
    offcanves: offCanvesReducer ,
    accountForm: showAccountFormReducer,
    datatableReload: reloadDataTableReducer,
    bays: bayReducer,
    profile: profileReducer,
    venues: venueReducer,
    venuePause: venuePauseReducer,
    loader: loaderReducer,
    venueDateTime: venueDateTimeReducer,
  },
});

import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  Offcanvas,
  OverlayTrigger,
  Tab,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import DataTable from "../CommonComponents/DataTable/DataTable";
import ReactDOM from "react-dom";
import MainLayout from "../Layout/MainLayout";
import ViewWaitlist from "./ViewWaitlist";
import {
  COUNT_DOWN_TIMER,
  DATABLE_CLASSIC_API_URL,
  INTAKE_COUNT_DOWN_TIMER,
  VENUE_GUID,
} from "../../constants";
import { minutes_to_hhmm } from "../../commonFunctions";
import moment from "moment-timezone";
import BaysAvailable from "../../views/Intake/BaysAvailable";
import { useDispatch } from "react-redux";
import {
  reloadTable,
  resetReloadTable,
} from "../../features/reloadDataTableSlice";
import dataService from "../../apiServices/data.service";
import tokenService from "../../apiServices/token.service";
import { toast } from "react-toastify";
import {
  DefaultIntakeBayFilters,
  checkedBay,
  resetIntakeBayFilter,
} from "../../features/baysSlice";
import EditIntake from "../../views/Intake/EditIntake";
import { confirmAlert } from "react-confirm-alert";
import PhoneNumberFormat from "../CommonComponents/PhoneNumberFormat";
import CancelWaitList from "./CancelWaitList";

const WaitList = ({ handleClose, setTabTwo, tabTwo }) => {
  let { id } = useParams();
  const dispatch = useDispatch();

  const [waitListGuid, setWaitListGuid] = useState(null);
  const [waitListData, setWaitListData] = useState({});
  const [intakeName, setIntakeName] = useState(null);
  const [show1, setShow1] = useState(false);
  const [showWaitList, setShowWaitList] = useState({
    canvas: false,
    key: "",
    rowData: null,
  });

  const handleClose1 = () => {
    dataService
      .unLockBay({
        VenueGuid: VENUE_GUID,
        ResourceGuid: waitListGuid,
        ResourceName: intakeName,
        LockedBy: tokenService.getName(),
        LockExpiryTime: 0,
        ResourceType: "Intake",
      })
      .then((res) => {
        if (res.data.status != "success") {
          toast.error(res.data.errorMessage);
        } else {
          dispatch(resetReloadTable());
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(resetIntakeBayFilter());
      });

    setShow1(false);
  };
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => {
    dataService
      .unLockBay({
        VenueGuid: VENUE_GUID,
        ResourceGuid: waitListGuid,
        ResourceName: intakeName,
        LockedBy: tokenService.getName(),
        LockExpiryTime: 0,
        ResourceType: "Intake",
      })
      .then((res) => {
        if (res.data.status != "success") {
          toast.error(res.data.errorMessage);
        } else {
          dispatch(reloadTable());
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(resetIntakeBayFilter());
      });

    setShow3(false);
  };
  const handleShow3 = () => setShow3(true);

  const columns = [
    {
      data: "id",
      name: "id",
      title: "#",
      searchable: false,
      width: "50",
      orderable: false,
    },
    {
      data: "is_locked",
      name: "is_locked",
      title: "Status",
      searchable: false,
      placeholder: "Filter by status",
      width: "90",
      orderable: false,
    },
    {
      data: "in_transfer",
      name: "in_transfer",
      title: "Transfer",
      orderable: false,
      width: "150",
    },
    {
      data: "priority",
      name: "priority",
      title: "Priority",
      orderable: false,
      width: "150",
    },
    {
      data: "manual_assign_only",
      name: "manual_assign_only",
      title: "Manual Assign",
      searchable: false,
      orderable: false,
      width: "150",
    },
    {
      data: "type",
      name: "type",
      title: "Fast Pass",
      width: "150",
      searchable: false,
      width: "150",
      placeholder: "Filter by type",
      orderable: false,
    },
    {
      data: "name",
      name: "name",
      title: "Name",
      searchable: true,
      placeholder: "Filter by name",
      orderable: false,
    },
    {
      data: "phone",
      name: "phone",
      title: "Phone",
      searchable: true,
      placeholder: "Filter by phone",
      width: "200",
      orderable: false,
    },
    {
      data: "group_size",
      name: "group_size",
      title: "Group size",
      searchable: false,
      placeholder: "Filter by group size",
      width: "150",
      orderable: false,
    },
    {
      data: "venue_datetime",
      name: "venue_datetime",
      title: "Intake time",
      searchable: false,
      width: "300",
      placeholder: "Filter by Intake time",
      orderable: false,
      render: function (data, type, row, meta) {
        return moment(data).format("h:mm A");
      },
    },

    {
      data: "estimated_wait_time_range",
      name: "estimated_wait_time_range",
      title: "Estimated wait time range",
      searchable: false,
      width: "150",
      placeholder: "Filter by Estimated wait time range",
      orderable: false,
    },
    {
      data: "wait_time",
      name: "wait_time",
      title: "Actual Wait Time",
      searchable: false,
      placeholder: "Filter by waiting time",
      width: "150",
      orderable: false,
      render: function (data, type, row, meta) {
        const duration = minutes_to_hhmm(data);

        if (duration.hour > 0) {
          return duration.hour + " h " + duration.minutes + " m";
        }
        return duration.minutes + " m";
      },
    },
    {
      data: null,
      name: "reason",
      title: "Reason",
      searchable: false,
      placeholder: "Filter by waiting time",
      width: "150",
      orderable: false,
    },
    {
      data: null,
      name: "action",
      title: "Action",
      orderable: false,
      searchable: false,
      width: "100",
    },
  ];

  const transferSessionColumnDefs = [
    {
      targets: 3,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            <PhoneNumberFormat phone={rowData.phone} />
          </>,
          td
        );
      },
    },
    {
      className: "dt-head-center",
      targets: 8,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            <div className="d-flex justify-content-center">
              <Button
                variant="link"
                className="text-dark pt-0 assign-btn"
                onClick={() => {
                  if (rowData.is_locked == "0") {
                    dataService
                      .lockBay({
                        VenueGuid: VENUE_GUID,
                        ResourceGuid: rowData.intake_guid,
                        ResourceName: rowData.name,
                        LockedBy: tokenService.getName(),
                        LockExpiryTime: INTAKE_COUNT_DOWN_TIMER,
                        ResourceType: "Intake",
                      })
                      .then((res) => {
                        if (res.data.status == "success") {
                          dispatch(checkedBay(null));
                          setIntakeName(rowData.name);
                          dispatch(resetReloadTable());
                          handleShow1();
                          setWaitListGuid(rowData.intake_guid);
                          setWaitListData(rowData);
                        } else {
                          toast.error(res.data.errorMessage);
                        }
                      })
                      .catch((err) => {
                        toast.error(err.message);
                      });
                  } else {
                    toast.error(
                      `This Intake is locked by ${rowData.locked_by}.`
                    );
                  }
                }}
              >
                Assign
              </Button>
              <Button
                variant="link"
                className="text-dark pt-0 assign-btn"
                onClick={() => {
                  if (rowData.is_locked == "0") {
                    dataService
                      .lockBay({
                        VenueGuid: VENUE_GUID,
                        ResourceGuid: rowData.intake_guid,
                        ResourceName: rowData.name,
                        LockedBy: tokenService.getName(),
                        LockExpiryTime: INTAKE_COUNT_DOWN_TIMER,
                        ResourceType: "Intake",
                      })
                      .then((res) => {
                        if (res.data.status == "success") {
                          dispatch(checkedBay(null));
                          setIntakeName(rowData.name);
                          dispatch(resetReloadTable());
                          handleShow3();
                          setWaitListGuid(rowData.intake_guid);
                          setWaitListData(rowData);
                        } else {
                          toast.error(res.data.errorMessage);
                        }
                      })
                      .catch((err) => {
                        toast.error(err.message);
                      });
                  } else {
                    toast.error(
                      `This Intake is locked by ${rowData.locked_by}.`
                    );
                  }
                }}
              >
                Edit
              </Button>
              <Button
                variant="link"
                className="text-dark pt-0 assign-btn"
                onClick={() => {
                  confirmAlert({
                    message: "Do you want to cancel this guest?",
                    buttons: [
                      {
                        label: "Cancel",
                        onClick: () => {},
                      },
                      {
                        label: "Confirm",
                        onClick: () => {
                          dataService
                            .cancelFromIntake({
                              VenueGuid: VENUE_GUID,
                              IntakeGuid: rowData.intake_guid,
                            })
                            .then((res) => {
                              if (res.data.status == "success") {
                                dispatch(checkedBay(null));
                                dispatch(reloadTable());
                              } else {
                                toast.error(res.data.errorMessage);
                              }
                            })
                            .catch((err) => {
                              toast.error(err.message);
                            });
                        },
                      },
                      {
                        label: "Cancel",
                        onClick: () => {},
                      },
                    ],
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          </>,
          td
        );
      },
    },
    {
      targets: 1,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            {rowData.is_locked == "0" ? (
              <i className="fa-solid fa-lock-open"></i>
            ) : (
              <i className="fa-solid fa-lock"></i>
            )}
          </>,
          td
        );
      },
    },
  ];

  const columnDefs = [
    {
      targets: 7,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            <PhoneNumberFormat phone={rowData.phone} />
          </>,
          td
        );
      },
    },
    {
      targets: 2,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            {rowData.in_transfer ? (
              <div className="custombadge bg-brown me-2">TRAN</div>
            ) : null}
          </>,
          td
        );
      },
    },
    {
      targets: 3,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            {rowData.priority ? (
              <div className="custombadge bg-pink me-2">PRIO</div>
            ) : null}
          </>,
          td
        );
      },
    },
    {
      targets: 4,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            {rowData.manual_assign_only ? (
              <div className="custombadge bg-blue-dark  me-2">Manual</div>
            ) : null}
          </>,
          td
        );
      },
    },
    {
      targets: 5,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            {rowData.type == "FastPass" ? (
              <div className="custombadge bg-green me-2">FAST</div>
            ) : (
              rowData.type
            )}
          </>,
          td
        );
      },
    },

    {
      targets: 12,
      className: "dt-head-center",
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            {rowData.reason_for_transfer ? (
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id={`tooltip-$`}>
                    <p className="mb-0"> {rowData.reason_for_transfer}</p>
                  </Tooltip>
                }
              >
                <div className="d-flex">
                  <div className="text-wraps ">
                    {" "}
                    {rowData.reason_for_transfer}
                  </div>
                  <span>
                    <i className="fa-regular  fa-note-sticky"></i>
                  </span>
                </div>
              </OverlayTrigger>
            ) : (
              <div className="d-flex justify-content-center">-</div>
            )}
          </>,
          td
        );
      },
    },
    {
      className: "dt-head-center",
      targets: 13,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            <div className="d-flex justify-content-center">
              <Button
                variant="link"
                className="text-dark pt-0 assign-btn"
                onClick={() => {
                  if (rowData.is_locked == "0") {
                    dataService
                      .lockBay({
                        VenueGuid: VENUE_GUID,
                        ResourceGuid: rowData.intake_guid,
                        ResourceName: rowData.name,
                        LockedBy: tokenService.getName(),
                        LockExpiryTime: INTAKE_COUNT_DOWN_TIMER,
                        ResourceType: "Intake",
                      })
                      .then((res) => {
                        if (res.data.status == "success") {
                          dispatch(DefaultIntakeBayFilters(rowData));
                          dispatch(checkedBay(null));
                          setIntakeName(rowData.name);
                          dispatch(resetReloadTable());
                          handleShow1();
                          setWaitListGuid(rowData.intake_guid);
                          setWaitListData(rowData);
                        } else {
                          toast.error(res.data.errorMessage);
                        }
                      })
                      .catch((err) => {
                        toast.error(err.message);
                      });
                  } else {
                    toast.error(
                      `This Intake is locked by ${rowData.locked_by}.`
                    );
                  }
                }}
              >
                Assign
              </Button>
              <Button
                variant="link"
                className="text-dark pt-0 assign-btn"
                onClick={() => {
                  if (rowData.is_locked == "0") {
                    dataService
                      .lockBay({
                        VenueGuid: VENUE_GUID,
                        ResourceGuid: rowData.intake_guid,
                        ResourceName: rowData.name,
                        LockedBy: tokenService.getName(),
                        LockExpiryTime: INTAKE_COUNT_DOWN_TIMER,
                        ResourceType: "Intake",
                      })
                      .then((res) => {
                        if (res.data.status == "success") {
                          dispatch(checkedBay(null));
                          setIntakeName(rowData.name);
                          dispatch(resetReloadTable());
                          handleShow3();
                          setWaitListGuid(rowData.intake_guid);
                          setWaitListData(rowData);
                        } else {
                          toast.error(res.data.errorMessage);
                        }
                      })
                      .catch((err) => {
                        toast.error(err.message);
                      });
                  } else {
                    toast.error(
                      `This Intake is locked by ${rowData.locked_by}.`
                    );
                  }
                }}
              >
                Edit
              </Button>

              <Button
                variant="link"
                className="text-dark pt-0 assign-btn"
                onClick={() =>
                  setShowWaitList({
                    ...showWaitList,
                    canvas: true,
                    key: "waitlist",
                    rowData: rowData,
                  })
                }
              >
                Cancel
              </Button>
            </div>
          </>,
          td
        );
      },
    },
    {
      targets: 1,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            {rowData.is_locked == "1" ? (
              <i className="fa-solid fa-lock text-danger"></i>
            ) : null}
          </>,
          td
        );
      },
    },
  ];

  const assignColumnDef = [
    {
      targets: 7,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            {rowData.remaining_time_for_check_in ? (
              rowData.remaining_time_for_check_in <= 0 ? (
                <span className="text-danger">
                  {rowData.remaining_time_for_check_in} m
                </span>
              ) : (
                <span className="text-secondary">
                  {rowData.remaining_time_for_check_in} m
                </span>
              )
            ) : (
              "-"
            )}
          </>,
          td
        );
      },
    },
    {
      targets: 2,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            <PhoneNumberFormat phone={rowData.phone} />
          </>,
          td
        );
      },
    },
    {
      className: "dt-head-center",
      targets: 8,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            {/*  <div className="d-flex justify-content-center">
              <Button
                variant="link"
                className="text-dark pt-0 assign-btn"
                onClick={() => {
                  confirmAlert({
                    message: "Do you want to cancel this guest?",
                    buttons: [
                      {
                        label: "Cancel",
                        onClick: () => {},
                      },
                      {
                        label: "Confirm",
                        onClick: () => {
                          dataService
                            .cancelAssignment({
                              VenueGuid: VENUE_GUID,
                              BayGuid: rowData.bay_guid,
                              IntakeGuid: rowData.intake_guid,
                            })
                            .then((res) => {
                              if (res.data.status == "success") {
                                dispatch(checkedBay(null));
                                dispatch(reloadTable());
                              } else {
                                toast.error(res.data.errorMessage);
                              }
                            })
                            .catch((err) => {
                              toast.error(err.message);
                            });
                        },
                      },
                    ],
                  });
                }}
              >
                Cancel
              </Button>
            </div> */}

            <Button
              variant="link"
              className="text-dark pt-0 assign-btn"
              onClick={() =>
                setShowWaitList({
                  ...showWaitList,
                  canvas: true,
                  key: "assigned",
                  rowData: rowData,
                })
              }
            >
              Cancel
            </Button>
          </>,
          td
        );
      },
    },
  ];
  const cancellationColumnDefs = [
    {
      targets: 3,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            <PhoneNumberFormat phone={rowData.phone} />
          </>,
          td
        );
      },
    },
    {
      targets: 6,
      className: "dt-head-center",
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            {rowData.reason_for_cancellation ? (
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id={`tooltip-$`}>
                    <p className="mb-0"> {rowData.reason_for_cancellation}</p>
                  </Tooltip>
                }
              >
                <div className="d-flex">
                  <div className="text-wraps ">
                    {" "}
                    {rowData.reason_for_cancellation}
                  </div>
                  <span>
                    <i className="fa-regular  fa-note-sticky"></i>
                  </span>
                </div>
              </OverlayTrigger>
            ) : (
              <div className="d-flex justify-content-center">-</div>
            )}
          </>,
          td
        );
      },
    },
    {
      className: "dt-head-center",
      targets: 7,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            <div className="d-flex justify-content-center">
              <Button
                variant="link"
                className="text-dark pt-0 assign-btn"
                onClick={() => {
                  if (rowData.is_locked == "0") {
                    dataService
                      .lockBay({
                        VenueGuid: VENUE_GUID,
                        ResourceGuid: rowData.intake_guid,
                        ResourceName: rowData.name,
                        LockedBy: tokenService.getName(),
                        LockExpiryTime: INTAKE_COUNT_DOWN_TIMER,
                        ResourceType: "Intake",
                      })
                      .then((res) => {
                        if (res.data.status == "success") {
                          dispatch(checkedBay(null));
                          setIntakeName(rowData.name);
                          dispatch(resetReloadTable());
                          handleShow3();
                          setWaitListGuid(rowData.intake_guid);
                          setWaitListData(rowData);
                        } else {
                          toast.error(res.data.errorMessage);
                        }
                      })
                      .catch((err) => {
                        toast.error(err.message);
                      });
                  } else {
                    toast.error(
                      `This Intake is locked by ${rowData.locked_by}.`
                    );
                  }
                }}
              >
                Edit
              </Button>
              <Button
                variant="link"
                className="text-dark pt-0 assign-btn"
                onClick={() => {
                  confirmAlert({
                    message: "Do you want to add guest to waitlist?",
                    buttons: [
                      {
                        label: "Cancel",
                        onClick: () => {},
                      },
                      {
                        label: "Confirm",
                        onClick: () => {
                          dataService
                            .revertCancellation({
                              VenueGuid: VENUE_GUID,
                              IntakeGuid: rowData.intake_guid,
                            })
                            .then((res) => {
                              if (res.data.status == "success") {
                              } else {
                                toast.error(res.data.errorMessage);
                              }
                            })
                            .catch((err) => {
                              toast.error(err.message);
                            })
                            .finally(() => {
                              dispatch(resetReloadTable());
                            });
                        },
                      },
                    ],
                  });
                }}
              >
                Add to Waitlist
              </Button>
            </div>
          </>,
          td
        );
      },
    },
    {
      targets: 1,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            {rowData.is_locked == "1" ? (
              <i className="fa-solid fa-lock text-danger"></i>
            ) : null}
          </>,
          td
        );
      },
    },
  ];

  const columns1 = [
    { data: "id", name: "id", title: "#", searchable: false, width: "50" },
    {
      data: "name",
      name: "name",
      title: "Name",
      searchable: true,
      placeholder: "Filter by name",
    },
    {
      data: "phone",
      name: "phone",
      title: "Phone",
      searchable: true,
      placeholder: "Filter by phone",
      width: "150",
    },
    {
      data: "group_size",
      name: "group_size",
      title: "Group size",
      width: "150",
    },
    {
      data: "bay_number",
      name: "bay_number",
      title: "Bay number",
      width: "90",
    },
    {
      data: "bay_assigned_on",
      name: "bay_assigned_on",
      title: "Bay assigned on",
      width: "140",
    },
    {
      data: "count_down_to_checkin",
      name: "count_down_to_checkin",
      title: "Count down to check-in",
      width: "140",
    },
    {
      data: "guest_checked_in",
      name: "guest_checked_in",
      title: "Guest checked in",
      width: "140",
    },
    {
      data: "bay_assigned_on",
      name: "bay_assigned_on",
      title: "Guest checked tn at",
      width: "140",
    },
    {
      data: null,
      name: "action",
      title: "Action",
      orderable: false,
      searchable: false,
      width: "150",
    },
    {
      data: "venue_datetime",
      name: "venue_datetime",
      title: "Intake time",
      searchable: false,
      width: "150",
      placeholder: "Filter by Intake Time",
      render: function (data, type, row, meta) {
        return moment(data).format("h:mm A");
      },
    },
    {
      data: "wait_time",
      name: "wait_time",
      title: "Waiting Time",
      searchable: false,
      placeholder: "Filter by waiting time",
      width: "150",
      render: function (data, type, row, meta) {
        const duration = minutes_to_hhmm(data);

        if (duration.hour > 0) {
          return duration.hour + " h " + duration.minutes + " m";
        }
        return duration.minutes + " m";
      },
    },
  ];

  const inUseColumnDef = [
    {
      targets: 2,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            <PhoneNumberFormat phone={rowData.phone} />
          </>,
          td
        );
      },
    } /* ,
    {
      className: "dt-head-center",
      targets: 10,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            <div className="d-flex">
              <Button variant="link"
                className="text-dark pt-0 assign-btn" onClick={() => {

                  confirmAlert({
                    message: "Do you want to cancel this guest?",
                    buttons: [
                      
                      {
                        label: "Cancel",
                        onClick: () => {

                        },
                      },
                      {
                        label: "Confirm",
                        onClick: () => {
                          dataService
                            .cancelFromIntake({
                              VenueGuid: VENUE_GUID,
                              IntakeGuid: rowData.intake_guid,
                            })
                            .then((res) => {
                              if (res.data.status == "success") {
                                dispatch(checkedBay(null))
                                dispatch(reloadTable());
                              } else {
                                toast.error(res.data.errorMessage);
                              }
                            })
                            .catch((err) => {
                              toast.error(err.message);
                            });
                        },
                      },
                    ],
                  });


                }}>
                Cancel
              </Button>
            </div>
          </>,
          td
        );
      },
    } */,
    {
      targets: 6,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            {rowData.session_started ? (
              <i className="fa-solid fa-circle-check  text-danger"></i>
            ) : (
              <i className="fa-solid fa-circle-check text-secondary"></i>
            )}
          </>,
          td
        );
      },
    },
  ];

  const columnDefs1 = [
    {
      targets: 2,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            <PhoneNumberFormat phone={rowData.phone} />
          </>,
          td
        );
      },
    },
  ];

  const inUseColumn = [
    { data: "id", name: "id", title: "#", searchable: false, width: "50" },
    {
      data: "name",
      name: "name",
      title: "Name",
      searchable: true,
      placeholder: "Filter by name",
    },
    {
      data: "phone",
      name: "phone",
      title: "Phone",
      searchable: true,
      placeholder: "Filter by phone",
      width: "150",
    },
    {
      data: "group_size",
      name: "group_size",
      title: "Group size",
      width: "150",
    },
    {
      data: "bay_number",
      name: "bay_number",
      title: "Bay number",
      width: "90",
    },
    {
      data: "session_start_time_venue_datetime",
      name: "session_start_time_venue_datetime",
      title: "Session time",
      width: "140",
      render: function (data, type, row, meta) {
        return data ? moment(data).format("h:mm A") : " - ";
      },
    },
    {
      data: "session_started",
      name: "session_started",
      title: "Session started",
      width: "140",
      render: function (data, type, row, meta) {
        return data ? "Yes" : "No";
      },
    },
    {
      data: "estimated_session_end_time",
      name: "estimated_session_end_time",
      title: "Estimated session end time",
      width: "150",
      render: function (data, type, row, meta) {
        return data ? moment(data).format("h:mm A") : " - ";
      },
    },
    {
      data: "total_time_purchd_in_min",
      name: "total_time_purchd_in_min",
      title: "Session time purchased",
      width: "150",
      render: function (data, type, row, meta) {
        if (!data) {
          return " - ";
        }
        const duration = minutes_to_hhmm(data);

        if (duration.hour > 0) {
          return duration.hour + " h " + duration.minutes + " m";
        }
        return duration.minutes + " m";
      },
    },
    {
      data: "time_remaining",
      name: "time_remaining",
      title: "Time remaining",
      width: "150",
      render: function (data, type, row, meta) {
        if (!data) {
          return " - ";
        }
        const duration = minutes_to_hhmm(data);
        if (duration.hour > 0) {
          return duration.hour + " h " + duration.minutes + " m";
        }
        return duration.minutes + " m";
      },
    },
    /* {
      data: null,
      name: "action",
      title: "Action",
      orderable: false,
      searchable: false,
      width: "100",
    }, */
    // {
    //   data: "guest_checked_in",
    //   name: "guest_checked_in",
    //   title: "Guest Checked In",
    //   width: "140",
    // },
    // {
    //   data: "bay_assigned_on",
    //   name: "bay_assigned_on",
    //   title: "Guest Checked In At",
    //   width: "140",
    // },
    // {
    //   data: null,
    //   name: "action",
    //   title: "Action",
    //   orderable: false,
    //   searchable: false,
    //   width: "150",
    // },
  ];
  const assignColumn = [
    { data: "id", name: "id", title: "#", searchable: false, width: "50" },
    {
      data: "name",
      name: "name",
      title: "Name",
      searchable: true,
      placeholder: "Filter by name",
      width: "200",
    },
    {
      data: "phone",
      name: "phone",
      title: "Phone",
      searchable: true,
      placeholder: "Filter by phone",
      width: "100",
    },
    {
      data: "group_size",
      name: "group_size",
      title: "Group size",
      width: "100",
    },
    {
      data: "bay_number",
      name: "bay_number",
      title: "Bay number",
      width: "100",
    },
    {
      data: "pin",
      name: "pin",
      title: "Pin",
      width: "90",
    },
    {
      data: "bay_assigned_on",
      name: "bay_assigned_on",
      title: "Bay assigned on",
      width: "140",
      render: function (data, type, row, meta) {
        return moment(data).format("h:mm A");
      },
    },
    {
      data: "remaining_time_for_check_in",
      name: "remaining_time_for_check_in",
      title: "Count down to check-in",
      width: "140",
    },
    {
      data: null,
      name: "action",
      title: "Action",
      orderable: false,
      searchable: false,
      width: "150",
    },
    // {
    //   data: "venue_datetime",
    //   name: "venue_datetime",
    //   title: "Intake Time",
    //   searchable: false,
    //   width: "150",
    //   placeholder: "Filter by Intake Time",
    //   render: function (data, type, row, meta) {
    //     return moment(data).format("h:mm A");
    //   },
    // },
    // {
    //   data: "wait_time",
    //   name: "wait_time",
    //   title: "Waiting Time",
    //   searchable: false,
    //   placeholder: "Filter by Waiting Time",
    //   width: "150",
    //   render: function (data, type, row, meta) {
    //     const duration = minutes_to_hhmm(data);

    //     if (duration.hour > 0) {
    //       return duration.hour + " h ";
    //     }
    //     return duration.minutes + " m";
    //   },
    // },
  ];
  const cancellationColumn = [
    { data: "id", name: "id", title: "#", searchable: false, width: "50" },
    {
      data: "is_locked",
      name: "is_locked",
      title: "Status",
      width: "90",
    },
    {
      data: "name",
      name: "name",
      title: "Name",
      searchable: true,
      placeholder: "Filter by name",
    },
    {
      data: "phone",
      name: "phone",
      title: "Phone",
      searchable: true,
      placeholder: "Filter by phone",
      width: "200",
    },
    {
      data: "group_size",
      name: "group_size",
      title: "Player count",
      width: "150",
    },
    // {
    //   data: "venue_datetime",
    //   name: "venue_datetime",
    //   title: "Intake Time",
    //   searchable: false,
    //   width: "150",
    //   placeholder: "Filter by Intake Time",
    //   render: function (data, type, row, meta) {
    //     return moment(data).format(
    //       "MMMM Do YYYY, h:mm:ss a"
    //     );
    //   },
    // },
    {
      data: "guest_cancelled_on_venue_datetime",
      name: "guest_cancelled_on_venue_datetime",
      title: "Guest cancelled at",
      width: "200",
      render: function (data, type, row, meta) {
        return moment(data).format("h:mm A");
      },
    },
    {
      data: null,
      name: "reason",
      title: "Reason",
      orderable: false,
      searchable: false,
      width: "150",
    },
    {
      data: null,
      name: "action",
      title: "Action",
      orderable: false,
      searchable: false,
      width: "150",
    },
  ];
  const completedSessionColumn = [
    { data: "id", name: "id", title: "#", searchable: false, width: "50" },
    {
      data: "name",
      name: "name",
      title: "Name",
      searchable: true,
      placeholder: "Filter by name",
    },
    {
      data: "phone",
      name: "phone",
      title: "Phone",
      searchable: true,
      placeholder: "Filter by phone",
      width: "150",
    },
    {
      data: "group_size",
      name: "group_size",
      title: "Player count",
      width: "150",
    },
    {
      data: "bay_number",
      name: "bay_number",
      title: "Bay number",
      width: "120",
    },
    {
      data: "session_start_time_venue_datetime",
      name: "session_start_time_venue_datetime",
      title: "Session start time",
      searchable: false,
      width: "150",
      placeholder: "Filter by session start time",
      render: function (data, type, row, meta) {
        return moment(data).format("h:mm A");
      },
    },
    {
      data: "session_end_time_venue_datetime",
      name: "session_end_time_venue_datetime",
      title: "Session end time",
      searchable: false,
      width: "150",
      placeholder: "Filter by Session End Time",
      render: function (data, type, row, meta) {
        return moment(data).format("h:mm A");
      },
    },
    {
      data: "session_duration",
      name: "session_duration",
      title: "Session duration",
      searchable: false,
      width: "170",
      placeholder: "Filter by session duration",
      render: function (data, type, row, meta) {
        const duration = minutes_to_hhmm(data);

        if (duration.hour > 0) {
          return duration.hour + " h " + duration.minutes + " m";
        }
        return duration.minutes + " m";
      },
    },
  ];
  const transferBayColumn = [
    { data: "id", name: "id", title: "#", searchable: false, width: "50" },
    {
      data: "is_locked",
      name: "is_locked",
      title: "Status",
      width: "90",
    },
    {
      data: "name",
      name: "name",
      title: "Name",
      searchable: true,
      placeholder: "Filter by name",
    },
    {
      data: "phone",
      name: "phone",
      title: "Phone",
      searchable: true,
      placeholder: "Filter by phone",
      width: "200",
    },
    {
      data: "group_size",
      name: "group_size",
      title: "Group size",
      searchable: true,
      placeholder: "Filter by Group size",
      width: "150",
    },
    {
      data: "remaining_game_time",
      name: "remaining_game_time",
      title: "Time remaining",
      searchable: false,
      width: "150",
      placeholder: "Filter by Time remaining",
      render: function (data, type, row, meta) {
        const duration = minutes_to_hhmm(data);

        if (duration.hour > 0) {
          return duration.hour + " h " + duration.minutes + " m";
        }
        return duration.minutes + " m";
      },
    },
    {
      data: "in_transfer_on",
      name: "in_transfer_on",
      title: "Transferred at",
      searchable: false,
      width: "150",
      placeholder: "Filter by Transferred at",
      render: function (data, type, row, meta) {
        return moment(data).format("h:mm A");
      },
    },
    {
      data: "in_transfer_wait_time",
      name: "in_transfer_wait_time",
      title: "Transfer waiting time",
      searchable: false,
      width: "150",
      placeholder: "Filter by Transfer waiting time",
      render: function (data, type, row, meta) {
        const duration = minutes_to_hhmm(data);

        if (duration.hour > 0) {
          return duration.hour + " h " + duration.minutes + " m";
        }
        return duration.minutes + " m";
      },
    },
    {
      data: null,
      name: "action",
      title: "Action",
      orderable: false,
      searchable: false,
      width: "150",
    },
  ];

  const waitListFastPassUrlObj = {
    url:
      DATABLE_CLASSIC_API_URL +
      "api/get-wait-list?tab=1&venue_guid=" +
      VENUE_GUID,
    type: "GET",
  };

  const waitListWalkInUrlObj = {
    url:
      DATABLE_CLASSIC_API_URL +
      "api/get-wait-list?tab=todays-waitlist&venue_guid=" +
      VENUE_GUID,
    type: "GET",
  };

  const waitListAssignedUrlObj = {
    url:
      DATABLE_CLASSIC_API_URL +
      "api/get-wait-list?tab=bay-assignments&venue_guid=" +
      VENUE_GUID,
    type: "GET",
  };

  const waitListInUseUrlObj = {
    url:
      DATABLE_CLASSIC_API_URL +
      "api/get-wait-list?tab=in-use-now&venue_guid=" +
      VENUE_GUID,
    type: "GET",
  };

  const waitListCombineUrlObj = {
    url:
      DATABLE_CLASSIC_API_URL +
      "api/get-wait-list?tab=5&venue_guid=" +
      VENUE_GUID,
    type: "GET",
  };

  const waitListPriorityUrlObj = {
    url:
      DATABLE_CLASSIC_API_URL +
      "api/get-wait-list?tab=6&venue_guid=" +
      VENUE_GUID,
    type: "GET",
  };

  const waitListCancellationUrlObj = {
    url:
      DATABLE_CLASSIC_API_URL +
      "api/get-wait-list?tab=todays-cancellations&venue_guid=" +
      VENUE_GUID,
    type: "GET",
  };

  const waitListCompletedUrlObj = {
    url:
      DATABLE_CLASSIC_API_URL +
      "api/get-wait-list?tab=completed-sessions&venue_guid=" +
      VENUE_GUID,
    type: "GET",
  };

  const waitListTransferUrlObj = {
    url:
      DATABLE_CLASSIC_API_URL +
      "api/get-wait-list?tab=transfer-bay&venue_guid=" +
      VENUE_GUID,
    type: "GET",
  };

  const [reason, setReason] = useState("");

  const handleCancelFun = () => {
    if (showWaitList.key == "waitlist") {
      dataService
        .cancelFromIntake({
          VenueGuid: VENUE_GUID,
          IntakeGuid: showWaitList.rowData.intake_guid,
          ReasonForCancellation: reason,
        })
        .then((res) => {
          if (res.data.status == "success") {
            dispatch(checkedBay(null));
            dispatch(reloadTable());
          } else {
            toast.error(res.data.errorMessage);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
      setReason("");
      setShowWaitList({ ...showWaitList, canvas: false, rowData: null });
    } else if (showWaitList.key == "assigned") {
      dataService
        .cancelAssignment({
          VenueGuid: VENUE_GUID,
          BayGuid: showWaitList.rowData.bay_guid,
          IntakeGuid: showWaitList.rowData.intake_guid,
          ReasonForCancellation: reason,
        })
        .then((res) => {
          if (res.data.status == "success") {
            dispatch(checkedBay(null));
            dispatch(reloadTable());
          } else {
            toast.error(res.data.errorMessage);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
      setReason("");
      setShowWaitList({ ...showWaitList, canvas: false, rowData: null });
    }
  };

  const handleCancelCanvas = () => {
    setShowWaitList(false);
    setReason("");
  };
  const waitListOffcanvasContent = (
    <>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Reason</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          onChange={(e) => setReason(e.target.value)}
        />
      </Form.Group>
      <div className="d-flex justify-content-end gap-2">
        <Button onClick={() => handleCancelCanvas()} variant="secondary">
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleCancelFun}
          disabled={reason.length < 6}
        >
          Confirm
        </Button>
      </div>
    </>
  );
  useEffect(() => {
    console.log(showWaitList);
  }, [showWaitList]);
  return (
    <>
      {/* WAITLIST OFFCANVAS */}
      {/* available */}
      <Offcanvas
        show={show1}
        onHide={handleClose1}
        placement="end"
        className="bay  "
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5>Edit Guest Info</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <BaysAvailable
            handleClose1={handleClose1}
            waitListGuid={waitListGuid}
            intakeName={intakeName}
            waitListData={waitListData}
          />
        </Offcanvas.Body>
      </Offcanvas>
      {/* available */}
      {/* WAITLIST OFFCANVAS */}

      {/* WAITLIST OFFCANVAS */}
      {/* view */}
      <Offcanvas
        show={show2}
        onHide={handleClose2}
        placement="end"
        className="waitlist-bay  "
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5>Guest Info</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ViewWaitlist />
        </Offcanvas.Body>
      </Offcanvas>
      {/* view */}
      {/* WAITLIST OFFCANVAS */}
      {/* view offcanvas */}
      <Offcanvas
        show={show3}
        onHide={handleClose3}
        placement="end"
        className="off_canves off_canves_two offcanvas offcanvas-end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5>Edit Guest</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <EditIntake
            handleClose3={handleClose3}
            waitListGuid={waitListGuid}
            intakeName={intakeName}
            waitListData={waitListData}
          />
        </Offcanvas.Body>
      </Offcanvas>
      {/* view offcanvas */}

      <MainLayout>
        <Tabs
          defaultActiveKey={`${id ? id : "waitlist"}`}
          id="uncontrolled-tab-example"
          className="mb-3"
          mountOnEnter={true}
          unmountOnExit={true}
        >
          {/* <Tab eventKey="transfer-bay" title="Transfers">
            <DataTable
              id="waitlist-transfer-bays"
              url={waitListTransferUrlObj}
              columns={transferBayColumn}
              columnDefs={transferSessionColumnDefs}
            />
          </Tab> */}
          <Tab eventKey="waitlist" title="Waitlist">
            <div className="table-responsive  ">
              <DataTable
                id="todays-waitlist"
                url={waitListWalkInUrlObj}
                columns={columns}
                columnDefs={columnDefs}
              />
            </div>
          </Tab>
          {/* <Tab eventKey="priority-waitlist" title="Priority">
            <DataTable
              id="waitlist-priority"
              url={waitListPriorityUrlObj}
              columns={columns}
              columnDefs={columnDefs}
            />
          </Tab>
          <Tab eventKey="reservation" title="Fast Pass">
            <DataTable
              id="waitlist-fast-pass"
              url={waitListFastPassUrlObj}
              columns={columns}
              columnDefs={columnDefs}
            />
          </Tab>
          <Tab eventKey="walk-in" title="Walk-in">
            <div className="table-responsive  ">
              <DataTable
                id="waitlist-walk-in"
                url={waitListWalkInUrlObj}
                columns={columns}
                columnDefs={columnDefs}
              />
            </div>
          </Tab> */}
          <Tab eventKey="bay-assigned" title="Assigned">
            <div className="table-responsive  ">
              <DataTable
                id="bay-assignments"
                url={waitListAssignedUrlObj}
                columns={assignColumn}
                columnDefs={assignColumnDef}
              />
            </div>
          </Tab>
          <Tab eventKey="in-use" title="In Use">
            <DataTable
              id="in-use-now"
              url={waitListInUseUrlObj}
              columns={inUseColumn}
              columnDefs={inUseColumnDef}
            />
          </Tab>
          <Tab eventKey="completed-sessions" title="Completed">
            <DataTable
              id="waitlist-completed-session"
              url={waitListCompletedUrlObj}
              columns={completedSessionColumn}
              columnDefs={columnDefs1}
            />
          </Tab>
          <Tab eventKey="cancellations" title="Cancellations">
            <DataTable
              id="waitlist-cancellations"
              url={waitListCancellationUrlObj}
              columns={cancellationColumn}
              columnDefs={cancellationColumnDefs}
            />
          </Tab>
        </Tabs>
        <CancelWaitList
          setShowWaitList={setShowWaitList}
          showWaitList={showWaitList}
          content={waitListOffcanvasContent}
        />
      </MainLayout>
    </>
  );
};

export default WaitList;

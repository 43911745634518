import axios from "axios";
import TokenService from "./token.service";
import authService from "./auth.service";
import { API_URL, CLASSIC_API_URL, LOGOUT_VARNAME, appInfo } from "../constants";
import { checkExpTime, checkTokenExpiration } from "../commonFunctions";
import { toast } from "react-toastify";
import { msalInstance } from "..";

const instance = axios.create({
  baseURL: CLASSIC_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    config.headers["VenueGUID"] = appInfo.VenueGUID;
    config.headers["AppName"] = appInfo.AppName;
    config.headers["AppVersion"] = appInfo.AppVersion;
    config.headers["AppGUID"] = appInfo.AppGUID;

    const username = TokenService.getUserName();
    const token = TokenService.getLocalAccessToken();
    const expireTokenTime =  TokenService.getExpTime();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      //config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    if (username) {
      config.headers["userEmail"] = username;
    }
    return config;
    /* if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      //config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    if (username) {
      config.headers["userEmail"] = username;
    }
    return config; */


    if (!checkTokenExpiration(expireTokenTime)) {
     /*  msalInstance.logoutRedirect({
        postLogoutRedirectUri: "/login",
        account: msalInstance.getActiveAccount(),
        onRedirectNavigate: () => {
          localStorage.setItem(LOGOUT_VARNAME, Date.now().toString());
          authService.logout();
          window.location.replace("/login")
          return true;
        },
      }); */
      console.log("Access token has expired.")
     // return Promise.reject("Access token has expired.");
     return config;
    } else {
      if (token) {
        config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      if (username) {
        config.headers["userEmail"] = username;
      }
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig) {
      if (originalConfig.url.split("/").pop() !== "login" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/login",
            account: msalInstance.getActiveAccount(),
            onRedirectNavigate: () => {
              localStorage.setItem(LOGOUT_VARNAME, Date.now().toString());
              authService.logout();
              window.location.replace("/login")
              return false;
            },
          });
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;

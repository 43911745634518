import React from 'react'

import {
  Button,
  Col,
  FormControl,
  FormLabel,
  Row,
  Table,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import DataTable from '../CommonComponents/DataTable/DataTable'
import ReactDOM from 'react-dom'

const Reservation = ({ handleClose, tabOne, setTabOne }) => {
  const columns = [
    { data: 'id', name: 'id', title: '#', searchable: false },
    { data: 'name', name: 'name', title: 'Name', searchable: true,placeholder:"Filter by name" },
    { data: 'phone', name: 'phone', title: 'Phone', searchable: true ,placeholder:"Filter by phone"},
    {
      data: 'confirmation',
      name: 'confirmation',
      title: 'Confirmation#',
      searchable: true,
      placeholder:"Filter by confirmation"
    },
    { data: 'time', name: 'time', title: 'Time#', searchable: true,placeholder:"Filter by time" },
    {
      data: null,
      name: 'action',
      title: 'Action',
      orderable: false,
      searchable: false,
    },
  ]
  const columnDefs = [
    {
      targets: 6,
      createdCell: (td, cellData, rowData, row, col) => {
        ReactDOM.render(
          <>
            <Button variant="light" onClick={() => setTabOne(2)}>
              Assign
            </Button>
            <Button variant="light" onClick={() => setTabOne(2)}>
              Assign
            </Button>
            <Button variant="light" onClick={() => setTabOne(2)}>
              Assign
            </Button>
          </>,
          td,
        )
      },
    },
  ]

  return (
    <>
      {/* tab 1 */}
      <div className={`offcanvas-body ${tabOne === 1 ? 'd-block' : 'd-none'}`}>
        <div className="d-flex justify-content-between mb-4"></div>
        <div className="table-responsive ">
          <DataTable id="reservation" columns={columns} columnDefs={columnDefs} />

          {/* <Table className="table ">
            <thead>
              <tr className="bg_sky_blue align-top">
                <th scope="col">#</th>
                <th scope="col">
                  <div>
                    <FormLabel htmlFor="" className="d-block">
                      Name
                    </FormLabel>
                    <FormControl
                      placeholder="Filter by Name"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </th>
                <th scope="col">
                  <div>
                    <FormLabel htmlFor="" className="d-block">
                      Phone
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Filter by Phone"
                      className="form-control"
                    />
                  </div>
                </th>
                <th scope="col">
                  <div>
                    <FormLabel htmlFor="" className="d-block">
                      Confirmation#
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Filter by Confirmation#"
                      className="form-control"
                    />
                  </div>
                </th>
                <th scope="col">
                  <div>
                    <FormLabel htmlFor="" className="d-block">
                      Time
                    </FormLabel>
                    <FormControl type="text" className="form-control" />
                  </div>
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>John Revera</td>
                <td>(123)4576</td>
                <td>201,202</td>
                <td>6:00PM</td>
                <td>
                  <Button
                    type="button"
                    variant="light"
                    onClick={() => setTabOne(2)}
                  >
                    Assign
                  </Button>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>John Revera</td>
                <td>(123)4576</td>
                <td>201,202</td>
                <td>6:00PM</td>
                <td>
                  <Button
                    type="button"
                    variant="light"
                    onClick={() => setTabOne(2)}
                  >
                    Assign
                  </Button>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>John Revera</td>
                <td>(123)4576</td>
                <td>201,202</td>
                <td>6:00PM</td>
                <td>
                  <Button
                    type="button"
                    variant="light"
                    onClick={() => setTabOne(2)}
                  >
                    Assign
                  </Button>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>John Revera</td>
                <td>(123)4576</td>
                <td>201,202</td>
                <td>6:00PM</td>
                <td>
                  <Button
                    type="button"
                    variant="light"
                    onClick={() => setTabOne(2)}
                  >
                    Assign
                  </Button>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>John Revera</td>
                <td>(123)4576</td>
                <td>201,202</td>
                <td>6:00PM</td>
                <td>
                  <Button
                    type="button"
                    variant="light"
                    onClick={() => setTabOne(2)}
                  >
                    Assign
                  </Button>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>John Revera</td>
                <td>(123)4576</td>
                <td>201,202</td>
                <td>6:00PM</td>
                <td>
                  <Button
                    type="button"
                    variant="light"
                    onClick={() => setTabOne(2)}
                  >
                    Assign
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table> */}
        </div>
      </div>

      {/* tab 2 and tab 3 */}
      <div className={`offcanvas-body step`}>
        <div className={` `}>
          <Row
            className={`row text-start ${
              tabOne === 2 || tabOne === 3 ? 'd-block' : 'd-none'
            }`}
          >
            <Col xs={12} className=" py-3 bg_sky_blue">
              <Link
                className={`text-dark ${
                  tabOne === 2 ? 'active_tab' : ''
                }  position-relative fw_medium px-4`}
              >
                Guest Info
              </Link>
              <Link
                className={`text-dark ${
                  tabOne === 3 ? 'active_tab' : ''
                } ms-2 fw_medium px-4 position-relative`}
              >
                Select Bays
              </Link>
            </Col>
            <Row className={`${tabOne === 2 ? 'd-block' : 'd-none'}`}>
              <Col xs={12} className=" mt-4 selected_bay_row">
                <h3 className="fw-bold">John Revera</h3>
                <Table cellPadding="6" className="mt-3 border_none_table">
                  <tbody>
                    <tr>
                      <th>Phone No</th>
                      <td> (123) 456-876</td>
                    </tr>
                    <tr>
                      <th>Email id</th>
                      <td> johnrever@gmail.com</td>
                    </tr>
                    <tr>
                      <th>Booking Time</th>
                      <td> 2:10 PM</td>
                    </tr>
                    <tr>
                      <th>Group Size</th>
                      <td> 8</td>
                    </tr>
                    <tr>
                      <th>Number Bay Requested</th>
                      <td> 2</td>
                    </tr>
                    <tr>
                      <th>Bay Preference</th>
                      <td> Floor 3,ADA</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col xs={12} className="col-12 text-end mt-5">
                <Button
                  variant="light"
                  className=" btn bg_gray  fw_medium "
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  className="btn-dark  ms-3 fw_medium "
                  onClick={() => setTabOne(3)}
                >
                  Next
                </Button>
              </Col>
            </Row>
            <Row className={`${tabOne === 3 ? 'd-block' : 'd-none'}`}>
              <Col xs={12} className="col-12 mt-4 px-3">
                {/* <div>
                <Button
                  variant="light"
                  className="fw_medium"
                >
                  Select Bays
                </Button>
              </div> */}
                {/* <div className="same_line mt-3"></div> */}
                {/* <!-- boxes --> */}
                <div className="mt-5 selected_bay_row ">
                  <div className="d-flex align-items-start gap-3 bay_row">
                    <div className="bay-card bay-available alert-status">
                      <div className="bay-card-box ">
                        <span>312</span>
                        <div className="bay-card-notifcation">
                          <Link title="" className="bay-check ">
                            <i className="fa-solid fa-check"></i>
                          </Link>
                        </div>
                      </div>
                      <div className="bay-card-status">Available</div>
                    </div>

                    <div className="bay-card bay-available alert-status">
                      <div className="bay-card-box ">
                        <span>210</span>
                        <div className="bay-card-notifcation">
                          <Link title="" className="bay-check ">
                            <i className="fa-solid fa-check"></i>
                          </Link>
                        </div>
                      </div>
                      <div className="bay-card-status">Available</div>
                    </div>

                    <div className="bay-card bay-available alert-status">
                      <div className="bay-card-box ">
                        <span>217</span>
                        <div className="bay-card-notifcation">
                          <Link title="" className="bay-check ">
                            <i className="fa-solid fa-check"></i>
                          </Link>
                        </div>
                      </div>
                      <div className="bay-card-status">Available</div>
                    </div>
                  </div>

                  {/* <div className="d-flex mt-5 select_bays same_box_row gap-3 flex-sm-wrap  py-4 py-sm-0">
                  <div className="bay-card bay-inuse alert-status">
                    <div className="card-status-time">
                      15:00
                    </div>
                    <div className="bay-card-box ">
                      <span>317</span>
                      <div className="bay-card-notifcation">
                        <Link
                          title=""
                          className="bay-alert"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="bay-card-status">
                      INUSE
                    </div>
                  </div>
                  <div className="bay-card bay-inuse alert-status">
                    <div className="card-status-time">
                      15:00
                    </div>
                    <div className="bay-card-box ">
                      <span>317</span>
                      <div className="bay-card-notifcation">
                        <Link
                          title=""
                          className="bay-alert"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="bay-card-status">
                      INUSE
                    </div>
                  </div>
                  <div className="bay-card bay-inuse alert-status">
                    <div className="card-status-time">
                      15:00
                    </div>
                    <div className="bay-card-box ">
                      <span>317</span>
                      <div className="bay-card-notifcation">
                        <Link
                          title=""
                          className="bay-alert"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="bay-card-status">
                      INUSE
                    </div>
                  </div>
                  <div className="bay-card bay-available alert-status">
                    <div className="card-status-time">
                      15:00
                    </div>
                    <div className="bay-card-box ">
                      <span>317</span>
                      <div className="bay-card-notifcation">
                        <Link
                          title=""
                          className="bay-alert"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="bay-card-status">
                      INUSE
                    </div>
                  </div>
                  <div className="bay-card bay-inuse alert-status">
                    <div className="card-status-time">
                      15:00
                    </div>
                    <div className="bay-card-box ">
                      <span>317</span>
                      <div className="bay-card-notifcation">
                        <Link
                          title=""
                          className="bay-alert"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="bay-card-status">
                      INUSE
                    </div>
                  </div>
                </div> */}
                </div>
              </Col>
              <Col
                xs={12}
                className="col-12 d-sm-flex  justify-content-between mt-5"
              >
                <Button
                  variant="light"
                  className="  btn fw_medium"
                  onClick={() => setTabOne(2)}
                >
                  Back
                </Button>

                <div className="mt-3 mt-sm-0">
                  <Button
                    variant="light"
                    className="  fw_medium"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>

                  <Button className="btn bg-dark   ms-2 text-white fw_medium">
                    Confirm
                  </Button>
                </div>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Reservation

import React from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  hideCanves,
  hideProfile,
  showCanves,
} from "../../features/offCanvesSlice";
import { showForm } from "../../features/showAccountFormSlice";
import ViewProfile from "./ViewProfile";

const ProfileOffcanvas = (props) => {
  const showProfile = useSelector((state) => state.offcanves.profile);
  // const show = useSelector((state) => state.offcanves.offCanvesShow);
  const showAddForm = useSelector((state) => state.accountForm.showAccountForm);

  const dispatch = useDispatch();

  const showAccountFormHandler = () => {
    dispatch(showProfile());
    dispatch(showForm());
  };
  return (
    <div>
      <Offcanvas
        show={showProfile}
        placement="end"
        className={props.showHalfPopUp ? props.showHalfPopUp : "edit "}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            {!showAddForm ? props.title : " View Profile "}
          </Offcanvas.Title>
          <div className="">
            {props.type === "view" ? (
              <>
                <Button
                  className="edit1-btn"
                  onClick={() => {
                    props.callBack("edit");
                  }}
                >
                  Edit
                </Button>
              </>
            ) : (
              ""
            )}
            <Button
              variant="none"
              className="canvas-btn border-0 fs-5"
              onClick={() => dispatch(hideProfile())}
            >
             <i className="bi bi-x-lg"></i>
            </Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!showAddForm ? props.content : <ViewProfile />}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default ProfileOffcanvas;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {  BAY_DIRECTION, COUNT_DOWN_TIMER, VENUE_GUID, bayStates } from '../constants';
import { filterObject, filterObjectByDisable, convertArrayToObject, getCountByBayStates, sortBays, sortFloorsDesc, filterObjectByDisableMulti, sortByKey, filterIntakeObjectByDisable, filterObjectWithMultiParam, orderBy } from '../commonFunctions';
import dataService from '../apiServices/data.service';
import { toast } from 'react-toastify';

const initialState = {
  loading: 'idle',
  venuePauseStatus: ""
};
export const pauseStatus = createAsyncThunk('venues/pauseStatus', async () => {
  return dataService.getVenuePauseStatus(/* {
    "params": { "keys": "bay_guid, bay_number, Floor, is_deleted" },
    "filter": { "venue_guid": VENUE_GUID }
  } */ { "VenueGuid": VENUE_GUID }).then((response) => {
    if (response.data.status == 'success') {
      return response.data;
    }
  }).catch((err) => {
    toast.error(err.message)
  })
})


export const venuePauseSlice = createSlice({
  name: 'venuePause',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    
  },

  extraReducers: (builder) => {
    builder.addCase(pauseStatus.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    })
    builder.addCase(pauseStatus.fulfilled, (state, action) => {
      if (state.loading === 'pending') {
        
        if (action.payload.status == 'success') {

          state.venuePauseStatus = action.payload.response
        }

        state.loading = 'idle'
      }
    })
    builder.addCase(pauseStatus.rejected, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.error = 'Error occured'
      }
    })


  },
});


export default venuePauseSlice.reducer


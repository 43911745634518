import React from "react";
import { ThreeDots } from "react-loader-spinner";

const ThreeDotsLoader = ({ loading }) => {
  return (
    <ThreeDots
      height="90"
      width="70"
      radius="9"
      color="var(--primary)"
      ariaLabel="three-dots-loading"
      wrapperStyle={{ justifyContent: "center" }}
      visible={true}
    />
  );
};

export default ThreeDotsLoader;

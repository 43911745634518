import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-input-2";

const ViewProfile = (props) => {
  const { profile } = useSelector((state) => state.profile) || {};

  return (
    <>
      <div className="offcanvas-content">
        <Form>
          <Row className=" ">
            <Col md="4" className=" text-center">
              <img
                src={`${
                  profile && profile.AvatarFileUrl
                    ? profile.AvatarFileUrl
                    : "img/user.jpg"
                }`}
                className="popup-img"
                alt="..."
              />
            </Col>
            <Col md="8">
              <h4 className="mb-2">
                {profile?.FirstName} {profile?.LastName}
              </h4>
              <Form.Group className="my-3" controlId="formGroupEmail">
                <Form.Label className="">Phone</Form.Label>

                <PhoneInput
                  className="phone-input-color email-disabled"
                  specialLabel={false}
                  value={profile?.Phone}
                  /*   disableCountryCode={true}
                  onlyCountries={["us"]} */
                  id="phone-input"
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label className="">Email</Form.Label>

                <div className=" email-disabled"> {profile?.Email}</div>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default ViewProfile;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  BAY_DIRECTION,
  COUNT_DOWN_TIMER,
  VENUE_GUID,
  bayStates,
} from "../constants";
import {
  filterObject,
  filterObjectByDisable,
  convertArrayToObject,
  getCountByBayStates,
  sortBays,
  sortFloorsDesc,
  filterObjectByDisableMulti,
  sortByKey,
  filterIntakeObjectByDisable,
  filterObjectWithMultiParam,
  orderBy,
  filterObjectWithMultiParamWithoutPage,
} from "../commonFunctions";
import dataService from "../apiServices/data.service";
import { toast } from "react-toastify";

const initialState = {
  bays: [],
  allBays: [],
  canonBays: [],
  puttingBays: [],
  bay_states: [],
  bay_types: [],
  handedness: [],
  floors: [],
  all_state: 0,
  bay_state_counts: {},
  putting_bay_state_counts: {},
  canon_bay_state_counts: {},
  assignable_count: 0,
  loading: "idle",
  filterApplied: false,
  filterCondition: [],
  error: null,
  intake_bays: [],
  intake_bay_floor_filter: [],
  intake_bay_type_filter: [],
  intake_bay_filter: {
    BayStateId: [],
    BayTypeId: [],
    Floor: [],
  },
  bay_filters: {
    BayStateId: [],
    BayTypeId: [],
    Floor: [],
  },
  canon_bay_filters: {
    BayStateId: [],
    BayTypeId: [],
    Floor: [],
  },
  putting_bay_filters: {
    BayStateId: [],
    BayTypeId: [],
    Floor: [],
  },
  checked_intake_bay: null,
  reservation_count: 0,
  walk_in_count: 0,
  trigger_change: 0,
  bay_timer: COUNT_DOWN_TIMER,
  currentPage: null,
};

export const getBays = createAsyncThunk("bays/getBays", async () => {
  /* const response = await axios.post(API_URL + 'bays-module/get-bays', bayPayload)


  return response.data */

  return dataService
    .getBays(
      /* {
    "params": { "keys": "bay_guid, bay_number, Floor, is_deleted" },
    "filter": { "venue_guid": VENUE_GUID }
  } */ { VenueGuid: VENUE_GUID }
    )
    .then((response) => {
      if (response.data.status == "success") {
        return response.data;
      }
    })
    .catch((err) => {
      toast.error(err.message);
    });
});

export const baySlice = createSlice({
  name: "bays",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    changeBayColor: (state, action) => {
      if (action.payload !== undefined) {
        state.data = action.payload;
      }
    },
    bayFilters: (state, action) => {
      if (action.payload !== undefined) {
       // debugger;
       state.currentPage = action.payload.page;

        if (action.payload.action == "state") {
          
          state.filterApplied = true;
          if (action.payload.type == "add") {
            if (action.payload.value == "assignable") {
              state.bay_filters = {
                ...state.bay_filters,
                BayStateId: [...state.bay_filters.BayStateId, 1],
              };
              state.bay_filters = {
                ...state.bay_filters,
                BayStateId: [...state.bay_filters.BayStateId, 5],
              };
            } else if (action.payload.value == "all") {
              if(state.currentPage == "/putting-bays")
              {
                state.putting_bay_filters = {
                  ...state.putting_bay_filters,
                  BayStateId: [],
                };
              }else if(state.currentPage == "/canon-bays"){
                state.canon_bay_filters = {
                  ...state.canon_bay_filters,
                  BayStateId: [],
                };
              } else{
                state.bay_filters = {
                  ...state.bay_filters,
                  BayStateId: [],
                };
              }
              
            } else {

              if(state.currentPage == "/putting-bays")
              {
                state.putting_bay_filters = {
                  ...state.putting_bay_filters,
                  BayStateId: [
                    ...state.putting_bay_filters.BayStateId,
                    parseInt(action.payload.value),
                  ],
                };
              }else if(state.currentPage == "/canon-bays"){
                state.canon_bay_filters = {
                  ...state.canon_bay_filters,
                  BayStateId: [
                    ...state.canon_bay_filters.BayStateId,
                    parseInt(action.payload.value),
                  ],
                };
              } else{
                state.bay_filters = {
                  ...state.bay_filters,
                  BayStateId: [
                    ...state.bay_filters.BayStateId,
                    parseInt(action.payload.value),
                  ],
                };
              }


              
            }
          }

          if (action.payload.type == "remove") {
            if (action.payload.value == "assignable") {
              state.bay_filters = {
                ...state.bay_filters,
                BayStateId: state.bay_filters.BayStateId.filter((val) => {
                  return val != 1;
                }),
              };
              state.bay_filters = {
                ...state.bay_filters,
                BayStateId: state.bay_filters.BayStateId.filter((val) => {
                  return val != 5;
                }),
              };
            } else {

              if(state.currentPage == "/putting-bays")
              {
                state.putting_bay_filters = {
                  ...state.putting_bay_filters,
                  BayStateId: state.putting_bay_filters.BayStateId.filter((val) => {
                    return val != parseInt(action.payload.value);
                  }),
                };
              }else if(state.currentPage == "/canon-bays"){
                state.canon_bay_filters = {
                  ...state.canon_bay_filters,
                  BayStateId: state.canon_bay_filters.BayStateId.filter((val) => {
                    return val != parseInt(action.payload.value);
                  }),
                };
              } else{
                state.bay_filters = {
                  ...state.bay_filters,
                  BayStateId: state.bay_filters.BayStateId.filter((val) => {
                    return val != parseInt(action.payload.value);
                  }),
                };
              }


              
            }
          }
        }

        if (action.payload.action == "type") {
          state.filterApplied = true;
          if (action.payload.type == "add") {
            if (action.payload.value == "all") {
              state.bay_filters = {
                ...state.bay_filters,
                BayTypeId: [],
              };
            } else {
              state.bay_filters = {
                ...state.bay_filters,
                BayTypeId: [
                  ...state.bay_filters.BayTypeId,
                  parseInt(action.payload.value),
                ],
              };
            }
          }

          if (action.payload.type == "remove") {
            state.bay_filters = {
              ...state.bay_filters,
              BayTypeId: state.bay_filters.BayTypeId.filter((val) => {
                return val != parseInt(action.payload.value);
              }),
            };
          }
        }

        if (action.payload.action == "Floor") {
          state.filterApplied = true;
          if (action.payload.type == "add") {
            if (action.payload.page == "all") {
              state.bay_filters = {
                ...state.bay_filters,
                Floor: [],
              };
            } else {
              state.bay_filters = {
                ...state.bay_filters,
                Floor: [
                  ...state.bay_filters.Floor,
                  parseInt(action.payload.value),
                ],
              };
            }
          }

          if (action.payload.type == "remove") {
            state.bay_filters = {
              ...state.bay_filters,
              Floor: state.bay_filters.Floor.filter((val) => {
                return val != parseInt(action.payload.value);
              }),
            };
          }
        }

        let filters = (state.currentPage == "/putting-bays") ? state.putting_bay_filters : (state.currentPage == "/canon-bays") ? state.canon_bay_filters : state.bay_filters

        
        state.allBays = convertArrayToObject(
          orderBy(
            filterObjectWithMultiParam(
              Object.values(state.allBays),
              filters,
              state.currentPage
            ),
            "BayNumber",
            BAY_DIRECTION == "rtl" ? "desc" : "asc"
          ),
          "BayGuid"
        );
      }
    },
    DefaultIntakeBayFilters:(state, action) => {
      if (action.payload !== undefined) {
        if(action.payload.pref_bay_types && JSON.parse(action.payload.pref_bay_types).length > 0)
        {
          state.intake_bay_filter = {
            ...state.intake_bay_filter,
            BayTypeId: JSON.parse(action.payload.pref_bay_types),
          };
        } 

        if(action.payload.pref_floors && JSON.parse(action.payload.pref_floors).length > 0)
        {
          state.intake_bay_filter = {
            ...state.intake_bay_filter,
            Floor: JSON.parse(action.payload.pref_floors),
          };
        } 
        state.intake_bays = convertArrayToObject(Object.values(filterObjectWithMultiParamWithoutPage(
          Object.values(state.intake_bays),
          state.intake_bay_filter,
          action.payload.pref_excluded_bays
        )),"BayGuid");
        /* state.intake_bay_filter = {
          ...state.intake_bay_filter,
          Floor: [
            ...state.intake_bay_filter.Floor,
            parseInt(action.payload.value),
          ],
        }; */
      }
    },
    IntakeBayFilters: (state, action) => {
      if (action.payload !== undefined) {
        if (action.payload.action == "Floor") {
          if (action.payload.type == "add") {
            state.intake_bay_filter = {
              ...state.intake_bay_filter,
              Floor: [
                ...state.intake_bay_filter.Floor,
                parseInt(action.payload.value),
              ],
            };
          }

          if (action.payload.type == "remove") {
            state.intake_bay_filter = {
              ...state.intake_bay_filter,
              Floor: state.intake_bay_filter.Floor.filter((val) => {
                return val != parseInt(action.payload.value);
              }),
            };
          }
        }

        if (action.payload.action == "type") {
          if (action.payload.type == "add") {
            state.intake_bay_filter = {
              ...state.intake_bay_filter,
              BayTypeId: [
                ...state.intake_bay_filter.BayTypeId,
                parseInt(action.payload.value),
              ],
            };
          }

          if (action.payload.type == "remove") {
            state.intake_bay_filter = {
              ...state.intake_bay_filter,
              BayTypeId: state.intake_bay_filter.BayTypeId.filter((val) => {
                return val != parseInt(action.payload.value);
              }),
            };
          }
        }

        state.intake_bays = filterObjectWithMultiParam(
          Object.values(state.intake_bays),
          state.intake_bay_filter,
          null,
          action.payload.excludedBay
        );
      }
    },
    filterBaysByState: (state, action) => {
      if (action.payload !== undefined) {
        if (action.payload != "all" && action.payload != "assignable") {
          state.allBays = filterObjectByDisable(
            state.allBays,
            "BayStateId",
            action.payload
          );
          state.filterApplied = true;
        } else if (action.payload == "assignable") {
          state.allBays = filterObjectByDisableMulti(
            state.allBays,
            "BayStateId",
            [1, 3]
          );
          state.filterApplied = true;
        } else {
          state.allBays = filterObjectByDisable(
            state.allBays,
            "BayStateId",
            action.payload
          );
          state.filterApplied = false;
        }
      }
    },
    filterBaysByType: (state, action) => {
      if (action.payload !== undefined) {
        if (action.payload != "all" && action.payload != "assignable") {
          state.allBays = filterObjectByDisable(
            state.allBays,
            "BayTypeId",
            action.payload
          );
          state.filterApplied = true;
        } else if (action.payload == "assignable") {
          state.allBays = filterObjectByDisableMulti(
            state.allBays,
            "BayTypeId",
            [1, 3]
          );
          state.filterApplied = true;
        } else {
          state.allBays = filterObjectByDisable(
            state.allBays,
            "BayTypeId",
            action.payload
          );
          state.filterApplied = false;
        }
      }
    },
    filterBaysByOrientation: (state, action) => {
      if (action.payload !== undefined) {
        if (action.payload != "all" && action.payload != "assignable") {
          state.allBays = filterObjectByDisable(
            state.allBays,
            "handedness_id",
            action.payload
          );
          state.filterApplied = true;
        } else if (action.payload == "assignable") {
          state.allBays = filterObjectByDisableMulti(
            state.allBays,
            "handedness_id",
            [1, 3]
          );
          state.filterApplied = true;
        } else {
          state.allBays = filterObjectByDisable(
            state.allBays,
            "handedness_id",
            action.payload
          );
          state.filterApplied = false;
        }
      }
    },
    filterBaysByFloor: (state, action) => {
      if (action.payload !== undefined) {
        if (action.payload != "all") {
          state.filterApplied = true;
          state.allBays = filterObject(state.allBays, "Floor", action.payload);
        } else {
          state.filterApplied = false;
          state.allBays = state.bays;
        }

        state.all_state = Object.values(state.allBays).length;

        state.bay_state_counts = getCountByBayStates(
          Object.values(state.allBays)
        );

        /* state.assignable_count = state.bay_state_counts[1] + state.bay_state_counts[3] */
      }
    },
    updateBayFromSignalR: (state, action) => {
      if (action.payload !== undefined) {
        const signalRPayload = JSON.parse(action.payload)[0];
        state.allBays[signalRPayload.BayGuid] = signalRPayload;
        state.intake_bays = convertArrayToObject(Object.values(filterObjectWithMultiParamWithoutPage(
          Object.values(state.allBays),
          state.intake_bay_filter,
          action.payload.pref_excluded_bays
        )),"BayGuid");


        let filters = (state.currentPage == "/putting-bays") ? state.putting_bay_filters : (state.currentPage == "/canon-bays") ? state.canon_bay_filters : state.bay_filters

        
        state.allBays = convertArrayToObject(
          orderBy(
            filterObjectWithMultiParam(
              Object.values(state.allBays),
              filters,
              state.currentPage
            ),
            "BayNumber",
            BAY_DIRECTION == "rtl" ? "desc" : "asc"
          ),
          "BayGuid"
        );


        state.bay_state_counts = getCountByBayStates(
          Object.values(state.allBays)
        );

        state.trigger_change = state.trigger_change + parseInt(1);
        /* state.assignable_count = state.bay_state_counts[1] + state.bay_state_counts[3] */
      }
    },

    triggerChange: (state, action) => {
      state.trigger_change = state.trigger_change + parseInt(1);
      /* state.assignable_count = state.bay_state_counts[1] + state.bay_state_counts[3] */
    },

    resetBayTimer: (state, action) => {
      state.bay_timer = COUNT_DOWN_TIMER;
      /* state.assignable_count = state.bay_state_counts[1] + state.bay_state_counts[3] */
    },

    resetIntakeBayFilter: (state, action) => {
      state.intake_bay_filter = {
        BayStateId: [],
        BayTypeId: [],
        Floor: [],
      }
    },

    updateIntakeBayFloorFilter: (state, action) => {
      if (action.payload !== undefined) {
        if (action.payload.type == "add") {
          state.intake_bay_floor_filter = [
            ...state.intake_bay_floor_filter,
            parseInt(action.payload.value),
          ];
          state.intake_bays = filterIntakeObjectByDisable(
            state.intake_bays,
            "Floor",
            state.intake_bay_floor_filter
          );
        }
        if (action.payload.type == "remove") {
          state.intake_bay_floor_filter = state.intake_bay_floor_filter.filter(
            function (item) {
              return item != action.payload.value;
            }
          );
          console.log(
            "updateIntakeBayFloorFilter",
            state.intake_bay_floor_filter.length,
            state.intake_bay_type_filter.length
          );
          if (
            state.intake_bay_floor_filter.length > 0 &&
            state.intake_bay_type_filter.length > 0
          ) {
            state.intake_bays = filterIntakeObjectByDisable(
              state.intake_bays,
              "Floor",
              state.intake_bay_floor_filter
            );
          } else {
            state.intake_bays = state.allBays;
          }
        }
      }
    },

    updateIntakeBayTypeFilter: (state, action) => {
      if (action.payload !== undefined) {
        if (action.payload.type == "add") {
          state.intake_bay_type_filter = [
            ...state.intake_bay_type_filter,
            parseInt(action.payload.value),
          ];
          state.intake_bays = filterIntakeObjectByDisable(
            state.intake_bays,
            "BayTypeId",
            state.intake_bay_type_filter
          );
        }
        if (action.payload.type == "remove") {
          state.intake_bay_type_filter = state.intake_bay_type_filter.filter(
            function (item) {
              return item != action.payload.value;
            }
          );
          console.log(
            "updateIntakeBayTypeFilter",
            state.intake_bay_floor_filter.length,
            state.intake_bay_type_filter.length
          );
          if (
            state.intake_bay_floor_filter.length > 0 &&
            state.intake_bay_type_filter.length > 0
          ) {
            state.intake_bays = filterIntakeObjectByDisable(
              state.intake_bays,
              "BayTypeId",
              state.intake_bay_type_filter
            );
          } else {
            state.intake_bays = state.allBays;
          }
        }
      }
    },
    checkedBay: (state, action) => {
      if (action.payload !== undefined) {
        state.checked_intake_bay = action.payload;
        /* state.assignable_count = state.bay_state_counts[1] + state.bay_state_counts[3] */
      }
    },
    unCheckedBay: (state, action) => {
      state.checked_intake_bay = null;
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
  },

  extraReducers: (builder) => {
    builder.addCase(getBays.pending, (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    });
    builder.addCase(getBays.fulfilled, (state, action) => {
      if (state.loading === "pending") {
        if (action.payload.status == "success") {
          state.bays = convertArrayToObject(
            action.payload.response.bays,
            "BayGuid"
          );
          state.floors = orderBy(convertArrayToObject(
            sortFloorsDesc(action.payload.response.floors),
            "Floor"
          ),'Floor', 'desc');

          state.bay_states = sortByKey(
            convertArrayToObject(action.payload.response.states, "BayStateId"),
            "sort_order"
          );

          state.bay_types = convertArrayToObject(
            action.payload.response.bay_types,
            "BayTypeId"
          );

          if (!state.filterApplied) {
            state.allBays = convertArrayToObject(
              orderBy(
                state.bays,
                "BayNumber",
                BAY_DIRECTION == "rtl" ? "desc" : "asc"
              ),
              "BayGuid"
            );
          }

          state.trigger_change = state.trigger_change + parseInt(1);

          state.intake_bays = convertArrayToObject(
            Object.values(state.allBays).map((item) => ({
              ...item,
              is_deleted: 0,
            })),
            "BayGuid"
          );
          
          state.intake_bays = convertArrayToObject(Object.values(filterObjectWithMultiParamWithoutPage(
            Object.values(state.intake_bays),
            state.intake_bay_filter,
            action.payload.pref_excluded_bays
          )),"BayGuid");

          state.all_state = Object.values(state.allBays).length;

          state.bay_state_counts = getCountByBayStates(
            Object.values(state.allBays)
          );

          state.reservation_count =
            action.payload.response.todaysCounts[0].TodaysFastPassCount;
          state.walk_in_count =
            action.payload.response.todaysCounts[0].TodaysWalkInCount;
        }

        state.loading = "idle";
      }
    });
    builder.addCase(getBays.rejected, (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = "Error occured";
      }
    });
  },
});

export const {
  changeBayColor,
  filterBaysByState,
  filterBaysByFloor,
  updateBayFromSignalR,
  filterBaysByType,
  filterBaysByOrientation,
  updateIntakeBayFloorFilter,
  updateIntakeBayTypeFilter,
  bayFilters,
  IntakeBayFilters,
  checkedBay,
  triggerChange,
  resetBayTimer,
  resetIntakeBayFilter,
  unCheckedBay,
  DefaultIntakeBayFilters
} = baySlice.actions;

export default baySlice.reducer;

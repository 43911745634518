import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Siderbar from "../Sidebar/Siderbar";
import Footer from "../../components/Footer/Footer";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import Signin from "../../views/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import { getBays } from "../../features/baysSlice";
import {
  APP_GUID,
  CUSTOM_DOMAIN,
  LOGOUT_COOKIE_NAME,
  LOGOUT_VARNAME,
  VENUE_GUID,
} from "../../constants";
import dataService from "../../apiServices/data.service";
import { useNavigate } from "react-router-dom";
import { updateAccessStatus } from "../../features/profileSlice";
import { toast } from "react-toastify";
import { getVenues } from "../../features/venueSlice";
import { pauseStatus } from "../../features/venuePauseSlice";
import { getVenueDateTime } from "../../features/venueDateTimeSlice";
import authService from "../../apiServices/auth.service";
import Cookies from "js-cookie";
import ColorMapCanvas from "../Footer/ColorMapCanvas";

const MainLayout = (props) => {
  const { instance } = useMsal();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { is_access, profile } = useSelector((state) => state.profile);
  const [venueData, setVenueData] = useState([]);

  useEffect(() => {
    if (profile && profile.PersonsGuid !== null && !is_access) {
      if (Object.keys(profile).length == 0) {
        navigate("/access-denied");
        dispatch(updateAccessStatus(1));
      }

      dataService
        .getAppAccess({
          VenueGuid: VENUE_GUID,
          PersonGuid:
            Object.keys(profile).length > 0 ? profile.PersonsGuid : "0000",
          AppGuid: APP_GUID,
        })
        .then(function (response) {
          if (response.data.status == "success") {
            dispatch(updateAccessStatus(1));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [profile]);

  useEffect(() => {
    Cookies.set(LOGOUT_COOKIE_NAME, "1", { path: "/", domain: CUSTOM_DOMAIN });
    const handleStorageChange = (event) => {
      if (event.key === LOGOUT_VARNAME) {
        // Perform actions when logout flag is detected
        // For example, reload all tabs
        window.location.reload(true);

        // Clear the logout flag
        localStorage.removeItem(LOGOUT_VARNAME);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    /*cookieStore.addEventListener("change", (event) => {
        if(Object.values(event.deleted.length > 0 && event.deleted[0]).length > 0  && event.deleted[0].name == LOGOUT_COOKIE_NAME)
        {
          instance.logoutRedirect({
            postLogoutRedirectUri: "/login",
            account: instance.getActiveAccount(),
            onRedirectNavigate: () => {
              // Return false to stop navigation after local logout
              localStorage.setItem(LOGOUT_VARNAME, Date.now().toString());
              authService.logout();
              return true;
            },
          });
        }
      });*/

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      /*  cookieStore.removeEventListener("change", (event) => {
      }); */
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Cookies.get(LOGOUT_COOKIE_NAME) != 1) {
        instance.logoutRedirect({
          postLogoutRedirectUri: "/login",
          account: instance.getActiveAccount(),
          onRedirectNavigate: () => {
            // Return false to stop navigation after local logout
            localStorage.setItem(LOGOUT_VARNAME, Date.now().toString());
            authService.logout();
            return true;
          },
        });
      }
      //dispatch(triggerChange())
    }, 500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(pauseStatus());
    dispatch(getVenues());
    dispatch(getVenueDateTime());
    dispatch(getBays());
  }, []);
  const [active, setActive] = useState(false);

  const handleActiveState = () => {
    setActive(!active);
  };
  return (
    <>
      <AuthenticatedTemplate>
        <div className="bg-white d-flex p-0">
          <Siderbar
            handleActiveState={handleActiveState}
            active={active}
            venueData={venueData}
          />
          <div className={`content ${active ? "open" : " "}`}>
            <Header handleActiveState={handleActiveState} />
            <div className="main-panel px-0 px-sm-4">{props.children}</div>
            <Footer setShow={setShow} />
            <ColorMapCanvas show={show} setShow={setShow} />
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Signin />
      </UnauthenticatedTemplate>
    </>
  );
};

export default MainLayout;

import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Offcanvas from "react-bootstrap/Offcanvas";
import dataService from "../../../apiServices/data.service";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Active from "./NotesTabsContent/Active";
import Archive from "./NotesTabsContent/Archive";
import { VENUE_GUID } from "../../../constants";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';

const Notes = ({ handleShow2, show2, handleClose2, bayGuid }) => {
  const [notes, setNotes] = useState([]);
  const [currentTab, setCurrentTab] = useState("active");
  const [btnIsDisable, setBtnIsDisable] = useState(false);
  const [uuid, setUuid] = useState(uuidv4());

  /* For Notes*/
  const formikNotes = useFormik({
    initialValues: {
      Notes: "",
    },
    validationSchema:  Yup.object().shape({
      Notes: Yup.string().trim().required("Please enter Note."),
    }),

    onSubmit: (values, { resetForm }) => {
      setBtnIsDisable(true);
      dataService
        .insertNotes({ ...values, VenueGuid: VENUE_GUID, BayGuid: bayGuid }, uuid)
        .then((res) => {
          if (res.data.status === "success") {
            toast.success("Note added successfully.");
            setUuid(uuidv4())

            resetForm();
            handleClose2();
            setCurrentTab("active");
            setBtnIsDisable(false);
          } else {
            setUuid(uuidv4())
            toast.error(res.data.errorMessage[0]);
            setBtnIsDisable(false);
          }
        })
        .finally(() => {
          setBtnIsDisable(false);
        });
    },
  });

  return (
    <>
      <Row className=" mb-3 mt-3">
        <Col className="pb-0">
          <h5 className="fw_medium">Notes</h5>
        </Col>
        <Col className="text-end pb-0">
          <Button
            type="submit"
            className="btn-primary fw_medium"
            onClick={() => {
              setCurrentTab("");
              handleShow2();
            }}
          >
            Add Note
          </Button>
        </Col>
      </Row>
      <div className="col">
        <Tabs
          /* defaultActiveKey={currentTab} */
          defaultActiveKey="active"
          activeKey={currentTab}
          id="uncontrolled-tab-example"
          className="mb-3"
          mountOnEnter={true}
          unmountOnExit={true}
          onSelect={(key) => setCurrentTab(key)}
        >
          <Tab eventKey="active" title="Active">
            <Active
              handleShow2={handleShow2}
              venueId={VENUE_GUID}
              toast={toast}
              bayGuid={bayGuid}
            />
          </Tab>
          <Tab eventKey="archive" title="Archive">
            <Archive
              handleShow2={handleShow2}
              venueId={VENUE_GUID}
              toast={toast}
              bayGuid={bayGuid}
            />
          </Tab>
        </Tabs>
      </div>

      {/* ADD NOTES */}
      <Offcanvas
        show={show2}
        onHide={handleClose2}
        placement="end"
        className="off_canves_two off_canves offcanvas offcanvas-end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5>Add Note</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={formikNotes.handleSubmit}>
            <Row>
              <Col>
                <span className="fw_medium">Notes</span>
                <div className="mt-2">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className=" "
                      name="Notes"
                      placeholder="Leave a note here"
                      style={{ height: "100px" }}
                      onChange={formikNotes.handleChange}
                      onBlur={formikNotes.handleBlur}
                      value={formikNotes.values.Notes}
                    />
                  </Form.Group>
                  {formikNotes.touched.Notes && formikNotes.errors.Notes ? (
                    <div className="text-danger">
                      {formikNotes.errors.Notes}
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
            <div className="modal-footer  ">
              <Button
                type="button"
                className="btn btn-secondary fw_medium   ms-2 rounded-1   "
                variant=" "
                onClick={handleClose2}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="  btn_primary   fw_medium   ms-2 rounded-1  "
                disabled={btnIsDisable}
              >
                Save
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      {/* ADD NOTES */}
    </>
  );
};

export default Notes;

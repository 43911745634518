import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import CancelCanves from "./ActionsCanves/CancelCanves";
import { TranferCanves } from "./ActionsCanves/TranferCanves";
import dataService from "../../../apiServices/data.service";
import { useSelector } from "react-redux";

const Actions = (props) => {
  const [serviceChecked, setserviceChecked] = useState(
    props.service ? props.service : false
  );
  const [offline, setOffline] = useState(props.order ? props.order : false);
  const [demo, setDemo] = useState(props.demo ? props.demo : false);

  const [outOfOrderChecked, setoutOfOrderChecked] = useState(
    props.order == 2 ? props.order : false
  );
  const [closedChecked, setClosedChecked] = useState(
    props.order == 6 ? props.order : false
  );

  const [disallowAdditionalTimeChecked, setdisallowAdditionalTimeChecked] =
    useState(
      props.disallowAdditionalTime ? props.disallowAdditionalTime : false
    );
  const [bussingChecked, setbussingChecked] = useState(
    props.bussing ? props.bussing : false
  );
  const [pausedChecked, setpausedChecked] = useState(
    props.paused ? props.paused : false
  );

  const [enableNewAssignment, setEnableNewAssignment] = useState(null);
  const [enableCancelAssignment, setEnableCancelAssignment] = useState(null);
  const [enableTransferBay, setEnableTransferBay] = useState(null);
  const {
    disabledNewAssignmentBtnStatus,
    disabledTransferAssignmentBtnStatus,
    disabledCancelAssignmentBtnStatus,
  } = useSelector((state) => state.datatableReload);

  useEffect(() => {
    setEnableNewAssignment(!disabledNewAssignmentBtnStatus);
    setEnableCancelAssignment(!disabledCancelAssignmentBtnStatus);
    setEnableTransferBay(!disabledTransferAssignmentBtnStatus);

    console.log(
      "disabledNewAssignmentBtnStatus | ",
      disabledNewAssignmentBtnStatus,
      "disabledTransferAssignmentBtnStatus | ",
      disabledTransferAssignmentBtnStatus,
      "disabledCancelAssignmentBtnStatus | ",
      disabledCancelAssignmentBtnStatus
    );
  }, [
    disabledNewAssignmentBtnStatus,
    disabledTransferAssignmentBtnStatus,
    disabledCancelAssignmentBtnStatus,
  ]);

  useEffect(() => {
    setEnableNewAssignment(
      props.event ? false : props.bayStateSlug == "available" ? true : false
    );
    setEnableCancelAssignment(
      props.event ? false : props.bayStateSlug == "assigned" ? true : false
    );
    setEnableTransferBay(
      props.event ? false : props.bayStateSlug == "in-use" ? true : false
    );
    if (props.demo) {
      setDemo(props.demo);
    }
    if (props.service) {
      setserviceChecked(props.service);
    }
    if (props.order == 2) {
      setoutOfOrderChecked(props.order);
    }
    if (props.order == 6) {
      setClosedChecked(props.order);
    }
    if (props.disallowAdditionalTime) {
      setdisallowAdditionalTimeChecked(props.disallowAdditionalTime);
    }
    if (props.bussing) {
      setbussingChecked(props.bussing);
    }
    if (props.paused) {
      setpausedChecked(props.paused);
    }

    /* setoutOfOrderChecked(order)
    setdisallowAdditionalTimeChecked(disallowAdditionalTime)
    setbussingChecked(bussing)
    setpausedChecked(paused) */
  }, [props]);

  const handleCheck = (
    title,
    message,
    yesLabel,
    noLabel,
    yesFunction,
    noFunction,
    currentState
  ) => {
    props.closeOffCanvas();
    confirmAlert({
      message: message,
      buttons: [
        {
          label: yesLabel,
          onClick: () => {
            yesFunction(currentState);
          },
        },
        {
          label: noLabel,
          onClick: () => {
            noFunction(currentState);
          },
        },
      ],
    });
  };
  const Canceled = (currentState) => {
    console.log("Canceled", currentState);
  };
  const Confirmed = (currentState) => {
    dataService
      .updateBayFlag({
        VenueGuid: props.VENUE_GUID,
        BayGuid: props.bayGuid,
        FlagName: "ServiceRequest",
        Value: currentState ? 1 : 0,
      })
      .then((res) => {
        if (res.data.status == "success") {
          if (currentState) {
            setserviceChecked(true);
          } else {
            setserviceChecked(false);
          }
        } else {
          props.toast.error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        // Handle error
        props.toast.error(err.message);
      });
  };

  const handleChange = (e) => {
    if (e.target.checked === true) {
      handleCheck(
        "Confirm ?",
        "Set service request for the bay?",
        "Cancel ",
        "Confirm",
        Canceled,
        Confirmed,
        e.target.checked
      );
    } else if (e.target.checked === false) {
      handleCheck(
        "Confirm ?",
        "Clear service request for the bay?",
        "Cancel",
        "Confirm",
        Canceled,
        Confirmed,
        e.target.checked
      );
    }
  };
  // 2
  const CanceledOutOfOrder = (currentState) => {
    // alert("Canceled called")
  };
  const ConfirmedOutOfOrder = (currentState) => {
    dataService
      .setBayState({
        VenueGuid: props.VENUE_GUID,
        BayGuid: props.bayGuid,
        StateId: currentState ? 2 : 1,
      })
      .then((res) => {
        if (res.data.status == "success") {
          if (currentState) {
            setoutOfOrderChecked(true);
          } else {
            setoutOfOrderChecked(false);
          }
        } else {
          props.toast.error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        // Handle error
        props.toast.error(err.message);
      });
  };
  const handleChangeOutOfOrder = (input) => {
    if (input === true) {
      handleCheck(
        "Confirm ?",
        "Set out of order for the bay?",
        "Cancel ",
        "Confirm",
        CanceledOutOfOrder,
        ConfirmedOutOfOrder,
        2
      );
    } else if (input === false) {
      props.toast.error("Bay state can only be reset at the bay.");

      /* handleCheck(
        "Confirm ?",
        "Clear out of order for the bay?",
        "Cancel",
        "Confirm",
        CanceledOutOfOrder,
        ConfirmedOutOfOrder,
        input
      ) */
    }
  };

  // 2
  const CanceledDemoMode = (currentState) => {
    // alert("Canceled called")
  };
  const ConfirmedDemoMode = (currentState) => {
    dataService
      .updateBayFlag({
        VenueGuid: props.VENUE_GUID,
        BayGuid: props.bayGuid,
        FlagName: "DemoMode",
        Value: currentState ? 1 : 0,
      })
      .then((res) => {
        if (res.data.status == "success") {
          if (currentState) {
            setdemoModeChecked(true);
          } else {
            setdemoModeChecked(false);
          }
        } else {
          props.toast.error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        // Handle error
        props.toast.error(err.message);
      });
  };
  const handleChangeDemoMode = (input) => {
    if (input === true) {
      handleCheck(
        "Confirm ?",
        "Set demo mode for the bay?",
        "Cancel ",
        "Confirm",
        CanceledDemoMode,
        ConfirmedDemoMode,
        input
      );
    } else if (input === false) {
      handleCheck(
        "Confirm ?",
        "Clear demo mode for the bay?",
        "Cancel",
        "Confirm",
        CanceledDemoMode,
        ConfirmedDemoMode,
        input
      );
    }
  };

  const disableBtn = () => {
    setEnableTransferBay(false);
  };

  const disableCancelBtn = () => {
    setEnableCancelAssignment(false);
    setEnableNewAssignment(true);
    props.setTab(1);
  };

  // 3
  const CanceledAdditionalTime = (currentState) => {
    //alert("Canceled called")
  };
  const ConfirmedAdditionalTime = (currentState) => {
    //
    dataService
      .updateBayFlag({
        VenueGuid: props.VENUE_GUID,
        BayGuid: props.bayGuid,
        FlagName: "DisallowAdditionalTime",
        Value: currentState ? 1 : 0,
      })
      .then((res) => {
        if (res.data.status == "success") {
          if (currentState) {
            setdisallowAdditionalTimeChecked(true);
          } else {
            setdisallowAdditionalTimeChecked(false);
          }
        } else {
          props.toast.error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        // Handle error
        props.toast.error(err.message);
      });
  };
  const handleChangeAdditionalTime = (input) => {
    if (input === true) {
      handleCheck(
        "Confirm ?",
        "Set disallow additional time for the bay?",
        "Cancel ",
        "Confirm",
        CanceledAdditionalTime,
        ConfirmedAdditionalTime,
        input
      );
    } else if (input === false) {
      handleCheck(
        "Confirm ?",
        "Clear disallow additional time for the bay?",
        "Cancel",
        "Confirm",
        CanceledAdditionalTime,
        ConfirmedAdditionalTime,
        input
      );
    }
  };
  // 4
  const CanceledPause = (currentState) => {
    // alert("Canceled called")
  };
  const ConfirmedPause = (currentState) => {
    dataService
      .updateBayFlag({
        VenueGuid: props.VENUE_GUID,
        BayGuid: props.bayGuid,
        FlagName: "Pause",
        Value: currentState ? 1 : 0,
      })
      .then((res) => {
        if (res.data.status == "success") {
          if (currentState) {
            setpausedChecked(true);
          } else {
            setpausedChecked(false);
          }
        } else {
          props.toast.error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        // Handle error
        props.toast.error(err.message);
      });
  };
  const handleChangePause = (input) => {
    if (input === true) {
      handleCheck(
        "Confirm ?",
        "Set pause for the bay?",
        "Cancel ",
        "Confirm",
        CanceledPause,
        ConfirmedPause,
        input
      );
    } else if (input === false) {
      handleCheck(
        "Confirm ?",
        "Clear pause for the bay?",
        "Cancel",
        "Confirm",
        CanceledPause,
        ConfirmedPause,
        input
      );
    }
  };
  // 5
  const CanceledBussing = () => {
    alert("Canceled called");
  };
  const ConfirmedBussing = () => {
    alert("called");
  };
  const handleChangeBussing = (input) => {
    if (input === true) {
      handleCheck(
        "Confirm ?",
        "Set bussing for the bay?",
        "Cancel ",
        "Confirm",
        CanceledBussing,
        ConfirmedBussing,
        input
      );
    } else if (input === false) {
      handleCheck(
        "Confirm ?",
        "Clear bussing for the bay?",
        "Cancel",
        "Confirm",
        CanceledBussing,
        ConfirmedBussing,
        input
      );
    }
  };
  //6
  const CanceledOffline = () => {
    // alert("Canceled called")
  };
  const ConfirmedOffline = (currentState) => {
    dataService
      .setBayState({
        VenueGuid: props.VENUE_GUID,
        BayGuid: props.bayGuid,
        StateId: currentState ? 5 : null,
      })
      .then((res) => {
        if (res.data.status == "success") {
          if (currentState) {
            setoutOfOrderChecked(true);
          } else {
            setoutOfOrderChecked(false);
          }
        } else {
          props.toast.error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        // Handle error
        props.toast.error(err.message);
      });
  };
  const handleChangeOffline = (input) => {
    if (input === true) {
      handleCheck(
        "Confirm ?",
        "Set Offline for the bay?",
        "Cancel ",
        "Confirm",
        CanceledOffline,
        ConfirmedOffline,
        input
      );
    } else if (input === false) {
      handleCheck(
        "Confirm ?",
        "Clear Offline for the bay?",
        "Cancel",
        "Confirm",
        CanceledOffline,
        ConfirmedOffline,
        input
      );
    }
  };
  //7
  const CanceledDemo = () => {
    // alert("Canceled called")
  };
  const ConfirmedDemo = (currentState) => {
    dataService
      .updateBayFlag({
        VenueGuid: props.VENUE_GUID,
        BayGuid: props.bayGuid,
        FlagName: "DemoMode",
        Value: currentState ? 1 : 0,
      })
      .then((res) => {
        if (res.data.status == "success") {
          if (currentState) {
            setDemo(true);
          } else {
            setDemo(false);
          }
        } else {
          props.toast.error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        // Handle error
        props.toast.error(err.message);
      });
  };
  const handleChangeDemo = (input) => {
    if (input === true) {
      handleCheck(
        "Confirm ?",
        "Set Demo mode for the bay?",
        "Cancel ",
        "Confirm",
        CanceledDemo,
        ConfirmedDemo,
        input
      );
    } else if (input === false) {
      handleCheck(
        "Confirm ?",
        "Clear Demo mode for the bay?",
        "Cancel",
        "Confirm",
        CanceledDemo,
        ConfirmedDemo,
        input
      );
    }
  };

  const CanceledClosed = (currentState) => {
    // alert("Canceled called")
  };
  const ConfirmedClosed = (currentState) => {
    dataService
      .setBayState({
        VenueGuid: props.VENUE_GUID,
        BayGuid: props.bayGuid,
        StateId: currentState ? 6 : 1,
      })
      .then((res) => {
        if (res.data.status == "success") {
          if (currentState) {
            setClosedChecked(true);
          } else {
            setClosedChecked(false);
          }
        } else {
          props.toast.error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        // Handle error
        props.toast.error(err.message);
      });
  };
  const handleChangeClosed = (input) => {
    if (input === true) {
      handleCheck(
        "Confirm ?",
        "Set closed for the bay?",
        "Cancel ",
        "Confirm",
        CanceledClosed,
        ConfirmedClosed,
        2
      );
    } else if (input === false) {
      //props.toast.error("Bay state can only be reset at the bay.");

      handleCheck(
        "Confirm ?",
        "Clear closed for the bay?",
        "Cancel",
        "Confirm",
        CanceledClosed,
        ConfirmedClosed,
        input
      );
    }
  };

  return (
    <div className={`tab-pane mt-4`}>
      <Row className="  ">
        {/* <Col sm={4} className="text-center text-sm-start">
          <Button
            className="action_button text-dark fw_medium "
            disabled={!enableNewAssignment}
            onClick={props.handleShow3}
          >
            New Assignment
          </Button>
        </Col> */}

        <Col sm={4} className="text-center text-sm-start">
          <Button
            className="action_button text-dark fw_medium"
            disabled={!enableTransferBay}
            onClick={props.handleShow1}
          >
            Transfer Bay
          </Button>
        </Col>
        <Col sm={4} className="mt-2 mt-sm-0 text-center text-sm-start">
          <Button
            className="action_button text-dark fw_medium "
            disabled={!enableCancelAssignment}
            onClick={props.handleShow}
          >
            Cancel Assignment
          </Button>
        </Col>
      </Row>
      <h5 className="mt-3">FLAGS</h5>
      <Form>
        <Row className="switch-btn">
          <Col md="8">
            <Row className="mt-4">
              <Col md="8">Service Request </Col>
              <Col md="4">
                <div className="d-flex justify-content-evenly">
                  <Form.Check
                    key="saurabh"
                    label=""
                    type="switch"
                    id="service-request"
                    value={serviceChecked}
                    checked={serviceChecked}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
            </Row>
            {/* <Row className="mt-4">
              <Col md="8">Demo Mode</Col>
              <Col md="4">
                <div className="d-flex justify-content-evenly">
                  <Form.Check
                    key="saurabh"
                    label=""
                    type="switch"
                    id="demo-mode"
                    value={demo}
                    checked={demo}
                    onChange={(e) => handleChangeDemo(e.target.checked)}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col md="8">Disallow Additional Time</Col>
              <Col md="4">
                <div className="d-flex justify-content-evenly">
                  <Form.Check
                    type="switch"
                    id="disallow-additional-time"
                    label=""
                    value={disallowAdditionalTimeChecked}
                    checked={disallowAdditionalTimeChecked}
                    onChange={(e) => {
                      handleChangeAdditionalTime(e.target.checked);
                    }}
                  />
                </div>
              </Col>
            </Row> */}
            <Row className="mt-4">
              <Col md="8">Pause</Col>
              <Col md="4">
                <div className="d-flex justify-content-evenly">
                  <Form.Check
                    type="switch"
                    id="pause"
                    label=""
                    value={pausedChecked}
                    checked={pausedChecked}
                    onChange={(e) => {
                      handleChangePause(e.target.checked);
                    }}
                  />
                </div>
              </Col>
            </Row>
            {/* <Row className="mt-4">
          <Col md="8">Technical Issues</Col>
          <Col md="4">
            <div className='d-flex justify-content-evenly'>
              <Form.Check
                type="switch"
                id="custom-switch"
                label=""
                defaultChecked={props.bay.is_out_of_order}
              />
            </div>
          </Col>
        </Row> */}

            <Row className="mt-4">
              <Col md="8">Bussing</Col>
              <Col md="4">
                <div className="d-flex justify-content-evenly">
                  <Form.Check
                    type="switch"
                    id="bussing"
                    label=""
                    value={bussingChecked}
                    defaultChecked={bussingChecked}
                    disabled
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr></hr>
        <h5 className="mt-3 ">STATES</h5>
        <Row className="switch-btn">
          <Col md="8">
            <Row className="mt-4">
              <Col md="8">Out Of Order</Col>
              <Col md="4">
                <div className="d-flex justify-content-evenly">
                  <Form.Check
                    label=""
                    type="switch"
                    id="out-of-order"
                    value={outOfOrderChecked}
                    checked={outOfOrderChecked == 2 ? true : false}
                    onChange={(e) => handleChangeOutOfOrder(e.target.checked)}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="8">Bay Closed</Col>
              <Col md="4">
                <div className="d-flex justify-content-evenly">
                  <Form.Check
                    label=""
                    type="switch"
                    id="closed"
                    value={closedChecked}
                    checked={closedChecked == 6 ? true : false}
                    onChange={(e) => handleChangeClosed(e.target.checked)}
                  />
                </div>
              </Col>
            </Row>
            {/* <Row className="mt-4">
              <Col md="8">Offline</Col>
              <Col md="4">
                <div className="d-flex justify-content-evenly">
                  <Form.Check
                    label=""
                    type="switch"
                    id="offline"
                    value={offline}
                    checked={offline}
                    onChange={(e) => handleChangeOffline(e.target.checked)}
                  />
                </div>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Form>

      <CancelCanves
        handleClose={props.handleClose}
        show={props.show}
        bayCss={props.bayCss}
        bayEndTime={props.bayEndTime}
        bayStartTime={props.bayStartTime}
        bayNumber={props.bayNumber}
        bayStateName={props.bayStateName}
        bayRowGuid={props.bayGuid}
        waitlistGuid={props.waitlistGuid}
        toast={props.toast}
        showFlagUi={props.showFlagUi}
        showFlagCount={props.showFlagCount}
        disableCancelBtn={disableCancelBtn}
      />
      <TranferCanves
        handleClose1={props.handleClose1}
        show1={props.show1}
        bayCss={props.bayCss}
        bayStartTime={props.bayStartTime}
        bayEndTime={props.bayEndTime}
        bayStateName={props.bayStateName}
        bayNumber={props.bayNumber}
        bayRowGuid={props.bayGuid}
        waitlistGuid={props.waitlistGuid}
        toast={props.toast}
        showFlagUi={props.showFlagUi}
        showFlagCount={props.showFlagCount}
        disableBtn={disableBtn}
      />
    </div>
  );
};

export default Actions;

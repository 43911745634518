import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import NewAssignment from "../Available/NewAssigment";
import dataService from "../../apiServices/data.service";
import { toast } from "react-toastify";
import { COUNT_DOWN_TIMER, VENUE_GUID } from "../../constants";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Actions from "./TabsContent/Actions";
import GuestInfo from "./TabsContent/GuestInfo";
import Notes from "./TabsContent/Notes";
import Equipment from "./TabsContent/Equipment";
import { confirmAlert } from "react-confirm-alert";
import Countdown, { zeroPad } from "react-countdown";
import { useSelector } from "react-redux";
import {
  millisToMinutesAndSeconds,
  slugify,
  timeDiffInMinutes,
} from "../../commonFunctions";
import moment, { now } from "moment-timezone";
import tokenService from "../../apiServices/token.service";
import Events from "./TabsContent/Events";

const InUse = (props) => {
  const twoDigits = (num) => String(num).padStart(2, "0");

  const { allBays, trigger_change, bay_timer } = useSelector(
    (state) => state.bays
  );
  const [bay, setBay] = useState(allBays[props.bay]);

  const [showExtentedPopup, setShowExtentedPopup] = useState(false);

  const labelCss = {
    color: bay.IsEvent ? "rgb(76, 175, 80)" : bay.IsOnline ? bay.LabelColor ? bay.LabelColor : null : "#000",
  };

  const [isValid, setValid] = useState(false);
  const validate = () => {
    return allBays[props.bay].BayStateId == 1 ? true : false;
  };


  const [bayCss, setBayCss] = useState({
    backgroundColor: bay.IsEvent ? "#4CAF50" : bay.FillColor,
    borderColor: bay.IsEvent
      ? "#4CAF50"
      : bay.BorderColor
        ? bay.BorderColor
        : bay.FillColor,
    color: bay.IsEvent ? "#fff" : bay.TextColor ? bay.TextColor : null,
  });

  useEffect(() => {
    const isValid = validate();
    setValid(isValid);
  }, [allBays[props.bay].BayStateId]);

  useEffect(() => {
    setBayCss({
      backgroundColor: bay.IsOnline
        ? bay.IsEvent
          ? "#4CAF50"
          : bay.FillColor
        : bay.BayStateName == 'Out Of Order' || bay.BayStateName == 'Closed'
          ? bay.FillColor
          : "#000",
      borderColor: bay.IsOnline
        ? bay.IsEvent
          ? "#4CAF50"
          : bay.BorderColor

        : bay.BayStateName == 'Out Of Order' || bay.BayStateName == 'Closed'
          ? bay.FillColor
          : "#000",
      color: bay.IsEvent ? "#fff" : bay.IsOnline ? bay.TextColor ? bay.TextColor : null : "#fff",
    });
  }, [bay]);
  const [counterTime, setCounterTime] = useState(0);

  useEffect(() => {
    setBay(allBays[props.bay]);
  }, [trigger_change]);

  useEffect(() => {
    if (allBays[props.bay].LockExpiryTime) {
      setCounterTime(timeDiffInMinutes(allBays[props.bay].LockExpiryTime));
    }
  }, [bay]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counterTime > 0) {
        setCounterTime(counterTime - 1000);
      } else {
        props.handleCloseOffcanvesTwo(bay);
        setKey((key) => key + 1);
        document.dispatchEvent(
          new KeyboardEvent("keydown", {
            key: "e",
            keyCode: 27, // example values.
            code: "KeyE", // put everything you need in this object.
            which: 27,
            shiftKey: false, // you don't need to include values
            ctrlKey: false, // if you aren't going to use them.
            metaKey: false, // these are here for example's sake.
          })
        );
        setCounterTime(0);
      }
    }, 1000);

    if (counterTime > 0 && counterTime < 60000) {
      setShowExtentedPopup(true);

      if (!showExtentedPopup) {
        confirmAlert({
          message:
            "Your bay lock time is about to end. Would you like to extend the time?",
          buttons: [
            {
              label: "Cancel",
              onClick: () => props.handleCloseOffcanvesTwo(bay),
            },
            {
              label: "Extend Time",
              onClick: () => {
                setKey((key) => key + 1);
                dataService
                  .extentBayLock({
                    VenueGuid: VENUE_GUID,
                    ResourceGuid: bay.BayGuid,
                    ResourceName: bay.BayNumber,
                    LockedBy: tokenService.getName(),
                    LockExpiryTime: COUNT_DOWN_TIMER,
                    ResourceType: "Bay",
                  })
                  .then((res) => {
                    if (res.data.status != "success") {
                      toast.error(res.data.errorMessage);
                    }
                  })
                  .catch((err) => {
                    toast.error(err.message);
                  });
              },
            }
          ],
        });
      }
    }

    return () => clearInterval(interval);
  }, [counterTime]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => {
    bay.IsOnline ? setShow3(true) : toast.error("The bay must be online for assignment.");
  }

  const [show4, setShow4] = useState(false);
  const handleShow4 = () => setShow4(true);
  const handleClose4 = () => setShow4(false);

  const [modalshow, setModalShow] = useState(false);

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const [mainTabs, setMainTabs] = useState(1);
  const [tab, setTab] = useState(1);

  const [key, setKey] = useState(1);

  const closeOffCanvas = () => {
    setModalShow(false);
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  const showConfirmBox = ({ hours, minutes, seconds, completed }) => {
    if (minutes < 1 && seconds == 59) {
    }
  };
  const showFlagUi = () => {
    if (bay.BayStateId == "6" || bay.BayStateId == "2") {
      if (bay.IsLocked) {
        return (
          <div
            className="bay-nstatus "
            style={{ background: "#FFC107", color: "#000" }}
          >
            <span>Locked</span>
          </div>
        );
      }
    } else {
      if (bay.IsLocked) {
        return (
          <div
            className="bay-nstatus "
            style={{ background: "#FFC107", color: "#000" }}
          >
            <span>Locked</span>
          </div>
        );
      }

      if (bay.IsPaused) {
        return (
          <div
            className="bay-nstatus "
            style={{
              background: "#FFF",
              // color: bay.IsEvent ? "#c92167" : bay.FillColor,
              color: "#C0155C",
            }}
          >
            <span className="d-flex  pause-icon ">
              <i className="fa-solid fa-triangle-exclamation"></i>
            </span>
          </div>
        );
      }


      if (!bay.IsOnline) {
        return (
          <div
            className="bay-nstatus "
            style={{
              background: "#FFF",
              color: "#000",
            }}
          >
            <span>Offline</span>
          </div>
        );
      }

      /* if (!bay.IsOnline) {
        return (
          <div
            className="bay-nstatus "
            style={{ background: "#000", color: "#fff" }}
          >
            <span>Offline</span>
          </div>
        );
      } */

      /* if (bay.IsOutOfOrder) {
        return (
          <div
            className="bay-nstatus "
            style={{
              background: "#FFF",
              color: bay.IsEvent ? "#F57522" : bay.FillColor,
            }}
          >
            <span>Out of Order</span>
          </div>
        );
      } */
      if (bay.IsServiceRequest) {
        return (
          <div
            className="bay-nstatus "
            style={{
              background: "#FFC107",
              color: bay.IsEvent ? "#ffffff" : "#000",
            }}
          >
            <span>Service</span>
          </div>
        );
      }

      if (bay.IsBussing) {
        return (
          <div
            className="bay-nstatus "
            style={{
              background: "#2c4cba",
              color:
                status == "available" || bay.IsBussing
                  ? "#fff"
                  : bay.IsEvent
                    ? "#fff"
                    : "#000",
            }}
          >
            <span>Bussing</span>
          </div>
        );
      }

      if (bay.IsDemoMode) {
        return (
          <div
            className="bay-nstatus "
            style={{
              background: "#fff",
              color: bay.IsEvent ? "#C0155C" : "#C0155C",
            }}
          >
            <span>Demo</span>
          </div>
        );
      }
    }
  };

  const showFlagCount = () => {
    let flagCount = 0;

    /* if (!bay.IsOnline) {
      flagCount = flagCount + 1;
    } */
    /*  if (bay.IsLocked) {
       flagCount = flagCount + 1;
     } */
    if (bay.IsPaused) {
      flagCount = flagCount + 1;
    }
    if (bay.IsOutOfOrder) {
      flagCount = flagCount + 1;
    }
    if (bay.IsServiceRequest) {
      flagCount = flagCount + 1;
    }
    if (bay.IsDisallowAdditionalTime) {
      flagCount = flagCount + 1;
    }

    if (bay.IsBussing) {
      flagCount = flagCount + 1;
    }

    if (bay.IsDemoMode) {
      flagCount = flagCount + 1;
    }

    return flagCount > 1 ? (
      <Link to="" title="" className={`bay-alert`}>
        {flagCount}
      </Link>
    ) : (
      ""
    );
  };

  return (
    <>
      {/* New Assignment */}
      <Offcanvas
        show={show3}
        onHide={handleClose3}
        placement="end"
        className="off_canves_two off_canves offcanvas offcanvas-end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5>New Assignment</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <NewAssignment
            bay={bay}
            bayCss={bayCss}
            handleClose3={handleClose3}
            tab={tab}
            setTab={setTab}
            showFlagUi={showFlagUi}
            showFlagCount={showFlagCount}
          />
        </Offcanvas.Body>
      </Offcanvas>
      {/* New Assignment */}

      <div className="offcanvas-body bay_box position-relative pt-0">
        <Offcanvas.Header closeButton className="text-reset"></Offcanvas.Header>
        <Row className="row justify-content-center justify-content-sm-start mt-3">
          <Col xs={6} className=" ">
            <div className="bay-d-wid">
              <div className="card-status-time"></div>
              <div
                className={`bay-card-box  ${bay.IsServiceRequest ? "service-requested" : null
                  }`}
                style={bayCss}
              >
                <div className="bay-divider">
                  <div className="d-flex align-items-center justify-content-center sec1">
                    <span className="fw-bold ">{bay.BayNumber}</span>
                  </div>
                  {showFlagUi()}
                </div>
              </div>
              <div className="bay-card-notifcation-offcanvas">
                {bay.BayStateId == "6" || bay.BayStateId == "2"
                  ? ""
                  : showFlagCount()}
              </div>
              <div
                className="bay-card-status text-uppercase w-100 mb-3"
                style={labelCss}
              >
                {bay.IsOnline ? bay.IsEvent ? "Event" : bay.BayStateName : "Offline"}
              </div>
            </div>
          </Col>
        </Row>
        <div className="border-puple"></div>

        <Row className="vip_tabs">
          <Col xs={12} className=" py-2 bg_sky_blue">
            <div className="timer d-flex gap-2 justify-content-end me-2">
              <div className="">
                <i className="fa-regular fa-clock"></i>
              </div>
              <div className="text-align-center">
                <h6 className="fw-bold  text-start">
                  <p key={counterTime}>
                    {millisToMinutesAndSeconds(counterTime)}
                  </p>
                  {/* <Countdown key={key} date={Date.now() + (counterTime)} renderer={renderer} onComplete={() => {
                  setKey((key) => key + 1)
                  document.dispatchEvent(
                    new KeyboardEvent("keydown", {
                      key: "e",
                      keyCode: 27, // example values.
                      code: "KeyE", // put everything you need in this object.
                      which: 27,
                      shiftKey: false, // you don't need to include values
                      ctrlKey: false,  // if you aren't going to use them.
                      metaKey: false   // these are here for example's sake.
                    })
                  );
                  props.handleCloseOffcanvesTwo(bay);
                }} onTick={(data) => {
                  showConfirmBox(data)
                }} /> */}
                </h6>
              </div>
            </div>
            <Tabs
              defaultActiveKey="Actions"
              id="uncontrolled-tab-example"
              className="mb-3"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Tab eventKey="Actions" title=" Actions">
                <Actions
                  key={bay.BayGuid}
                  isValid={isValid}
                  // for cancel canves
                  closeOffCanvas={closeOffCanvas}
                  show={show}
                  handleShow={handleShow}
                  handleClose={handleClose}
                  // for tranfer canvas
                  show1={show1}
                  handleShow1={handleShow1}
                  handleClose1={handleClose1}
                  handleShow3={handleShow3}
                  // for actions
                  event={bay.IsEvent}
                  service={bay.IsServiceRequest}
                  order={bay.BayStateId}
                  paused={bay.IsPaused}
                  bussing={bay.IsBussing}
                  disallowAdditionalTime={bay.IsDisallowAdditionalTime}
                  demo={bay.IsDemoMode}
                  // for both canves
                  bayStateName={bay.BayStateName}
                  bayStateSlug={slugify(bay.BayStateName)}
                  bayNumber={bay.BayNumber}
                  bayStartTime={bay.SessionStartTime}
                  bayEndTime={bay.SessionEndTime}
                  bayCss={bayCss}
                  bayGuid={bay.BayGuid}
                  VENUE_GUID={VENUE_GUID}
                  toast={toast}
                  handleCloseOffcanvesTwo={() =>
                    props.handleCloseOffcanvesTwo(bay)
                  }
                  waitlistGuid={bay.IntakeGuid}
                  showFlagUi={showFlagUi}
                  showFlagCount={showFlagCount}
                  setTab={setTab}
                />
              </Tab>
              <Tab
                eventKey="GuestInfo"
                title="Guest Info"
                disabled={
                  !(
                    slugify(bay.BayStateName) == "in-use" ||
                    slugify(bay.BayStateName) == "assigned"
                  )
                }
              >
                <GuestInfo rowGuid={bay.BayGuid} IntakeGuid={bay.IntakeGuid} />
              </Tab>
              <Tab eventKey="Notes" title="Notes">
                <Notes
                  handleShow2={handleShow2}
                  show2={show2}
                  handleClose2={handleClose2}
                  bayGuid={bay.BayGuid}
                />
              </Tab>
              <Tab eventKey="Equipment" title="Equipment">
                <Equipment
                  handleShow4={handleShow4}
                  show4={show4}
                  handleClose4={handleClose4}
                />
              </Tab>
              <Tab eventKey="Events" title="Events">
                <Events bayGuid={bay.BayGuid} dataService={dataService} />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default InUse;

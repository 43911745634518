import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import dataService from "../../apiServices/data.service";
import { VENUE_GUID } from "../../constants";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { getUniqueKeys, groupByKey, sortByKeySession } from "../../commonFunctions";
import { useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import ThreeDotsLoader from "../ThreeDotsLoader/ThreeDotsLoader";

const DayPricing = () => {
  const { venue } = useSelector((state) => state.venues);
  const { venueDateTime } = useSelector((state) => state.venueDateTime);
  const [startDate, setStartDate] = useState(new Date());
  const dateTime = moment();
  const [pricingData, setPricingData] = useState([]);
  const [keys, setKey] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const { bay_types } = useSelector((state) => state.bays);
  const [show, setShow] = useState();
  const [cols, setCols] = useState([]);


  useEffect(() => {
    setShow(true);
    dataService
      .getDayPricing({
        VenueGuid: VENUE_GUID,
        ForDate: venueDateTime.length > 0 ? moment.utc(venueDateTime).format("YYYY-MM-DD") : dateTime.format("YYYY-MM-DD"),
      })
      .then((res) => {
        if (res.data.status == "success") {
          setPricingData(groupByKey(res.data.response, "DayPartName"));
          setKey(getUniqueKeys(res.data.response, "DayPartName"));
          setCols(
            sortByKeySession(
              Object.values(groupByKey(res.data.response, "DayPartName"))[0],
              "Id"
            )
          );

        } else {
          toast.error(res.data.errorMessage);
        }
        setShow(false);
      })
      .catch((err) => {
        // Handle error
        toast.error(err.message);
      });
  }, [refresh]);

  const refreshData = () => {
    setRefresh((refresh) => refresh + 1);
  };

  return (
    <div className="pricing">
      <Form>
        <Row className="align-items-baseline">
          <Col md="3">
            <div className="d-flex">
              <Form.Group className=" " controlId="formPlaintextEmail">
                <Form.Label className="py-0"> Today's Date</Form.Label>
              </Form.Group>
              <p className="ms-4"> { venueDateTime.length > 0 ? moment.utc(venueDateTime).format("DD MMM YYYY") : dateTime.format("YYYY-MM-DD") }</p>
            </div>
          </Col>
          {/* <Col md="3">
                        <div className='d-flex'>
                            <Form.Group className=" " controlId="formPlaintextEmail">
                                <Form.Label className='py-0'>
                                    Current Time
                                </Form.Label>
                            </Form.Group>
                            <p className='ms-4'>{dateTime.format('h:mm a')}</p>
                            <Button variant='none' size="sm" className=' fw_medium rounded-1 border-0 ms-2' onClick={refreshData}>
                                <i className="fa-solid fa-arrows-rotate"></i>
                            </Button>
                        </div>

                    </Col> */}
        </Row>
      </Form>
      <hr></hr>
      {/* <Row>
                <Col sm="2">
                    <h6>Pricing in Effect</h6>
                </Col>
                <Col sm="2">
                    Cinco de mayo
                </Col>
                <Col sm="2">
                    1/1/2023 - 3/1/2023
                </Col>
            </Row>
            <hr></hr> */}
      <div className="table-responsive">
        {show ? (
          <ThreeDotsLoader />
        ) : (
          <Table className="table pricing-table ">
            <thead>
              <tr>
                <th scope="col " width="200 ">
                  {" "}
                </th>
                <th scope="col " width=" ">
                  Time
                </th>
               
                 {cols.length > 0
                  ? cols.map((data, i) => {
                      return (
                        <th scope="col " width="200" key={i}>
                          {data.BayTypeName}
                        </th>
                      );
                    })
                  : null}
              </tr>
            </thead>
            <tbody>
              {keys.map((data, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <h6>{data}</h6>
                    </td>
                    <td>{`${moment(
                      pricingData[data][0].DayPartStartTime,
                      "HH:mm:ss"
                    ).format("h:mm a")} to ${moment(
                      pricingData[data][0].DayPartEndTime,
                      "HH:mm:ss"
                    ).format("h:mm a")} `}</td>
                    {pricingData[data].map((bay_type, di) => {
                      return <td key={di}>${bay_type.DayPartPrice}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default DayPricing;

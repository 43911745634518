import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import WaitList from "../components/WaitList/WaitList";
import Error404 from "../views/Error/Error404";
import Pricing from "../views/Pricing/Pricing";
import PrivateRoute from "./PrivateRoute";
import AccessDenied from "../views/Error/AccessDenied";
import Config from "../views/Config/Index";
import Canon from "../views/Dashboard/Canon";
import AccountSelection from "../views/Login/AccountSelection";
import Reservations from "../views/Reservations/Reservations";

const Index = lazy(() => import("../views/Dashboard/Index"));
const Putting = lazy(() => import("../views/Dashboard/Putting"));

const Login = lazy(() => import("../views/Login/Login"))
const Intake = lazy(() => import("../views/Intake/Intake"))
const PlayerSearch = lazy(() => import("../views/PlayerSearch/PlayerSearch"))
const Events = lazy(() => import("../views/Events/Events"))

const routes = createBrowserRouter([
    {
        path: "/",
        /* element: <PrivateRoute />, */
        children: [
            {
                path: "/",
                element: <PrivateRoute><Index /></PrivateRoute>,
            },
            {
                path: "intake",
                element: <PrivateRoute><Intake /></PrivateRoute>,

            },
            {
                path: "waitlist/:id?",
                element: <PrivateRoute><WaitList /></PrivateRoute>
            },
            {
                path: "pricing",
                element: <PrivateRoute><Pricing /></PrivateRoute>
            },
            {
                path: "/config",
                element: <Config />,
            },
            {
                path: "/putting-bays",
                element: <PrivateRoute><Putting /></PrivateRoute>,
            },
            {
                path: "/canon-bays",
                element: <PrivateRoute><Canon /></PrivateRoute>,
            },
            {
                path: "/player-search",
                element: <PrivateRoute><PlayerSearch /></PrivateRoute>,
            },
            {
                path: "/events",
                element: <PrivateRoute><Events /></PrivateRoute>,
            },
            {
                path: "/fast-pass",
                element: <PrivateRoute><Reservations /></PrivateRoute>,
            }
        ],
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/account-selection",
        element: <AccountSelection />,
    },
    {
        path: "/access-denied",
        element: <AccessDenied />,
    },

    {
        path: "*",
        element: <Error404 />
    },
]);

export default routes;


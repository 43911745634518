import { getCookie } from "../commonFunctions";

class TokenService {
    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.refreshToken;
    }
  
    getLocalAccessToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.token;

     /*  const user = JSON.parse(getCookie("user"));
      return user?.token; */


    }

    getExpiryTimeForToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.idTokenClaims;
    }


    getUserName() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.username;
    }
  
    getName() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.name;
    }

    getPersonGuid() {
      const user = JSON.parse(localStorage.getItem("personInfo"));
      return user?.PersonsGuid;
    }

    updateLocalAccessToken(token) {
      let user = JSON.parse(localStorage.getItem("user"));
      user.token = token;
      localStorage.setItem("user", JSON.stringify(user));
    }
  
    getUser() {
      return JSON.parse(localStorage.getItem("user"));
      //return JSON.parse(getCookie("user"))
    }
  
    setUser(user) {
      console.log(JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));
    }
  
    removeUser() {
      localStorage.removeItem("user");
    }

    getUserRowGuid() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.row_guid;
    }

    getExpTime() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.idTokenClaims?.exp;
  }
  }
  
  export default new TokenService();
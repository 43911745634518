import React, { useEffect, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import dataService from "../../apiServices/data.service";
import { VENUE_GUID } from "../../constants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getVenues } from "../../features/venueSlice";

const OpenCloseVenue = (props) => {
  const dispatch = useDispatch();
  const { venue } = useSelector((state) => state.venues);
  const [show, setShow] = useState(false);
  const [tabKey, setTabKey] = useState(venue.length > 0 ? !venue[0].OpenCloseStatus ? "Open Venue" : "Close Venue" : "Archive Sessions & Waitlist");
  const handleClose = () => {
    props.setFalse();
    setShow(false);
  };
  useEffect(() => {
    setShow(props.show);
  }, [props]);

  const openVenue = () => {
    dataService
      .openVenue({
        VenueGuid: VENUE_GUID,
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success("Venue opened.");
          dispatch(getVenues());
          handleClose();
          setTabKey("Close Venue");
        } else {
          toast.error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const closeVenue = () => {
    dataService
      .closeVenue({
        VenueGuid: VENUE_GUID,
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success("Venue closed.");
          dispatch(getVenues());
          setTabKey("Open Venue");
          handleClose();
        } else {
          toast.error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="off_canves_two off_canves offcanvas offcanvas-end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Open/Close Venue</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="vip_tabs">
            <Tabs
              defaultActiveKey={tabKey}
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              {venue.length > 0 ? (
                !venue[0].OpenCloseStatus ? (
                  <Tab eventKey="Open Venue" title="Open Venue">
                    <div className="text-center lh-lg">
                      <p>There are 5 Sessions Open & 6 guests in Waitlist.</p>
                      <p>
                        To archive please go to Archive Sessions & Waitlist Tab.
                      </p>
                      <Button
                        variant="secondary"
                        className="mt-3"
                        onClick={openVenue}
                      >
                        Open Venue
                      </Button>
                    </div>
                  </Tab>
                ) : (
                  <Tab eventKey="Close Venue" title="Close Venue">
                    <div className="text-center lh-lg">
                      <p>There are 5 Sessions Open & 6 guests in Waitlist.</p>
                      <p>
                        To archive please go to Archive Sessions & Waitlist Tab.
                      </p>
                      <Button
                        variant="secondary"
                        className="mt-3"
                        onClick={closeVenue}
                      >
                        Close Venue
                      </Button>
                    </div>
                  </Tab>
                )
              ) : null}

              <Tab
                eventKey="Archive Sessions & Waitlist"
                title="Archive Sessions & Waitlist"
              >
                <div className="text-center lh-lg">
                  <p>There are 5 Sessions Open & 6 guests in Waitlist.</p>
                  <p>
                    To archive please go to Archive Sessions & Waitlist Tab.
                  </p>
                  <Form className="mt-4 ">
                    <p className="text-danger mb-2">
                      Archive Active Sessions & Waitlist
                    </p>
                    <div className="d-flex text-center mx-5">
                      <Form.Control placeholder="Please type" />
                      <Button variant="secondary" className="ms-2">
                        Archive
                      </Button>
                    </div>
                  </Form>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default OpenCloseVenue;

import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import authService from "../../apiServices/auth.service";
import {
  ADMIN_APP_SWITCH_URL,
  AD_LOGIN_URL,
  APP_NAME,
  BLACK_LOGO,
  EMPLOYEE_APP_SWITCH_URL,
  EVENT_APP_SWITCH_URL,
  FLOOR_APP_SWITCH_URL,
  IS_LUXE,
  SIMULATOR_APP_SWITCH_URL,
  roles,
  RESPONSIVE_APP_LOGO,
  BUSSINESS_REPORTING_APP_SWITCH_URL
} from "../../constants";
import { useLocation } from "react-router-dom";
import ProfileDropdown from "../../views/ProfileDropdown/ProfileDropdown";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, updateLoadedStatus } from "../../features/profileSlice";
import tokenService from "../../apiServices/token.service";
import PauseVenue from "../../views/PauseVenue/PauseVenue";
import OpenCloseVenue from "../../views/OpenCloseVenue/OpenCloseVenue";
import { Dropdown, NavDropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { useBackendTokenCheckExpirationTime } from "../../hooks/useBackendTokenCheckExpirationTime";

const Header = ({ handleActiveState }) => {

  const expireStatus = useBackendTokenCheckExpirationTime();

  const dispatch = useDispatch();
  const [showPauseVenue, setShowPauseVenue] = useState(false);
  const [showOpenVenue, setShowOpenVenue] = useState(false);
  const location = useLocation();
  const { instance } = useMsal();
  const { profile, is_loaded } = useSelector((state) => state.profile);
  const name = tokenService.getName();
  useEffect(() => {
    if (!is_loaded) {
      dispatch(getProfile(tokenService.getUser()?.username));
      dispatch(updateLoadedStatus(1));
    }
  }, []);
  const handleShow = () => setShowPauseVenue(true);
  const handleShowOpen = () => setShowOpenVenue(true);
  const setFalse = () => {
    setShowPauseVenue(false);
    setShowOpenVenue(false);
  };

  /*  const openInNewTab = (url, value) => {
     if (value != APP_NAME) {
       try {
         const homeAccountId = instance.getActiveAccount()?.homeAccountId;
         const urlObject = new URL(url);
 
         // Set the 'q' query parameter with the homeAccountId
         urlObject.searchParams.set("q", homeAccountId);
 
         // Open the modified URL in a new tab
         const childWindow = window.open(urlObject.href, APP_NAME);
 
         if (childWindow) {
           // Send a message to the child window when it loads
           childWindow.onload = function () {
             childWindow.postMessage(
               JSON.stringify(instance.getActiveAccount()),
               "*"
             );
           };
         } else {
           console.error("Failed to open the child window.");
         }
       } catch (error) {
         console.error("An error occurred:", error);
       }
     } else {
       toast.error("You are already on same app.");
     }
     return false;
   }; */

  const openInNewTab = (url) => {
    if (url != AD_LOGIN_URL) {
      window.open(url, "_blank");
    } else {
      toast.error("You are already on same app.");
    }
  };

  const showSelectedMenu = (val) => {
    if (val == "/putting-bays") {
      return <><img
        src="../img/putting-automic.svg"
        className="me-1 atomic-img show-img"
      ></img><span className="d-none d-lg-inline-flex">Putting Bays</span></>
    }
    if (val == "/canon-bays") {
      return <><img
        src="../img/canon-atomic.svg"
        className="me-1 atomic-img show-img"
      ></img><span className="d-none d-lg-inline-flex">Cannon Bays</span></>
    }

    if (val == "/") {
      return <><i className="fa-solid fa-golf-ball-tee header-icon"></i>

        <span className="d-none d-lg-inline-flex">Hitting Bays</span></>
    }


    return <><span className="d-none d-lg-inline-flex">Dashboard</span></>
  }

  return (
    <>
      <nav className="navbar header navbar-expand justify-content-between sticky-top  ">
        <div className="d-flex align-items-center">
          <div className="nav-item">
            <Link to="/" className="navbar-brand d-flex d-lg-none me-4">
              <img src={`${RESPONSIVE_APP_LOGO}`} alt="" className="img-fluid" />
            </Link>
          </div>
          <div className="nav-item">
            <Link
              className="sidebar-toggler nav-link"
              onClick={() => handleActiveState()}
            >
              <i className="fa-solid fa-bars mt-2"></i>
            </Link>
          </div>
          <NavDropdown title={showSelectedMenu(location.pathname)} id="basic-nav-dropdown" className="bay-dropdown">
            <NavDropdown.Item href="/" className={` d-flex ${location.pathname === "/" ? "activeHeader" : ""
              }`}>
              <i className="fa-solid fa-golf-ball-tee header-icon"></i>

              <span className="d-lg-inline-flex">Hitting Bays</span>

            </NavDropdown.Item>

            {IS_LUXE == "false" ? (
              <>
                <NavDropdown.Item href="/putting-bays" className={` d-flex ${location.pathname === "/putting-bays" ? "activeHeader" : ""
                  }`}>
                  <img
                    src="../img/putting-automic.svg"
                    className="me-1 atomic-img show-img"
                  ></img>
                  <img
                    src="../img/putting-atomic-white.png"
                    className="me-1 atomic-img hide-img"
                  ></img>
                  {/* <i className="fa-solid fa-flag header-icon"></i> */}
                  <span className="d-lg-inline-flex">Putting Bays</span>
                </NavDropdown.Item>
                <NavDropdown.Item href="/canon-bays" className={` d-flex ${location.pathname === "/canon-bays" ? "activeHeader" : ""
                  }`}>
                  <img
                    src="../img/canon-atomic.svg"
                    className="me-1 atomic-img show-img"
                  ></img>
                  <img
                    src="../img/canon-atomic-white.png"
                    className="me-1 atomic-img hide-img"
                  ></img>
                  <span className="d-lg-inline-flex">Cannon Bays</span>
                </NavDropdown.Item>
              </>
            ) : null}
          </NavDropdown>



          <div className="nav-item ">
            <Link
              to="/intake"
              className={`nav-link d-flex ${location.pathname === "/intake" ? "activeHeader" : ""
                }`}
            >
              <i className="fa-solid fa-plus header-icon"></i>

              <span className="d-none d-lg-inline-flex">Intake</span>
            </Link>
          </div>
          <div className="nav-item ">
            <Link
              to="/waitlist"
              className={`nav-link d-flex ${location.pathname === "/waitlist" ? "activeHeader" : ""
                }`}
              data-bs-toggle="dropdown"
            >
              <i className="fa-solid fa-list header-icon"></i>

              <span className="d-none d-lg-inline-flex">Guest List</span>
            </Link>
          </div>

          {/* <div>
            <Link to="/" className="nav-link " data-bs-toggle="dropdown">
              <i className="bi bi-calendar-check-fill me-2"></i>
              <span className="d-none d-lg-inline-flex">Events</span>
            </Link>
          </div> */}
          <div className="nav-item  ">
            <Link
              to="/pricing"
              className={`nav-link d-flex ${location.pathname === "/pricing" ? "activeHeader" : ""
                }`}
              data-bs-toggle="dropdown"
            >
              <i className="fa-solid fa-dollar-sign header-icon"></i>

              <span className="d-none d-lg-inline-flex">Pricing</span>
            </Link>
          </div>
          {/* <div className="nav-item  ">
            <Link
              className={`nav-link d-flex  ${location.pathname === "/ " ? "activeHeader" : ""
                }`}
              data-bs-toggle="dropdown"
              onClick={() => setShowPauseVenue(true)}
            >
              <i className="fa-solid fa-bolt header-icon"></i>

              <span className="d-none d-lg-inline-flex">Venue Pause</span>
            </Link>
          </div> */}
          <div className="nav-item  ">
            <Link
              to="/player-search"
              className={`nav-link d-flex ${location.pathname === "/player-search" ? "activeHeader" : ""
                }`}
              data-bs-toggle="dropdown"
            >
              <i className="fa-solid fa-magnifying-glass header-icon"></i>
              <span className="d-none d-lg-inline-flex">Player Search</span>
            </Link>
          </div>
          <div className="nav-item ">
            <Link
              to="/events"
              className={`nav-link d-flex ${location.pathname === "/events" ? "activeHeader" : ""
                }`}
            >

              <i className="fa-regular fa-calendar header-icon"></i>

              <span className="d-none d-lg-inline-flex">Events</span>
            </Link>
          </div>
          <div className="nav-item ">
            <Link
              to="/fast-pass"
              className={`nav-link d-flex ${location.pathname === "/fast-pass" ? "activeHeader" : ""
                }`}
            >

              <i className="fa-solid fa-calendar-week header-icon"></i>

              <span className="d-none d-lg-inline-flex">Fast Pass</span>
            </Link>
          </div>
          {/* <div className="nav-item  ">
            <Link
              className={`nav-link ${location.pathname === "/ " ? "activeHeader" : ""
                }`}
              data-bs-toggle="dropdown"
              onClick={() => setShowOpenVenue(true)}
            >
              <i className="fa-solid fa-bolt me-2"></i>
              <span className="d-none d-lg-inline-flex">Open/Close Venue</span>
            </Link>
          </div> */}
        </div>

        <div className="d-flex align-items-center gap-3">
          <Dropdown className="nav-item d-sm-block d-none ">
            <Dropdown.Toggle
              id="dropdown-basic "
              className="nav-link grid_icon bg-transparent "
            >
              <i className="bi bi-grid-3x3-gap-fill"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className=" dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
                <Link
                  to=""
                  className="dropdown-item"
                  onClick={() => openInNewTab(AD_LOGIN_URL, "Atomic Venue Manager App")}
                >
                  Venue Manager App
                </Link>
                <Link
                  to=""
                  className="dropdown-item"
                  onClick={() => openInNewTab(ADMIN_APP_SWITCH_URL)}
                >
                  Venue Admin App
                </Link>
                <Link
                  to=""
                  className="dropdown-item"
                  onClick={() => openInNewTab(EMPLOYEE_APP_SWITCH_URL)}
                >
                  Account Management App
                </Link>

                {IS_LUXE == "false" ? (
                  <>
                    {" "}
                    <Link
                      to=""
                      className="dropdown-item"
                      onClick={() => openInNewTab(EVENT_APP_SWITCH_URL)}
                    >
                      Event Management App
                    </Link>
                    <Link
                      to=""
                      className="dropdown-item"
                      onClick={() => openInNewTab(FLOOR_APP_SWITCH_URL)}
                    >
                      Floor Management App
                    </Link>
                    <Link
                      to=""
                      className="dropdown-item"
                      onClick={() => openInNewTab(BUSSINESS_REPORTING_APP_SWITCH_URL)}
                    >
                      Bussiness Reporting App
                    </Link>

                    
                  </>
                ) : null}
              </div>
            </Dropdown.Menu>
          </Dropdown>

          <span className="mb-1">
            {profile && profile.FirstName != undefined
              ? profile.FirstName + " " + profile.LastName
              : ""}
          </span>
          {/* {profile && profile.StaffRoleId != undefined
            ? roles[profile.StaffRoleId]
            : ""} */}
          <ProfileDropdown profile={profile} />
          <PauseVenue setFalse={setFalse} show={showPauseVenue} />
          <OpenCloseVenue setFalse={setFalse} show={showOpenVenue} />
        </div>
      </nav>
    </>
  );
};

export default Header;

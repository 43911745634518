import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { VENUE_GUID } from '../../../constants';
import moment from 'moment-timezone';
import PhoneNumberFormat from '../../CommonComponents/PhoneNumberFormat';
import ThreeDotsLoader from '../../../views/ThreeDotsLoader/ThreeDotsLoader';

const Events = ({bayGuid, dataService}) => {
    const [events, setEvents] = useState([]);
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(true);
        dataService
          .getBayUpcomingEventDetails({
            VenueGuid: VENUE_GUID,
            BayGuid: bayGuid
          })
          .then((response) => {
            if (response.data.status === "success") {
                setEvents(response.data.response);
            }
            setShow(false);
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }, []);

    


    return (
        <>
        {show ? (
            <ThreeDotsLoader />
          ) : (
        <div>
            {
                events.length > 0 ? events.map((event) => {
                    return <><h5 className='fw_medium'>{event.Name}</h5>
                    <Row className='mt-4 events'>
                        <Col md="6">
                            <h6>Event Time</h6>
                            <div className='mt-2' >
                                <p>{moment(event.EventStartTime, "HH:mm:ss").format("h:mm A")} - {moment(event.EventEndTime, "HH:mm:ss").format("h:mm A")}</p>
                                <span>{moment(event.EventStartTimeWithSetupTime).format("DD MMM YYYY")}</span>
                            </div>
                        </Col>
                        <Col md="3">
                            <h6>Set Up Time</h6>
                            <div className='mt-2' >
                                <p>{event.SetupTime}</p>
                                <span>Start-{moment(event.EventStartTimeWithSetupTime).format("h:mm A")}</span>
                            </div>
                        </Col>
                        <Col md="3">
                            <h6>Tear Down Time</h6>
                            <div className='mt-2' >
                                <p>{event.TeardownTime}</p>
                                <span>End- {moment(event.EventEndTimeWithTeardownTime).format("h:mm A")}</span>
                            </div>
                        </Col>
        
                    </Row>
                    <Row className='mt-4 events'>
                        <Col md="6">
                            <h6>Contact</h6>
                            <div className='mt-2' >
                                <p>{event.ContactName}</p>
                                <span><PhoneNumberFormat phone={event.ContactPhone} /></span><br /><span>{event.ContactEmail}</span>
                            </div>
                        </Col>
                    </Row>
                    <hr></hr></>
                }) : <p>No events scheduled on this bay for today.</p>
            }
            
            
        </div>
    )}
    </>
    )
}

export default Events
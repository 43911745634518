import { API_URL, CLASSIC_API_URL, VENUE_GUID, appInfo } from "../constants";
import api from "./api";
import axios from "axios";
import tokenService from "./token.service";

class DataService {
  app_info = appInfo;

  async getBayDetails(payload = {}) {
    return api.post(API_URL + "bays-module/get-bay-details", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async getGeneric(payload = {}) {
    return api.post(API_URL + "generic-module/get-generic", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async insertGeneric(payload = {}) {
    return api.post(API_URL + "generic-module/insert-generic", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async updateGeneric(payload = {}) {
    return api.post(API_URL + "generic-module/update-generic", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async findByPhone(payload = {}) {
    return api.put(API_URL + "employees-module/find-by-phone", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async getPlayersInBay(payload = {}) {
    return api.post(API_URL + "employees-module/get-players-in-bay", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async insertToWaitList(payload = {}, uuid = null) {
    return api.post(
      CLASSIC_API_URL + "waitlist-module/insert-waitlist",
      payload, { headers: {      "TransactionGUID": uuid } }
    );
    /* return api.post(API_URL + "waitlist-module/insert-waitlist", {
      request: payload,
      app_info: this.app_info,
    }); */
  }

  async lockBay(payload = {}) {
    /* return api.post(API_URL + "bays-module/lock-bay", {
      request: payload,
      app_info: this.app_info,
    }); */
    return api.post(CLASSIC_API_URL + "bays-module/get-lock", payload);
  }

  async unLockBay(payload = {}) {
    /* return api.post(API_URL + "bays-module/unlock-bay", {
      request: payload,
      app_info: this.app_info,
    }); */
    return api.post(CLASSIC_API_URL + "bays-module/release-lock", payload);
  }

  async extentBayLock(payload = {}) {
    /* return api.post(API_URL + "bays-module/unlock-bay", {
      request: payload,
      app_info: this.app_info,
    }); */
    return api.post(CLASSIC_API_URL + "bays-module/extend-lock", payload);
  }

  async changeServiceRequest(payload = {}) {
    /* return api.post(API_URL + "bays-module/change-service-request", {
      request: payload,
      app_info: this.app_info,
    }); */

    return api.post(CLASSIC_API_URL + "unity-module/update-bay-flag", payload);
  }
  async changeOrderRequest(payload = {}) {
    /* return api.post(API_URL + "bays-module/change-out-of-order", {
      request: payload,
      app_info: this.app_info,
    }); */

    return api.post(CLASSIC_API_URL + "unity-module/update-bay-flag", payload);
  }

  async changeDisAllowAdditionTimeRequest(payload = {}) {
    /* return api.post(API_URL + "bays-module/change-disallow-additional-time", {
      request: payload,
      app_info: this.app_info,
    }); */

    return api.post(CLASSIC_API_URL + "unity-module/update-bay-flag", payload);
  }

  async changePauseRequest(payload = {}) {
    /* return api.post(API_URL + "bays-module/change-pause", {
      request: payload,
      app_info: this.app_info,
    }); */

    return api.post(CLASSIC_API_URL + "unity-module/update-bay-flag", payload);
  }

  /* async changeDisAllowAdditionTimeRequest(payload = {})
  {
    return api.post(API_URL + "bays-module/change-disallow-additional-time", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async changeDisAllowAdditionTimeRequest(payload = {})
  {
    return api.post(API_URL + "bays-module/change-disallow-additional-time", {
      request: payload,
      app_info: this.app_info,
    });
  } */

  async transferBay(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "bays-module/transfer-bay",
      payload
    );
    /* return api.post(API_URL + "bays-module/transfer-bay", {
      request: payload,
      app_info: this.app_info,
    }); */
  }

  async cancelAssignment(payload = {}) {
    return api.post(CLASSIC_API_URL + "bays-module/cancel-assignment", payload);

    /* return api.post(API_URL + "bays-module/cancel-assignment", {
      request: payload,
      app_info: this.app_info,
    }); */
  }

  async assignBayFromWaitList(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "waitlist-module/assign-bay-from-waitlist2",
      payload
    );
    /*  return api.post(API_URL + "waitlist-module/assign-bay-from-waitlist", {
      request: payload,
      app_info: this.app_info,
    }); */
  }

  async getGuestInfoInBay(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "bays-module/get-guest-info-in-bay",
      payload
    );
    /*  return api.post(API_URL + "bays-module/get-guest-info-in-bay", {
       request: payload,
       app_info: this.app_info,
     }); */
  }

  async getDayPricing(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "pricing-module/get-price-for-date",
      payload
    );
    /* return api.post(API_URL + "pricing-module/get-price-for-date", {
      request: payload,
      app_info: this.app_info,
    }); */
  }

  async getSessionPricing(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "pricing-module/get-price-by-session-length",
      payload
    );
    /* return api.post(API_URL + "pricing-module/get-price-by-session-length", {
      request: payload,
      app_info: this.app_info,
    }); */
  }

  async getBays(payload = {}) {
    return api.post(CLASSIC_API_URL + "bays-module/get-bays", payload);
  }

  async getEquipments(payload = {}) {
    return api.post(CLASSIC_API_URL + "bays-module/get-equipments", payload);
  }

  async getNotes(payload = {}) {
    return api.post(CLASSIC_API_URL + "bays-module/get-notes", payload);
  }

  async updateNotes(payload = {}) {
    return api.post(CLASSIC_API_URL + "bays-module/update-notes", payload);
  }

  async insertNotes(payload = {}, uuid = null) {
    return api.post(CLASSIC_API_URL + "bays-module/insert-notes", payload, { headers: {      "TransactionGUID": uuid } });
  }

  async getAvatar(payload = {}) {
    return api.post(CLASSIC_API_URL + "employees-module/get-avatars", payload);
  }

  async getEmployee(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "employees-module/get-employees",
      payload
    );
  }
  async getEmployeeByRowGuid(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "employees-module/get-employee-details",
      payload
    );
  }

  async updateEmployee(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "employees-module/update-employee-avatar",
      payload
    );
  }

  async insertIntakeAndAssignBay(payload = {}, uuid = null) {
    return api.post(
      CLASSIC_API_URL + "waitlist-module/insert-intake-and-assign-bay",
      payload, { headers: {      "TransactionGUID": uuid } }
    );
  }

  async insertIntake(payload = {}, uuid = null) {
    return api.post(CLASSIC_API_URL + "waitlist-module/insert-intake", payload, { headers: {      "TransactionGUID": uuid } });
  }

  async newAssignment(payload = {}, uuid = null) {
    return api.post(
      CLASSIC_API_URL + "bays-module/new-assignment-request",
      payload, { headers: {      "TransactionGUID": uuid } }
    );
  }

  async addAssignmentRequest(payload = {}, uuid = null) {
    return api.post(
      CLASSIC_API_URL + "bays-module/add-assignment-request",
      payload, { headers: {      "TransactionGUID": uuid } }
    );
  }

  async getAppAccess(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "appsecurity-module/get-app-access",
      payload
    );
  }

  async getAppAccessAwait(payload = {}) {
    const res = await api.post(
      CLASSIC_API_URL + "appsecurity-module/get-app-access",
      payload
    );
    return res;
  }

  async getAppAccessSingle(payload = {}) {
    return api.post(CLASSIC_API_URL + "appsecurity-module/get-app-access", payload);
  }

  async changeVenuePause(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "bays-module/change-venue-pause",
      payload
    );
  }

  async openVenue(payload = {}) {
    return api.post(CLASSIC_API_URL + "bays-module/venue-open", payload);
  }

  async closeVenue(payload = {}) {
    return api.post(CLASSIC_API_URL + "bays-module/venue-close", payload);
  }

  async getIntakeDetails(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "waitlist-module/get-intake-info",
      payload
    );
  }

  async getSignalRToken() {
    return api.post(
      CLASSIC_API_URL +
        "flite-hub-module/platform/negotiate?UserId=" +
        VENUE_GUID +
        "&ConnectionType=reactDemo&ApplicationType=reactDemo&negotiateVersion=1"
    );
  }

  async updateBayFlag(payload = {}) {
    return api.post(CLASSIC_API_URL + "unity-module/update-bay-flag", payload);
  }

  async setBayState(payload = {}) {
    return api.post(CLASSIC_API_URL + "unity-module/set-bay-state", payload);
  }

  async archiveNotes(payload = {}) {
    return api.post(CLASSIC_API_URL + "bays-module/archive-notes", payload);
  }

  async getVenue(payload = {}) {
    return api.post(CLASSIC_API_URL + "unity-module/get-hours-for-date", payload);
  }

  async getVenuePauseStatus(payload = {}) {
    return api.post(CLASSIC_API_URL + "bays-module/get-venue-pause-status", payload);
  }

   async findByEmail(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "employees-module/find-by-email",
      payload
    );
  }

  async getPlayersByPhone(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "players-module/get-players-by-phone",
      payload
    );
  }

  async updateInTake(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "waitlist-module/update-intake",
      payload
    );
  }

  async deleteInTake(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "waitlist-module/delete-intake",
      payload
    );
  }

  async getReservations(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "sevenroom-module/get-reservations",
      payload
    );
  }

  async useReservations(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "sevenroom-module/use-reservation",
      payload
    );
  }

  async getVenueDateTime(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "appsecurity-module/get-effective-venue-date",
      payload
    );
  }

  async getEventDetails(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "events-module/get-event-details",
      payload
    );
  }

  async getTodaysReservations(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "sevenroom-module/get-todays-reservations",
      payload
    );
  }

  async cancelFromIntake(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "waitlist-module/cancel-from-intake",
      payload
    );
  }

  async revertCancellation(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "waitlist-module/revert-cancellation",
      payload
    );
  }

  async getTimeForNextEventInbay(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "waitlist-module/get-time-for-next-event-in-bay",
      payload
    );
  }

  async getBayUpcomingEventDetails(payload = {}) {
    return api.post(
      CLASSIC_API_URL + "bays-module/get-todays-upcoming-events-in-bay",
      payload
    );
  }


}

export default new DataService();

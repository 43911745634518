import React from "react";
import { Offcanvas } from "react-bootstrap";

const CancelWaitList = ({ showWaitList, setShowWaitList, content }) => {
  return (
    <Offcanvas
      show={showWaitList.canvas}
      onHide={() => setShowWaitList({ ...showWaitList, canvas: false })}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Remove from waitlist</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>{content}</Offcanvas.Body>
    </Offcanvas>
  );
};

export default CancelWaitList;

import React, { useEffect, useRef } from "react"
import $, { map } from 'jquery'
import Table from 'react-bootstrap/Table';
import tokenService from "../../../apiServices/token.service";

import { useSelector, useDispatch } from 'react-redux';
import 'datatables.net-buttons'
import { msalInstance } from "../../..";
import authService from "../../../apiServices/auth.service";
import { LOGOUT_VARNAME } from "../../../constants";

const DataTable = (props) => {
    $.DataTable = require('datatables.net')
    
    const tableRef = useRef()
    // console.log(tableRef)
    const tableName = props.id;

    let table;

    const reload = useSelector((state) => state.datatableReload.reload)
    
    const headers = { // Define your headers here
        "Authorization": "Bearer " + tokenService.getLocalAccessToken(),
        // Add more headers if needed
    };

    useEffect(() => {
        table = $(`#${tableName}`).DataTable(
            {
                /* "beforeSend": function(xhr){
                    console.log("first")
                    xhr.setRequestHeader("Authorization",
                       "Bearer " + tokenService.getLocalAccessToken());
                 }, */
                processing: true,
                serverSide: true,
                "bSortCellsTop": true,
                "bAutoWidth": false,
                /* lengthMenu: [
                    [1, 2, 3, -1],
                    [1, 2, 3, 'All'],
                ], */
                ajax: {
                    url: props.url.url,
                    type: "GET",
                    headers: headers, // Pass the headers here
                    "error": function(xhr, error, thrown) {
                        if(xhr.status == 401)
                        {
                            msalInstance.logoutRedirect({
                                postLogoutRedirectUri: "/login",
                                account: msalInstance.getActiveAccount(),
                                onRedirectNavigate: () => {
                                  localStorage.setItem(LOGOUT_VARNAME, Date.now().toString());
                                  authService.logout();
                                  window.location.replace("/login")
                                  return false;
                                },
                              });
                        }
                    }
                },
                columns: props.columns,
                columnDefs: props.columnDefs,
                destroy: true,
                dom: 'Bfrltip',
                order: typeof props.order == "undefined"  ? [[0, 'desc']] : props.order ,
                //order: false ,
                /* "bStateSave": true,
                "fnStateSave": function (oSettings, oData) {
                    localStorage.setItem('offersDataTables', JSON.stringify(oData));
                },
                "fnStateLoad": function (oSettings) {
                    return JSON.parse(localStorage.getItem('offersDataTables'));
                }, */
                buttons: [
                    {
                        text: 'Refresh',
                        action: function () {
                            table.ajax.reload(null, false);
                        },
                        className: "btn btn-primary mb-3 btn-sm fw_medium"
                    }
                ],
            }
        );

        $(`#${tableName} thead td`).each(function (index, value) {

            var title = $(`#${tableName} thead td`).eq(props.columns[$(this).index()]).title;

            var searchable = props.columns[$(this).index()].searchable;
            var placeholder = props.columns[$(this).index()].placeholder;

            if (title != '' && searchable === true) {
                $(this).html('<input aria-label="search ' + title + '" class="txtSearch2" type="text" placeholder="' + placeholder + '"y data-column="' + index + '"/>');
            } else {
                $(this).html('');
            }
        });

        var state = table.state.loaded();
        if (state) {
            table.columns().eq(0).each(function (colIdx) {
                var colSearch = state.columns[colIdx].search;

                if (colSearch.search) {
                    $('input', $(`#${tableName} thead td`)[colIdx]).val(colSearch.search);
                }
            });
           
            //table.draw();
           
        }
       
        $('.txtSearch2').on('keyup', function () {
            var colnum1 = $(this).attr('data-column');
            $(`#${tableName}`)
                .DataTable()
                .column(colnum1)
                .search($(this).val(), false, true)
                .draw();
        });


        return function () {
            table.destroy();
        }
        // Extra step to do extra clean-up.


    }, [reload])




    return (
        <div>
            
            <Table className="display" width="100%" id={tableName} ref={tableRef}>
            
                <thead className="bg_sky_blue">
                    <tr >
                        {
                            props.columns.map(function (data, index) {
                                return <th key={index} scope="col" className=" m-70 ">{data.title}</th>
                            })
                        }
                    </tr>
                    <tr>
                        {
                            props.columns.map(function (data, index) {
                                return <td key={index} scope="col" className=" m-70 ">{data.title}</td>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </Table>
        </div>

    )
}

export default DataTable
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "./MenuIcon/MenuIcon";
import { Button, FormControl } from "react-bootstrap";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
import { useSelector, useDispatch } from "react-redux";
import {
  filterBaysByState,
  filterBaysByFloor,
  filterBaysByType,
  filterBaysByOrientation,
  bayFilters,
} from "../../features/baysSlice";
import { APP_LOGO, IS_LUXE } from "../../constants";
import moment from "moment-timezone";
import { getVenueCloseTime } from "../../commonFunctions";

const state = () => {
  const dispatch = useDispatch();

  const {
    assignable_count,
    bay_state_counts,
    bay_states,
    canon_bay_filters,
    putting_bay_filters,
    all_state,
    bay_filters,
  } = useSelector((state) => state.bays);

  const onStateFilterChange = (e) => {
    const { value, checked } = e.target;

    if (!checked) {
      dispatch(
        bayFilters({ value: value.toString(), type: "remove", action: "state", page: (IS_LUXE == "false") ? location.pathname : null })
      );
    } else {
      dispatch(
        bayFilters({ value: value.toString(), type: "add", action: "state", page: (IS_LUXE == "false") ? location.pathname : null })
      );
    }
  };

  const addCss = (data) => {
    const bayCss = {
      backgroundColor: data.FillColor ? data.FillColor : "#d5d3cd",
      color: data.TextColor ? data.TextColor : "#000",
      borderColor: data.BorderColor ? data.BorderColor : "#d5d3cd",
    };

    return bayCss;
  };

  const checkCheckBoxes = (v) => {




   return (location.pathname == "/putting-bays") ? putting_bay_filters.BayStateId.length > 0
    ? putting_bay_filters.BayStateId.some((el) => {
        return el == v.BayStateId;
      })
    : false : (location.pathname == "/canon-bays") ? canon_bay_filters.BayStateId.length > 0
    ? canon_bay_filters.BayStateId.some((el) => {
        return el == v.BayStateId;
      })
    : false : bay_filters.BayStateId.length > 0
    ? bay_filters.BayStateId.some((el) => {
        return el == v.BayStateId;
      })
    : false



    
  } 

  return (
    <ul
      className="nav state flex-column sub-menu"
      id="accordionPanelsStayOpenExample"
    >
      {/* <li className="nav-item">
        <div className="form-check d-flex justify-content-between">
          <div>
            <FormControl
              className="form-check-input sidebar_checkbox"
              type="checkbox"
              value='all'
              name="group1"
              id="flexCheckDefault"
              onChange={onStateFilterChange}

            />
            <FormCheckLabel
              className="form-check-label text-white"
              htmlFor="flexCheckDefault"
            >
              All
            </FormCheckLabel>
          </div>
          <div className="pill bg_white  ">
            <span className="text-dark">{all_state}</span>
          </div>
        </div>
      </li> */}

      {Object.values(bay_states).map((v, i) => {
        return (
          <li className="nav-item" key={i}>
            <div className="form-check d-flex justify-content-between">
              <div className="checkbox-wrapper">
                <FormControl
                  className="form-check-input sidebar_checkbox"
                  type="checkbox"
                  name="group1"
                  value={v.BayStateId}
                  onChange={onStateFilterChange}
                  id={`flexCheckDefault${v.BayStateId}`}
                  checked={
                    checkCheckBoxes(v)
                  }
                />
                <FormCheckLabel
                  className="form-check-label text-white"
                  htmlFor={`flexCheckDefault${v.BayStateId}`}
                >
                  {v.BayStateName}
                </FormCheckLabel>
              </div>
              <div className="pill bg_orange circle_solid " style={addCss(v)}>
                <span>
                  { (bay_state_counts && bay_state_counts[v.BayStateId])
                    ? bay_state_counts[v.BayStateId]
                    : 0}
                </span>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
const floor = () => {
  const dispatch = useDispatch();
  const { floors, bay_filters } = useSelector((state) => state.bays);

  const onFloorFilterChange = (e) => {
    const { value, checked } = e.target;
    if (!checked) {
      dispatch(bayFilters({ value: value, type: "remove", action: "Floor" }));
    } else {
      dispatch(bayFilters({ value: value, type: "add", action: "Floor" }));
    }
  };

  return (
    <ul className="nav flex-column sub-menu">
      {/* <li className="nav-item">
        <div className="form-check">
          <FormControl
            className="form-check-input sidebar_checkbox sidebar_checkbox p-0"
            type="checkbox"
            value='all'
            name="group1"
            onChange={onFloorFilterChange}
            id="flexCheckDefaultFloorAll"
          />
          <FormCheckLabel
            className="form-check-label text-white"
            htmlFor="flexCheckDefaultFloorAll"
          >
            All
          </FormCheckLabel>
        </div>
      </li> */}
      {Object.values(floors).map((v, i) => {
        return v.Floor != 5 ? (
          <li className="nav-item" key={i}>
            <div className="form-check d-flex justify-content-between">
              <div  className="checkbox-wrapper">
                <FormControl
                  className="form-check-input sidebar_checkbox"
                  type="checkbox"
                  name="group1"
                  value={v.Floor}
                  onChange={onFloorFilterChange}
                  checked={
                    bay_filters.Floor.length > 0
                      ? bay_filters.Floor.some((el) => {
                          return el == v.Floor;
                        })
                      : false
                  }
                  id={`flexCheckDefaultFloor${v.Floor}`}
                />
                <FormCheckLabel
                  className="form-check-label text-white"
                  htmlFor={`flexCheckDefaultFloor${v.Floor}`}
                >
                  Floor {v.Floor}
                </FormCheckLabel>
              </div>
            </div>
          </li>
        ) : null;
      })}
    </ul>
  );
};
const bayType = () => {
  const { bay_types, bay_filters } = useSelector((state) => state.bays);

  const dispatch = useDispatch();

  const onBayTypeChange = (e) => {
    const { value, checked } = e.target;
    if (!checked) {
      dispatch(bayFilters({ value: value, type: "remove", action: "type" }));
    } else {
      dispatch(bayFilters({ value: value, type: "add", action: "type" }));
    }
  };


  return (
    <ul className="nav flex-column sub-menu">
      {/* <li className="nav-item">
        <div className="form-check d-flex justify-content-between">
          <div>
            <FormControl
              className="form-check-input sidebar_checkbox"
              type="checkbox"
              value='all'
              name="group1"
              id="flexCheckDefaultTypesAll"
              onChange={onBayTypeChange}
            />
            <FormCheckLabel
              className="form-check-label text-white"
              htmlFor="flexCheckDefaultTypesAll"
            >
              All
            </FormCheckLabel>
          </div>

        </div>
      </li>
 */}

      {Object.values(bay_types).map((v, i) => {
        return (
          <li className="nav-item" key={i}>
            <div className="form-check d-flex justify-content-between">
              <div>
                <FormControl
                  className="form-check-input sidebar_checkbox"
                  type="checkbox"
                  name="group1"
                  value={v.BayTypeId}
                  onChange={onBayTypeChange}
                  id={`flexCheckDefaultTypes${v.BayTypeId}`}
                  checked={
                    bay_filters.BayTypeId.length > 0
                      ? bay_filters.BayTypeId.some((el) => {
                          return el == v.BayTypeId;
                        })
                      : false
                  }
                />
                <FormCheckLabel
                  className="form-check-label text-white"
                  htmlFor={`flexCheckDefaultTypes${v.BayTypeId}`}
                >
                  {v.BayTypeName}
                </FormCheckLabel>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
const orientation = () => {
  const { handedness } = useSelector((state) => state.bays);

  const dispatch = useDispatch();

  const onBayOrientationChange = (e) => {
    const { value, checked } = e.target;
    if (!checked) {
      dispatch(filterBaysByOrientation("all"));
    } else {
      dispatch(filterBaysByOrientation(value));
    }
  };

  return (
    <ul className="nav flex-column sub-menu">
      {/* <li className="nav-item">
        <div className="form-check d-flex justify-content-between">
          <div>
            <FormControl
              className="form-check-input sidebar_checkbox"
              type="checkbox"
              value='all'
              name="group1"
              id="flexCheckDefaultOrientation"
              onChange={onBayOrientationChange}
            />
            <FormCheckLabel
              className="form-check-label text-white"
              htmlFor="flexCheckDefaultOrientation"
            >
              All
            </FormCheckLabel>
          </div>

        </div>
      </li> */}
      {Object.values(handedness).map((v, i) => {
        return (
          <li className="nav-item" key={i}>
            <div className="form-check d-flex justify-content-between">
              <div>
                <FormControl
                  className="form-check-input sidebar_checkbox"
                  type="checkbox"
                  name="group1"
                  value={v.headedness_id}
                  onChange={onBayOrientationChange}
                  id={`flexCheckDefaultOrientation${v.headedness_id}`}
                />
                <FormCheckLabel
                  className="form-check-label text-white"
                  htmlFor={`flexCheckDefaultOrientation${v.headedness_id}`}
                >
                  {v.handedness_description}
                </FormCheckLabel>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
const Siderbar = ({ active, venueData }) => {
  const { venue } = useSelector((state) => state.venues);
  const { venueDateTime } = useSelector((state) => state.venueDateTime);

  const showRemaingTime = (openTime, closeTime, OpenCloseStatus) => {
    const hm = getVenueCloseTime(openTime, closeTime);
    
    if (hm.hour < 0 && hm.minutes < 0) {
    } else {
      return (
        <p className="fw-bold mt-3">
          Venue Closes in {hm.hour}h {hm.minutes}m
        </p>
      );
    }
  };
  return (
    <>
      <div className={`sidebar  pb-3 ${active ? "open" : ""}`}>
        <nav className="navbar d-block">
          <div className="w-100    menu_icon_box pb-3">
            <Link
              to="/"
              className="navbar-brand mx-4 mb-3 d-block text-center "
            >
              <img src={`../img/${APP_LOGO}`} alt="" className="img-fluid" />
            </Link>
          </div>
          {venue.length > 0 ? (
            <div className="justify-content-between date align-items-center text-white menu_icon_box ">
              <div>
                <p className="mb-2">
                  {moment.utc(venueDateTime).format("DD MMM YYYY")}
                </p>
                <p className="mb-0">Today's Hours: </p>
                <p className="mb-0">
                  {moment(venue[0].StartTime, "HH:mm:ss").format("LT")} to{" "}
                  {moment(venue[0].EndTime, "HH:mm:ss").format("LT")}
                </p>
              </div>
              {/* {
                 !venue[0].OpenCloseStatus ? <div className='d-flex align-items-center mt-3'>
                 <i className="fa-solid fa-lock me-2"></i>
                 <p className='fw-bold '>Venue is Closed</p>
               </div> : null
               } */}

              <div></div>
            </div>
          ) : null}

          <div
            className="navbar-nav sidebar_nav w-100"
            id="accordionPanelsStayOpenExample"
          >
            <MenuIcon title="State" content={state()} />

            {IS_LUXE == "false" ? (
              ((location.pathname == "/putting-bays") || (location.pathname == "/canon-bays")) ? (
                <></>
              ) : (
                <>
                  {" "}
                  <MenuIcon title="Floor" content={floor()} />
                 {/*  <MenuIcon title="Bay Type" content={bayType()} /> */}
                </>
              )
            ) : (
              <>
                {" "}
                <MenuIcon title="Floor" content={floor()} />
                {/* <MenuIcon title="Bay Type" content={bayType()} /> */}
              </>
            )}

            {/* <MenuIcon title="Orientation" content={orientation()} /> */}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Siderbar;

// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React, { Suspense, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
/* import authService from '../apiServices/auth.service';
import { useDispatch, useSelector } from 'react-redux'; */
import authService from "../apiServices/auth.service";
import dataService from "../apiServices/data.service";
import { APP_GUID, VENUE_GUID } from "../constants";
import tokenService from "../apiServices/token.service";
import { toast } from "react-toastify";
import { ErrorBoundary } from "react-error-boundary";
import Error500 from "../views/Error/Error500";
const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAccess, setIsAccess] = useState(false);
  const auth = authService.isLoggedIn();

  useEffect(() => {

    dataService
        .getAppAccessAwait({
          VenueGuid: VENUE_GUID,
          PersonGuid: tokenService.getPersonGuid()
              ? tokenService.getPersonGuid()
              : "00000000-0000-0000-0000-000000000000",
          AppGuid: APP_GUID,
        })
        .then((res) => {
          if (res.data.status === "success") {
            const hasAccess = res.data.response?.[0]?.HasAccess;
            if (hasAccess !== false && hasAccess !== undefined) {
              setIsAccess(true);
            } else {
              setIsAccess(false);
              navigate("/access-denied");
            }
          } else {
            setIsAccess(false);
            navigate("/access-denied");
          }
        })
        .catch((err) => {
          // Handle error
          if (err.code === "ERR_BAD_REQUEST") {
            authService.logout();
            window.location.replace("/login");
          } else {
            toast.error(err.message);
          }
        });
  }, [location.pathname]);

  return isAccess && auth ? (
    <Suspense fallback={<div className="loader-container ">
    <div className="spinner"></div>
</div>}>
    <ErrorBoundary fallback={<Error500 />}>{children}</ErrorBoundary>
  </Suspense>
  
  ) : null;
};

export default PrivateRoute;

import React, { useState, useEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { millisToMinutesAndSeconds, slugify, timeDiffInMinutes } from '../../commonFunctions'
import moment from 'moment-timezone';
import { zeroPad } from 'react-countdown';
import IntakeBayBox from './IntakeBayBox';
import { DefaultIntakeBayFilters, IntakeBayFilters, getBays } from '../../features/baysSlice';
import { COUNT_DOWN_TIMER, INTAKE_COUNT_DOWN_TIMER, VENUE_GUID, bayType, floorData } from '../../constants';
import dataService from '../../apiServices/data.service';
import { toast } from 'react-toastify';
import { reloadTable } from '../../features/reloadDataTableSlice';
import tokenService from '../../apiServices/token.service';
import { confirmAlert } from 'react-confirm-alert';
import { v4 as uuidv4 } from 'uuid';

const BaysAvailable = ({ handleClose1, waitListGuid, intakeName, waitListData }) => {

  const [uuid, setUuid] = useState(uuidv4());

  const dispatch = useDispatch()
  const [selectedBay, setSelectedBay] = useState("")
  /* useEffect(() => {
    dispatch(getBays())
    
  }, [dispatch]) */

  const { allBays, intake_bay_filter, intake_bays, floors, loading, error, bay_states, bay_types } = useSelector((state) => state.bays)


  let inTakeBays = intake_bays;

  const renderer = ({ hours, minutes, seconds, completed }) => {

    if (minutes <= 10 && minutes > 1) {
      return <span>{zeroPad(minutes)}:00</span>;
    }

    if (minutes <= 1) {
      return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    }

  };


  const onIntakeFloorChecked = e => {
    const { value, checked } = e.target;
    if (!checked) {
      dispatch(IntakeBayFilters({ value: value, type: 'remove', action: 'floor', excludedBay: waitListData.pref_excluded_bays }))
    } else {
      dispatch(IntakeBayFilters({ value: value, type: 'add', action: 'floor', excludedBay: waitListData.pref_excluded_bays }))
    }
  };


  const onIntakeTypeChecked = e => {
    const { value, checked } = e.target;
    if (!checked) {
      dispatch(IntakeBayFilters({ value: value, type: 'remove', action: 'type', excludedBay: waitListData.pref_excluded_bays }))
    } else {
      dispatch(IntakeBayFilters({ value: value, type: 'add', action: 'type', excludedBay: waitListData.pref_excluded_bays }))
    }
  };


  const floorCollection = Object.values(floors);

  const bayTypeCollection = Object.values(bay_types);


  const setBayToGuest = (v) => {
    /* inTakeBays = inTakeBays.map(item => {
      item.is_deleted = 0
      if (item.row_guid == v) {
       item.is_deleted = 1
      }
      return item;
    }); */
    setSelectedBay(v);
  }

  const confirmBay = () => {

    dataService.getTimeForNextEventInbay({
      "BayGuid": selectedBay,
      "VenueGuid": VENUE_GUID
    }).then((res) => {
      if (res.data.status == "success") {
        if (res.data.response.NextEventTime == "" || res.data.response.TimeToNextEventInMinutes > 75) {
          dataService.newAssignment({
            "BayGuid": selectedBay,
            "IntakeGuid": waitListGuid,
            "VenueGuid": VENUE_GUID,
            "TimeOfNextEventInBay": res.data.response.NextEventTime
          }, uuid).then((res) => {
            if (res.data.status == "success") {
              toast.success("Bay Assigned.")
              setUuid(uuidv4())
              dataService.unLockBay({
                "VenueGuid": VENUE_GUID,
                "ResourceGuid": waitListGuid,
                "ResourceName": intakeName,
                "LockedBy": tokenService.getName(),
                "LockExpiryTime": 0,
                "ResourceType": "Intake"
              }).then((res) => {
                if (res.data.status != "success") {
                  toast.error(res.data.errorMessage)
                }
              }).catch((err) => {
                toast.error(err.message)
              })
              dispatch(reloadTable());
            } else {
              setUuid(uuidv4())
              toast.error(res.data.errorMessage)
              dispatch(reloadTable());
            }
          }).catch((err) => {
            setUuid(uuidv4())
            toast.error(err.message)
            dispatch(reloadTable());
          })

        }
      } else {
        dispatch(reloadTable());
        toast.error(res.data.errorMessage)
      }
    })







    handleClose1();
  }

  const [showExtentedPopup, setShowExtentedPopup] = useState(false)
  const [counterTime, setCounterTime] = useState(60000 * 1)

  const callGetIntakeDetails = (waitListGuid) => {
    dataService.getIntakeDetails({
      "VenueGuid": VENUE_GUID,
      "IntakeGuid": waitListGuid,
    }).then((res) => {
      if (res.data.status == "success") {
        if (res.data.response && res.data.response[0] && res.data.response[0].LockExpiryTime) {
          setCounterTime(timeDiffInMinutes(res.data.response[0].LockExpiryTime))
        }
      } else {
        toast.error(res.data.errorMessage)
      }
    }).catch((err) => {
      toast.error(err.message)
    })
  }

  useEffect(() => {

    if (waitListGuid) {
      callGetIntakeDetails(waitListGuid)
    }



  }, [waitListGuid]);


  useEffect(() => {
    const interval = setInterval(() => {
      if (counterTime > 0) {
        setCounterTime(counterTime - 1000);
      } else {
        /*   console.log(counterTime, typeof counterTime, COUNT_DOWN_TIMER*60000); */
        if (typeof counterTime !== null) {
          /*  console.log("1555"); */
          handleClose1()
          document.dispatchEvent(
            new KeyboardEvent("keydown", {
              key: "e",
              keyCode: 27, // example values.
              code: "KeyE", // put everything you need in this object.
              which: 27,
              shiftKey: false, // you don't need to include values
              ctrlKey: false,  // if you aren't going to use them.
              metaKey: false   // these are here for example's sake.
            })
          );
          setCounterTime(0);
        }


      }
    }, 1000);

    if (counterTime > 0 && counterTime < 30000) {
      setShowExtentedPopup(true)

      if (!showExtentedPopup) {
        confirmAlert({
          message: 'Your bay lock time is about to end. Would you like to extend the time?',
          buttons: [
            {
              label: 'Cancel',
              onClick: () => handleClose1()
            },
            {
              label: 'Extend Time',
              onClick: () => {
                dataService.extentBayLock({
                  "VenueGuid": VENUE_GUID,
                  "ResourceGuid": waitListGuid,
                  "ResourceName": intakeName,
                  "LockedBy": tokenService.getName(),
                  "LockExpiryTime": INTAKE_COUNT_DOWN_TIMER,
                  "ResourceType": "Intake"
                }).then((res) => {
                  if (res.data.status != "success") {
                    toast.error(res.data.errorMessage)
                  } else {
                    callGetIntakeDetails(waitListGuid)
                  }
                }).catch((err) => {
                  toast.error(err.message)
                })

              }
            }
          ]
        });
      }

    }

    return () => clearInterval(interval);
  }, [counterTime]);

  return (
    <>


      <Form>
        <Row>
          <Col><Row className='my-2'>
            <Col>
              <p>Preference{/*  {JSON.stringify(waitListData)}  */}</p>
            </Col>
          </Row>
            <div key={`floor-inline-checkbox`} className="mb-3 d-flex">
              {floorCollection.length > 0 ? floorCollection.map((tag, i) => (
                <Form.Check // prettier-ignore
                  type="checkbox"
                  id={`floor-inline-${tag.Floor}`}
                  key={i}
                  label={`Floor ${tag.Floor}`}
                  inline
                  value={tag.Floor}
                  defaultChecked={(waitListData.pref_floors && JSON.parse(waitListData.pref_floors).length > 0) ? (JSON.parse(waitListData.pref_floors)).some(el => {
                    return el == tag.Floor
                  }) : false}
                  onChange={e => {
                    if (e.target.checked) {
                      dispatch(IntakeBayFilters({ value: tag.Floor, type: 'add', action: 'Floor', excludedBay: waitListData.pref_excluded_bays }))
                    } else {
                      dispatch(IntakeBayFilters({ value: tag.Floor, type: 'remove', action: 'Floor', excludedBay: waitListData.pref_excluded_bays }))
                    }
                  }}
                />
              )) : null}
            </div>
            <div key={`type-checkbox`} className="mb-3 d-flex" >

              {
                bayTypeCollection.length > 0 ?
                  bayTypeCollection.map((tag, i) => (
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      id={`type-inline-${tag.BayTypeName}`}
                      label={tag.BayTypeName}
                      inline
                      key={i}
                      value={tag.BayTypeId}
                      defaultChecked={(waitListData.pref_bay_types && JSON.parse(waitListData.pref_bay_types).length > 0) ? (JSON.parse(waitListData.pref_bay_types)).some(el => {
                        return el == tag.BayTypeId
                      }) : false}

                      onChange={onIntakeTypeChecked}
                    />
                  )) : null}
            </div></Col>
          <Col className='text-end timer1 fw-bold'>
            <p key={counterTime}><i className="fa-regular fa-clock"></i>  &nbsp;{millisToMinutesAndSeconds(counterTime)}</p>
          </Col>
          <Col className='text-end' xs={1}></Col>
        </Row>

        <Row className="bay-wraper mt-3">

          {
            Object.values(floors).map((floor, i) => {
              return <div key={i}>
                <Col className="d-flex same_box_row row_gap flex-sm-wrap  mb-5 mt-3  align-items-start align-content-end ">

                  {
                    Object.values(inTakeBays).map((bay, i) => {
                      return (bay.Floor == floor.Floor) ? <IntakeBayBox
                        /* className={`bay-${bay.states.slug}`} */
                        className={`bay-${slugify(bay.BayStateName)}`}
                        statusTime={bay.BayStateSetOn}
                        number={bay.BayNumber}
                        status={slugify(bay.BayStateName)}
                        vip={bay.BayTypeId}
                        /* handler={() => handleShowOffcanvesTwo(bay.bay_number, floor.floor)} */
                        // handler={() => handleShowOffcanvesTwo()}
                        key={bay.BayGuid}
                        bay={bay}
                        moment={moment}
                        isDisable={bay.is_disable}
                        handler={setBayToGuest}
                      /> : null
                    })
                  }
                  <div className="same_line mt-2"></div>
                </Col>
              </div>
            })
          }

        </Row>
        <div className="modal-footer1   ">
          <Button type="button" className="btn btn-secondary fw_medium   ms-2 rounded-1   " variant=" " onClick={handleClose1}  >Cancel</Button>
          <Button type="button" className="  btn_primary   fw_medium   ms-2 rounded-1   " onClick={confirmBay}>Assign</Button>
        </div>
      </Form>
    </>

  )
}

export default BaysAvailable
import React, { useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import RequiredComponents from "../CommonComponents/RequiredComponents/RequiredComponents";
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "react-phone-number-input";
import { VENUE_GUID } from "../../constants";
import dataService from "../../apiServices/data.service";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ThreeDotsLoader from "../../views/ThreeDotsLoader/ThreeDotsLoader";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import {
  disableNewAssignmentBtn,
  disableTransferAssignmentBtn,
  enableCancelAssignmentBtn,
} from "../../features/reloadDataTableSlice";

const NewAssigment = ({
  handleCloseOffcanvesTwo,
  tab,
  setTab,
  bay,
  bayNumber,
  bayCss,
  bayStateName,
  showFlagUi,
  showFlagCount,
  handleClose3,
}) => {
  const dispatch = useDispatch();

  const [guestList, setGuestList] = useState([]);
  const [uuid, setUuid] = useState(uuidv4());

  const [value, setValue] = useState();

  const [pin, setPin] = useState("XXXX");

  const [users, setUsers] = useState([]);

  const [btnIsDisable, setBtnIsDisable] = useState(false);

  const labelCss = {
    color: bay.IsEvent ? "#F57522" : bay.LabelColor ? bay.LabelColor : null,
  };

  const handleValidate = (value) => {
    const isValid = isValidPhoneNumber("+" + value);

    return isValid;
  };

  const validationSchema = Yup.object().shape(
    {
      Name: Yup.string().trim().required("Please enter name."),
      GroupSize: Yup.number()
        .positive()
        .max(8, "The group size cannot exceed 8")
        .typeError("group size must be a number.")
        .required("Please enter group size."),
      Phone: Yup.string()
        .required()
        .when(["Phone"], (value, schema) => {
          if (Number(value)) {
            return schema.required();
          }
          return schema;
        })
        .test("Phone", "Phone number is invalid.", (value, ref) => {
          return handleValidate(value);
        }),
    },
    [["Phone", "Phone"]]
  );

  const formik = useFormik({
    initialValues: {
      Name: "",
      GroupSize: "",
      Priority: 0,
      IsFastPass: 0,
      PrefFloors: [],
      PrefBayTypes: [],
      Phone: "",
      BayGuid: bay.BayGuid,
      VenueGuid: VENUE_GUID,
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      setBtnIsDisable(true);

      dataService
        .getTimeForNextEventInbay({
          BayGuid: values.BayGuid,
          VenueGuid: VENUE_GUID,
        })
        .then((res) => {
          if (res.data.status == "success") {
            if (
              res.data.response.NextEventTime == "" ||
              res.data.response.TimeToNextEventInMinutes > 75
            ) {
              values.TimeOfNextEventInBay = res.data.response.NextEventTime;
              dataService
                .insertIntakeAndAssignBay(values, uuid)
                .then((res) => {
                  if (res.data.status == "success") {
                    toast.success("Bay assigned.");
                    setPin(res.data.response.Pin);
                    setTab(2);
                    dispatch(disableNewAssignmentBtn());
                    dispatch(disableTransferAssignmentBtn());
                    dispatch(enableCancelAssignmentBtn());
                  } else {
                    setUuid(uuidv4());
                    toast.error(res.data.errorMessage);
                  }
                  setBtnIsDisable(false);
                  setUuid(uuidv4());
                })
                .catch((err) => {
                  setBtnIsDisable(false);
                  setUuid(uuidv4());

                  toast.error(err.message);
                });
            }
          } else {
            toast.error(res.data.errorMessage);
          }
        })
        .catch((err) => {
          setUuid(uuidv4());
          toast.error(err.Message);
        })
        .finally(() => {
          //setBtnIsDisable(false)
        });

      //setTab(3)
    },
  });

  const onValueChange = (phoneNumber) => {
    formik.setFieldValue("Phone", phoneNumber);
  };

  const validationSchemaSearch = Yup.object().shape(
    {
      Phone: Yup.string()
        .trim()
        .required()
        .when(["Phone"], (value, schema) => {
          if (Number(value)) {
            return schema.required();
          }
          return schema;
        })
        .test("Phone", "Phone number is invalid.", (value, ref) => {
          return handleValidate(value);
        }),
    },
    [["Phone", "Phone"]]
  );

  const formikSearch = useFormik({
    initialValues: {
      Phone: "",
      VenueGuid: VENUE_GUID,
    },
    validationSchema: validationSchemaSearch,
    validateOnChange: true,
    onSubmit: (values) => {
      dataService
        .findByPhone(values)
        .then((res) => {
          if (res.data.status === "success") {
            setUsers(res.data.response);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
  });

  const onValueChangeSearch = (phoneNumber) => {
    formikSearch.setFieldValue("Phone", phoneNumber);
  };

  return (
    <>
      {/* {tab === 1 && (
        <>
          <Form onSubmit={formikSearch.handleSubmit} >
            <Row className="pe-4 ps-2 row_gap">
              <Col lg={6}>
                <Form.Label>Phone <RequiredComponents /></Form.Label>
                <PhoneInput
                  inputProps={{
                    name: 'phone', // should match with the schema and initialValues
                  }}
                  disableDropdown={true}
                  onChange={onValueChangeSearch} // 👈
                  specialLabel={false}
                  value={value}
                  placeholder="000 000-0000"
                  country='us'
                  disableCountryCode={true}
                  onlyCountries={['us']}
                  id="phone-input-search"
                />
                {formikSearch.touched.phone && formikSearch.errors.phone ? (
                  <div className='custom-invalid-feedback'>{formikSearch.errors.phone}</div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xs={4} className="text-end ms-auto mt-3">
                <Button type='submit' className="bg_theme_primary px-4 fw_medium w-100">
                  Find
                </Button>
              </Col>
            </Row>


          </Form>
          <Row className="mt-4 row_gap">
            <Col xs={12}>
              <div className="table-responsive mt-4">
                <Table>
                  <thead>
                    <tr className="bg_sky_blue">
                      <th></th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      users.length > 0 ?
                        users.map((user, i) => {
                          return <tr>
                            <td></td>
                            <td>{user.name}</td>
                            <td>{user.phone}</td>
                            <td className="text-end">
                              {' '}
                              <Button type="button" variant="light">
                                Assign
                              </Button>
                            </td>
                          </tr>
                        })
                        :
                        <tr>
                          <td colSpan={4}>
                            <div className='custom-invalid-feedback text-center'>No data found.</div>
                          </td>
                        </tr>

                    }


                  </tbody>
                </Table>
              </div>
            </Col>
            <Col xs={5} className="ms-auto">
              <Button className="bg_theme_primary w-100 fw_medium py-2" onClick={() => setTab(2)}>
                Add New Guest
              </Button>
            </Col>
          </Row>
        </>
      )} */}
      {tab === 1 && (
        <>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col sm={4} xs={6} className="box_details_col me-4">
                <div className="bay-d-wid">
                  <div
                    className={`bay-card-box  ${
                      bay.IsServiceRequest ? "service-requested" : null
                    }`}
                    style={bayCss}
                  >
                    <div className="bay-divider">
                      <div className="d-flex align-items-center justify-content-center sec1">
                        <span className="fw-bold ">{bay.BayNumber}</span>
                      </div>
                      {showFlagUi()}
                    </div>
                  </div>
                  <div className="bay-card-notifcation-offcanvas">
                    {showFlagCount()}
                  </div>
                  <div
                    className="bay-card-status text-uppercase w-100 mb-3"
                    style={labelCss}
                  >
                    {bayStateName}
                  </div>
                </div>
                {/* <div className="ms-4">
                    {
                      (guestList.length > 0) ? <h4>Pin - {guestList[0].Pin}</h4> : null
                    }
                  </div> */}
              </Col>
              <Col md="7">
                <Row className="row_gap  ">
                  <Col md={6}>
                    <Form.Group controlId="formName">
                      <Form.Label>
                        {" "}
                        Name <RequiredComponents />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="Name"
                        placeholder="Name"
                        onChange={formik.handleChange}
                      />
                      {formik.touched.Name && formik.errors.Name ? (
                        <div className="custom-invalid-feedback">
                          {formik.errors.Name}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Label>
                      Phone <RequiredComponents />
                    </Form.Label>
                    <PhoneInput
                      inputProps={{
                        name: "Phone", // should match with the schema and initialValues
                      }}
                      disableDropdown={true}
                      onChange={onValueChange} // 👈
                      specialLabel={false}
                      value={value}
                      placeholder="000 000-0000"
                      /*   disableCountryCode={true}
                      onlyCountries={["us"]} */
                      id="phone-input"
                    />
                    {formik.touched.Phone && formik.errors.Phone ? (
                      <div className="custom-invalid-feedback">
                        {formik.errors.Phone}
                      </div>
                    ) : null}
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                      <Form.Label>
                        Group Size <RequiredComponents />
                      </Form.Label>
                      <Form.Control
                        placeholder="Group Size"
                        name="GroupSize"
                        onChange={formik.handleChange}
                      />
                      {formik.touched.GroupSize && formik.errors.GroupSize ? (
                        <div className="custom-invalid-feedback">
                          {formik.errors.GroupSize}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={12}
                className="d-sm-flex mt-5 justify-content-between align-items-start mt-5"
              >
                <Button
                  className="fw_medium "
                  variant="secondary"
                  // onClick={() => setTab(1)}
                  onClick={handleClose3}
                >
                  Back
                </Button>

                <div className="mt-4 mt-sm-0">
                  <Button
                    type="button"
                    className="btn btn-secondary  fw_medium   ms-2 rounded-1   "
                    variant=" "
                    onClick={handleClose3}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    className="   text-white fw_medium ms-2 "
                    //onClick={() => setTab(3)}
                    disabled={btnIsDisable}
                  >
                    Confirm
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </>
      )}
      {tab === 2 && (
        <>
          {btnIsDisable ? (
            <ThreeDotsLoader />
          ) : (
            <Row className="new_assigment justify-content-center">
              <Col xs={12} className="d_flex_center mt-5 flex-column">
                <div className="circle bg_green">
                  <i className="fa-solid fa-check"></i>
                </div>
                <h4 className="fw_medium mt-2">Bay Assignment Successfull.</h4>
                <h2 className="mt-3 fw-bold">PIN:{pin}</h2>
              </Col>
            </Row>
          )}
        </>
      )}
      {tab === 3 && (
        <>
          {btnIsDisable ? (
            <ThreeDotsLoader />
          ) : (
            <Row className="new_assigment justify-content-center">
              <Col xs={12} className="d_flex_center mt-5 flex-column">
                <div className="circle bg_orange">
                  <i class="fa-solid fa-exclamation"></i>
                </div>
                <h2 className=" fw-bold mt-2 text_danger_two">
                  Bay Not Assigned
                </h2>
                <h5 className="mt-3 fw_medium ">No response from the bay</h5>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default NewAssigment;

import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import authService from "../../apiServices/auth.service";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Button, Col, Container, Offcanvas, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  hideCanves,
  showCanves,
  showProfile,
} from "../../features/offCanvesSlice";
import { showForm, hideForm } from "../../features/showAccountFormSlice";
import ProfileOffcanvas from "./ProfileOffcanvas";
import EditProfile from "../ProfileDropdown/EditProfile";
import SelectProfile from "./SelectProfile";
import {CUSTOM_DOMAIN, LOGOUT_COOKIE_NAME, LOGOUT_VARNAME} from "../../constants";
import Cookies from "js-cookie";

const ProfileDropdown = ({ profile }) => {


  const show = useSelector((state) => state.offcanves.offCanvesShow);
  const showAddForm = useSelector((state) => state.accountForm.showAccountForm);
  const [title, setTitle] = useState();
  const [showHalfPopUp, setShowHalfPopUp] = useState(false);
  const [type, setType] = useState();
  const [content, setContent] = useState();
  const dispatch = useDispatch();
  const [profileUrl, setProfileUrl] = useState();

  const showAccountFormHandler = () => {
    // dispatch(showCanves());
    dispatch(showProfile());
    dispatch(showForm());
    setType("view");
  };

  const handleProfileSelect = (profileUrl) => {
    // console.log(profileUrl, "handleProfileSelect");
    setProfileUrl(profileUrl);
    setTitle("Edit Person");
    dispatch(showProfile());
    setType("edit");
    setContent(<EditProfile profileUrl={profileUrl} callBack={callBack} />);
    dispatch(hideForm());
    setShowHalfPopUp("edit");
  };

  const callBack = (action, profile) => {
    // console.log(action)
    switch (action) {
      case "edit":
        console.log(profileUrl, "case edit");
        setTitle("Edit Person");
        dispatch(showProfile());
        setType("edit");
        setContent(<EditProfile profileUrl={profileUrl} callBack={callBack} />);
        dispatch(hideForm());
        setShowHalfPopUp("edit");
        break;
      case "select":
        setTitle("Select Avatar");
        setContent(
          <SelectProfile
            handleProfileSelect={handleProfileSelect}
            callBack={callBack}
          />
        );
        setType("select");
        dispatch(showProfile());
        dispatch(hideForm());
        break;
      case "profile-select":
        handleProfileSelect(profile);
        break;
      case "view":
        setType("view");
        dispatch(showForm());
        break;
      default:
        return false;
    }
  };

  // login functionality
  const { instance } = useMsal();
  const [name, setName] = useState(authService.getCurrentUser()?.name);

  const navigate = useNavigate();

  const logout = () => {
    Cookies.remove(LOGOUT_COOKIE_NAME, { path: '/', domain: CUSTOM_DOMAIN  })

    instance.logoutRedirect({
      postLogoutRedirectUri: "/login",
      account: instance.getActiveAccount(),
      onRedirectNavigate: () => {
        // Return false to stop navigation after local logout
        localStorage.setItem(LOGOUT_VARNAME, Date.now().toString());
        authService.logout();
        authService.clearCookie();
        return true;
      },
    });
  };

  return (
    <div>
      <Dropdown className="nav-item header-dropdown">
        <Dropdown.Toggle
          id="dropdown-basic"
          className="nav-link dropdown-toggle border-0"
        >
          <img
            className="rounded-circle me-lg-2"
            src={`${
              profile && profile.AvatarFileUrl
                ? profile.AvatarFileUrl
                : "img/user.jpg"
            }`}
            alt=""
            style={{ width: "40px", height: "40px" }}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu className="me-2">
          <div className=" dropdown-menu-center bg-light border-0 rounded-0 rounded-bottom m-0">
            <Link
              to=""
              className="dropdown-item"
              onClick={showAccountFormHandler}
            >
              My Profile
            </Link>
            <Link to="" className="dropdown-item" onClick={() => logout()}>
              Log Out
            </Link>
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <ProfileOffcanvas
        type={type}
        content={content}
        title={title}
        profileUrl={profileUrl}
        callBack={callBack}
      />
    </div>
  );
};

export default ProfileDropdown;

import React, { useEffect, useRef, useState } from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import {
  Badge,
  Button,
  Col,
  Container,
  Row,
} from 'react-bootstrap'
import BayBox from '../../components/BayBox/BayBox'
import Offcanvas from 'react-bootstrap/Offcanvas'
// import Modal from 'react-bootstrap/Modal
import Reservation from '../../components/Reservation/Reservation'
import WaitList from '../../components/WaitList/WaitList'
import InUse from '../../components/InUse/InUse'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { slugify } from '../../commonFunctions'
import dataService from '../../apiServices/data.service'
import { COUNT_DOWN_TIMER, IS_LUXE, VENUE_GUID } from '../../constants'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { getBays, triggerChange } from '../../features/baysSlice'
import tokenService from '../../apiServices/token.service'


const Canon = () => {

  const counterTime = useRef(COUNT_DOWN_TIMER);


  const [offcanvasOne, setOffcanvasOne] = useState(1)
  const navigate = useNavigate();
  // for reservation
  const [tabOne, setTabOne] = useState(1)

  // for wait list
  const [tabTwo, setTabTwo] = useState(1)
  const [mainTab, setMainTab] = useState(1)

  // for offCanves Two which contain Bay Box Data
  const [bayNumber, setBayNumber] = useState(0)
  const [bayOffCanvesContent, setBayOffCanvesContent] = useState(1)


  const [openBayId, setOpenBayId] = useState({})

  // for offcanves One
  const [show, setShow] = useState(false)
  const handleClose = () => {

    setShow(false)
  }
  const handleShow = (e) => {
    setShow(true)
    setOffcanvasOne(e)
  }

  // for offcanves two
  const [offcanvesTwo, setOffcanves] = useState(false)
  const handleCloseOffcanvesTwo = (bay) => {
    if (bay) {
      dataService.unLockBay({
        "VenueGuid": VENUE_GUID,
        "ResourceGuid": bay.BayGuid,
        "ResourceName": bay.BayNumber,
        "LockedBy": tokenService.getName(),
        "LockExpiryTime": COUNT_DOWN_TIMER,
        "ResourceType": "Bay"
      }).then((res) => {
        if (res.data.status != "success") {
          toast.error(res.data.errorMessage)
        }
      }).catch((err) => {
        toast.error(err.message)
      })
    }
    setOffcanves(false)
  }

   const handleShowOffcanvesTwo = (bay, disabled = 0) => {
    setOpenBayId(bay)
    /* setOffcanves(true)
    setBayOffCanvesContent(<InUse bay={bay.BayGuid} handleCloseOffcanvesTwo={handleCloseOffcanvesTwo} />) */

    if(!disabled)
    {
      if (!bay.IsLocked) {

        dataService.lockBay({
          "VenueGuid": VENUE_GUID,
          "ResourceGuid": bay.BayGuid,
          "ResourceName": bay.BayNumber,
          "LockedBy": tokenService.getName(),
          "LockExpiryTime": COUNT_DOWN_TIMER,
          "ResourceType": "Bay"
        }).then((res) => {
  
          if (res.data.status == "success") {
            dispatch(getBays())
            setOffcanves(true)
            setBayOffCanvesContent(<InUse bay={bay.BayGuid} handleCloseOffcanvesTwo={handleCloseOffcanvesTwo} />)
          } else {
            toast.error(res.data.errorMessage)
          }
        }).catch((err) => {
          toast.error(err.message)
        })
      } else {
        toast.error(`Bay ${bay.BayNumber} is locked by ${bay.LockedBy}.`)
      }
    }
    else{
      toast.error("Please clear filters to open bay.")
    }

    /*  setOffcanves(true)
     setBayOffCanvesContent(<InUse bay={bay.BayGuid} handleCloseOffcanvesTwo={handleCloseOffcanvesTwo} />) */

  }

  // demo comment
  const dispatch = useDispatch()
  const { bay_state_counts, allBays, floors, loading, error, reservation_count, walk_in_count } = useSelector((state) => state.bays)

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getBays())
      //dispatch(triggerChange())

    }, 15000);
    return () => clearInterval(interval)
  }, []);



  return (
    <MainLayout>
      <Container className=" px-1 overflow-hidden" fluid>
        <div className="main-panel p-0 home_section mt-2">
          <div className="content-wrapper">
            <Row className="row tab_row justify-content-between align-items-center">
              <Col sm={6} className="order-1">
                <h3>
                Venue Manager App
                </h3>
              </Col>

              <Col
                xxl={6}
                sm={6}
                xs={12}
                className="text-sm-end  order-xxl-3 order-2 d-flex gap-3 justify-content-end"
              >



                {/* <div className=" d-flex gap-3 me-4">
                  <div className="pt-3 fs-5">
                    <i className="fa-regular fa-clock"></i>
                  </div>
                  <div>
                    <h3 className="fw-bold text-start">06:22</h3>
                    <p className="text_brown">Remaining Time </p>
                  </div>
                </div> */}


                <Button variant=" " onClick={() => navigate("/waitlist/waitlist")}
                  className=" action-btn fw_medium border-0   px-2 rounded-1"  >
                  <span>Fast Pass</span>
                  <Badge className=" bg_gray text-dark ms-2">{reservation_count}</Badge>
                </Button>
                <Button variant=" " onClick={() => navigate("/waitlist/waitlist")}
                  className=" action-btn btn_primary fw_medium border-0    rounded-1 mt-sm-3 mt-md-0" >
                  <span>Walk-in</span>
                  <Badge className="bg_gray text-dark ms-2">{walk_in_count}</Badge>
                </Button>






                {/* offcanves */}
                <Offcanvas
                  show={show}
                  onHide={handleClose}
                  placement="end"
                  className="off_canves"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                      {offcanvasOne === 1 && tabOne === 1 && (
                        <>
                          {' '}
                          <h5 id="offcanvasRightLabel">
                            Today's Fast Pass
                          </h5>{' '}
                        </>
                      )}
                      {offcanvasOne === 1 && (tabOne === 2 || tabOne === 3) && (
                        <>
                          <h5 id="offcanvasRightLabel">
                            Assign from Fast Pass
                          </h5>
                        </>
                      )}

                      {offcanvasOne === 2 && tabTwo === 1 && (
                        <>
                          {' '}
                          <h5 id="offcanvasRightLabel">Wait List</h5>{' '}
                        </>
                      )}
                      {offcanvasOne === 2 && (tabTwo === 2 || tabTwo === 3) && (
                        <>
                          <h5 id="offcanvasRightLabel">
                            Assign from Wait List
                          </h5>
                        </>
                      )}
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body className="p-0">
                    {/*==============>> content for Reservation <<============== */}
                    {offcanvasOne === 1 && (
                      <Reservation handleClose={handleClose} tabOne={tabOne} setTabOne={setTabOne} />
                    )}

                    {/*==============>> content for Wait List <<=================== */}
                    {offcanvasOne === 2 && (
                      <>
                        <WaitList handleClose={handleClose} setTabTwo={setTabTwo} tabTwo={tabTwo} />
                      </>
                    )}
                  </Offcanvas.Body>
                </Offcanvas>
              </Col>
            </Row>
            {
              (!IS_LUXE) ? <Row>
                <Col
                  md="6"
                  className="tabs_active_col order-xxl-2 order-3"
                >
                  <ul className="nav nav-tabs border-0   ps-0 ">

                    <li className="nav-item">
                      <Button
                        className={`nav-link ${mainTab === 1 ? 'active' : ''}`}
                        onClick={() => setMainTab(1)}
                      >
                        <span>Range Bays</span>
                        <span className="badge  bg-success text-white ms-3">
                          {bay_state_counts[1]}
                        </span>
                      </Button>
                    </li>
                    <li className="nav-item">
                      <Button
                        className={`nav-link ${mainTab === 2 ? 'active' : ''} disabled`}
                        onClick={() => setMainTab(2)}
                      >
                        <span>Putting Bays</span>
                        <span className="badge  bg-success text-white ms-3">
                          {bay_state_counts[1]}
                        </span>
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row> : null
            }

            <div className="same_line mt-2"></div>



            {/* main dashboard content */}

            <div className="tab-content">

              <div className={`tab-pane ${mainTab === 1 ? 'active' : ''}`}>

                
                <Row className="bay-wraper mt-3">
                  {
                        <Col className="d-flex same_box_row row_gap flex-sm-wrap  py-4 py-sm-0 mt-sm-5 mt-4  align-items-start align-content-end ">

                          {
                            Object.values(allBays).map((bay, i) => {
                              return (bay.BayType == "3")  ? <BayBox
                              /* className={`bay-${bay.states.slug}`} */
                              className={`bay-${slugify(bay.BayStateName)}`}
                              statusTime={bay.BayStateSetOn}
                              number={bay.BayNumber}
                              status={slugify(bay.BayStateName)}
                              vip={bay.BayTypeId}
                              /* handler={() => handleShowOffcanvesTwo(bay.BayNumber, floor.floor)} */
                              handler={() => handleShowOffcanvesTwo(bay, bay.is_disable)}
                              key={i}
                              bay={bay}
                              moment={moment}
                              isDisable={bay.is_disable}
                            // showCounter={showCounter(bay.bay_state_id, bay.bussing_on, bay.BayStateSetOn)}

                            /> : null
                              
                            })
                          }
                          <div className="same_line mt-2"></div>
                        </Col>

                  }





                  {/* offcanves for every box */}
                  <Offcanvas
                    show={offcanvesTwo}
                    onHide={() => handleCloseOffcanvesTwo(openBayId)}
                    placement="end"
                    className="off_canves off_canves_two"
                  >
                    <Offcanvas.Body className="p-0">
                      {
                        bayOffCanvesContent
                      }

                    </Offcanvas.Body>
                  </Offcanvas>
                  {/* offcanves for every box */}
                </Row>
              </div>

              <div className={`tab-pane ${mainTab === 2 ? 'active' : ''} `}>
                This is Link profile tab.
              </div>
            </div>


          </div>
        </div>
      </Container>
    </MainLayout>
  )
}

export default Canon
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {  BAY_DIRECTION, COUNT_DOWN_TIMER, VENUE_GUID, bayStates } from '../constants';
import { filterObject, filterObjectByDisable, convertArrayToObject, getCountByBayStates, sortBays, sortFloorsDesc, filterObjectByDisableMulti, sortByKey, filterIntakeObjectByDisable, filterObjectWithMultiParam, orderBy } from '../commonFunctions';
import dataService from '../apiServices/data.service';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

const initialState = {
  venueDateTime: "",
  loading: 'idle',
};

export const getVenueDateTime = createAsyncThunk('venueDateTime/getVenueDateTime', async () => {
  return dataService.getVenueDateTime(/* {
    "params": { "keys": "bay_guid, bay_number, Floor, is_deleted" },
    "filter": { "venue_guid": VENUE_GUID }
  } */ { "UtcTime": moment().utc().format("YYYY-MM-DD HH:mm:ss"), "VenueGuid": VENUE_GUID }).then((response) => {
    if (response.data.status == 'success') {
      return response.data;
    }
  }).catch((err) => {
    toast.error(err.message)
  })
})



export const venueDateTimeSlice = createSlice({
  name: 'venueDateTime',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    
  },

  extraReducers: (builder) => {
    builder.addCase(getVenueDateTime.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    })
    builder.addCase(getVenueDateTime.fulfilled, (state, action) => {
      if (state.loading === 'pending') {
        if (action.payload.status == 'success') {
          state.venueDateTime = action.payload.response.length > 0 ? action.payload.response[0].LocalDateForVenue : []
        }
        state.loading = 'idle'
      }
    })
    builder.addCase(getVenueDateTime.rejected, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.error = 'Error occured'
      }
    })
  },
});


export default venueDateTimeSlice.reducer

